Sim.require.amd.registerRaw("/app/components/HtmlEditor/Buttons/TitleButton.js", ["/app/components/HtmlEditor/Buttons/BaseButton.js"],  (BaseButton) => {

	class TitleButton extends BaseButton
	{

		get name() { return 'title'; }
		get icon() { return 'simditor-icon simditor-icon-title'; }
		get disableTag() { return 'pre, table'; }

		get htmlTag()
		{
			return ['h1', 'h2', 'h3', 'h4', 'h5'].filter((tag) => this.isGloballyAllowedTag(tag)).join(',');
		}

		_init()
		{
			this.menu = [{
				name: 'normal',
				text: this._t('normalText'),
				param: 'p',
			}];
			for (const param of this.htmlTag.split(','))
			{
				this.menu.push({
					name: param,
					text: `${this._t('title')} ${param.replace('h', '')}`,
					param,
				});
			}
			super._init();
		}

		setActive(active, param)
		{
			super.setActive(active);
			this.el.removeClass('active-p active-h1 active-h2 active-h3 active-h4 active-h5');
			if (active)
			{
				param || (param = this.node[0].tagName.toLowerCase());
				this.el.addClass(`active-${param}`);
			}
		}

		command(param)
		{
			const $nodes = this.editor.selection.nodes();
			let $rootNodes = this.editor.selection.rootNodes();
			this.editor.selection.save();
			if (param !== 'p')
			{
				$rootNodes = $rootNodes.first();
			}
			for (const node of $rootNodes)
			{
				const $node = $(node);
				if ($node.is('blockquote') || $node.is(param) || $node.is(this.disableTag) || this.editor.util.isDecoratedNode($node))
				{
					continue;
				}
				const $title = $(`<${param}/>`);
				const $new = $(document.createDocumentFragment()).append($title);
				let $rest;
				const $restUl = $('<ul>');
				for (const content of $node.contents())
				{
					if ($rest)
					{
						$rest.append(content);
					}
					else if (content instanceof Element && content.tagName.toLowerCase() === 'br' && $node[0].lastChild !== content)
					{
						$rest = $node.clone().empty();
					}
					else if (content instanceof Element && content.tagName.toLowerCase() === 'li')
					{
						if (param === 'p')
						{
							$new.append($('<p>').append($(content).contents()));
						}
						else if ($title.is(':empty') && $nodes.is(content))
						{
							$title.append($(content).contents());
						}
						else
						{
							$restUl.append(content);
						}
					}
					else
					{
						$title.append(content);
					}
				}
				if (!$restUl.is(':empty'))
				{
					$new.append($restUl);
				}
				if ($rest && !$rest.is(':empty'))
				{
					$new.append($rest);
				}
				$new[0].normalize();
				$new.replaceAll($node);
			}
			this.editor.selection.restore();
			this.editor.trigger('valuechanged');
		}

	}

	return TitleButton;
});

Sim.require.amd.registerRaw("/app/templates/Auth/LaterDelay.js", ["/public/js/Helpers/ElementFinder.js"],  (ElementFinder) => {

	class LaterDelay
	{

		#animateSpeed = 250; // ms

		#root = new ElementFinder('#login');
		#link = this.#root.findOne('.js-later');
		#progress = $(`
			<div class="progress progress-striped progress-danger" style="margin-top: 18px;">
				<div class="bar"></div>
			</div>
		`);

		#step = 0;
		#interval;

		constructor(delaySeconds)
		{
			this.#link.on('click auxclick contextmenu', false);
			this.#link.one('click', () => this.#start(delaySeconds));
		}

		#start(delaySeconds)
		{
			Sim.loading();
			const bar = this.#progress.appendTo(this.#root.$root).find('> .bar');
			this.#interval = setInterval(() => this.#animate(delaySeconds, bar), this.#animateSpeed);
		}

		#animate(delaySeconds, bar)
		{
			const percent = ++this.#step / ((delaySeconds * 1000) / this.#animateSpeed);
			bar.width(`${Math.min(Math.max((1 - ((1 - percent) ** 2)) * 100, 0), 100)}%`);
			if (percent > 1)
			{
				clearInterval(this.#interval);
				bar.width('100%');
				this.#progress.removeClass('progress-danger').addClass('progress-warning');
				location.href = this.#link.attr('href');
			}
		}

	}

	return LaterDelay;
});

Sim.require.amd.registerRaw("/app/components/FormControls/PersonSelect/PersonSelect.js", ["/app/components/FormControls/PersonSelect/PersonSelectDataProvider.js"],  (PersonSelectDataProvider) => {

	function PersonSelect(validatorName, nameElId, valueElId, INTERNAL_SEPARATOR, valueId, provider) {
		this.INTERNAL_SEPARATOR = INTERNAL_SEPARATOR;
		this.initValidator(validatorName);
		this.initElements(nameElId, valueElId);
		this.initAutocomplete(valueId, provider);
	}

	PersonSelect.prototype = {

		initValidator: function (validatorName) {
			if (PersonSelect.validatorName === validatorName) return;
			Sim.addNetteValidator(validatorName, (elem, arg, val) => {
				const $elem = $(elem);
				const nameEl = $elem.parent().find(`> input[type="text"]:not([name])[id="${$.escapeSelector($elem.attr('data-name-id'))}"]`);
				const valueEl = $elem.parent().find(`> input[type="hidden"][name][id="${$.escapeSelector($elem.attr('data-value-id'))}"]`);
				if (nameEl.length !== 1) throw new Error;
				if (valueEl.length !== 1) throw new Error;
				const split = valueEl.val().split(this.INTERNAL_SEPARATOR);
				const [id, name] = [split.shift(), split.join(this.INTERNAL_SEPARATOR)];
				return (name === nameEl.val() && ((id && name) || (!id && !name)));
			});
			PersonSelect.validatorName = validatorName;
		},

		initElements(nameElId, valueElId)
		{
			this.nameInput = $(document.getElementById(nameElId)).filter('input[type="text"]:not([name])');
			this.valueInput = $(document.getElementById(valueElId)).filter('input[type="hidden"][name]');
			if (this.nameInput.length !== 1) throw new Error;
			if (this.valueInput.length !== 1) throw new Error;
			if (!this.nameInput.parent().is(this.valueInput.parent())) throw new Error;
		},

		initAutocomplete: function (valueId, provider) {
			this.nameInput.autocomplete({
				source: (request, response) => {
					Promise.resolve(PersonSelectDataProvider.getSourceData(provider))
						.then((array) => response($.ui.autocomplete.filter(array, request.term)))
						.catch((err) => { throw err; })
					;
				},
				delay: 0,
				select: this._setItem.bind(this),
				focus: this._setItem.bind(this),
				close: this._close.bind(this),
			});
			this.ac = this.nameInput.autocomplete('instance');

			this.nameInput.on('focusin click', function () {
				if (!this.ac.menu.element.is(':visible')) this.ac.search();
			}.bind(this));

			this.nameInput.on('keydown', function (event) {
				if (event.keyCode === $.ui.keyCode.ENTER && this.ac.menu.element.is(':visible'))
				{
					event.preventDefault();
					this.ac.close();
				}
			}.bind(this));

			if (valueId)
			{
				this.nameInput.data('item', _.find(this.ac.options.source, {id: valueId}));
			}

			this.ac._value = (function (old, setValue) {
				return function (value) {
					if (arguments.length) return setValue(value, true);
					return old.apply(this, arguments);
				};
			})(this.ac._value, this._setValue.bind(this));
			this.nameInput.on('change keyup paste cut input', function (e) {
				this._setValue(this.nameInput.val());
			}.bind(this));

			this.ac._renderItem = this._renderItem.bind(this);
			this.ac._normalize = (function (old) {
				return function (items) {
					return old.call(this, items.slice(0, 30));
				};
			})(this.ac._normalize);
		},

		_close: function () {
			if (!this.nameInput.data('item'))
			{
				var items = this.ac.menu.element.find('.ui-menu-item[data-person-select-id]');
				var active = items.length === 1 ? items.first() : this.ac.menu.active;
				this._setItem(null, { item: active ? active.data('ui-autocomplete-item') : null });
				this._setValue(this.nameInput.val());
			}
			this.valueInput.trigger('change');
		},

		_setItem: function (event, ui) {
			this.nameInput.data('itemSelected', ui.item);
			setTimeout(function () {
				this.nameInput.data('itemSelected', null);
			}.bind(this));
		},

		_setValue: function (value, set) {
			if (set) this.nameInput.val(value);
			var select = this.nameInput.data('itemSelected');
			var item = this.nameInput.data('item');
			if (select && select.value === value)
			{
				this.nameInput.data('item', select);
				this.valueInput.val(`${select.id}${this.INTERNAL_SEPARATOR}${value}`).trigger('change');
				this.ac.menu.element.find('.ui-menu-item[data-person-select-id].person-select-active').removeClass('person-select-active');
				this.ac.menu.element.find('.ui-menu-item[data-person-select-id="' + $.escapeSelector(select.id) + '"]').addClass('person-select-active');
			}
			else if (select || (item && item.value !== value))
			{
				this.nameInput.data('item', null);
				this.valueInput.val(value ? `${this.INTERNAL_SEPARATOR}${value}` : '');
				if (!value) this.valueInput.trigger('change');
				this.ac.menu.element.find('.ui-menu-item[data-person-select-id].person-select-active').removeClass('person-select-active');
			}
			else
			{
				this.valueInput.val((item || value) ? `${item ? item.id : ''}${this.INTERNAL_SEPARATOR}${value}` : '');
			}
		},

		_renderItem: function (ul, person) {
			var isUser = person.type === 'user';
			var content = $('<a>')
				.append($('<i>').addClass('icon-' + (isUser ? 'user' : 'home')))
				.append(' ')
				.append($('<span class="name">').text(person.name))
				.append($('<span class="pmc-id">').text(person.pmcId))
				.append(isUser
					? $('<div class="email">').text(person.email)
					: $('<div class="address">').text(person.address)
				)
			;
			return $('<li>')
				.attr('data-person-select-id', person.id)
				.toggleClass('person-select-active', this.nameInput.data('item') === person)
				.append(content.css('display', 'block'))
				.appendTo(ul)
			;
		},

	};

	return PersonSelect;
});

Sim.require.amd.registerRaw("/app/components/DropdownMenuSearch/DropdownMenuSearch.js", ["/app/components/DropdownMenuSearch/DropdownMenuPick.js","/public/js/Helpers/TextSearchMatcher.js"],  (DropdownMenuPick, TextSearchMatcher) => {

	class DropdownMenuSearch extends DropdownMenuPick
	{

		#options;
		#searchInput;

		#menuItem = $('<li class="m-dropdownMenu__row"><a></a></li>');
		#menuItemText = $(`
			<span class="a-breakText a-breakText--no">
				<span class="js-text"></span>
				<span class="a-interpunct"></span>
			</span>
		`);

		#lastValue = null;
		#statusInitial = true;

		constructor(options)
		{
			super({
				container: options.container,
				triggerElementSelector: options.searchInputSelector,
				menu: null,
				open: () => {
					this.#lookup.cancel();
					this.#lookupNow();
				},
			});
			this.#options = {
				minLength: 3,
				numberOfItems: -1,
				...options,
			};

			this.#searchInput = this.$triggerElement();
			this.#lastRequest.queryString = this.#searchInput.val();

			this.#searchInput.on({
				'input': (e) => {
					if (!e.isDefaultPrevented())
					{
						this.#statusInitial = false;
						this.#lookup();
					}
				},
			});

			$(Object.values(this.#options.ajax?.parameters || {})).on('change', (e) => {
				if (this.#lastValue !== null)
				{
					this.selectItem(null, null, e);
				}
			});
		}

		#lastRequest = {
			queryString: '',
			queryData: undefined,
			response: undefined,
			ajax: undefined,
		};

		#lookup = _.debounce(() => this.#lookupNow(), 100);

		lookupNonAjax(query)
		{
			throw new Error('not supported/implemented');
		}

		#lookupNow()
		{
			const query = this.#searchInput.val() || '';
			if (!('ajax' in this.#options))
			{
				if (this.#lastRequest.queryString !== query || !this._status('shown'))
				{
					this.#lastRequest.queryString = query;
					this.#render(this.lookupNonAjax(query));
				}
				return;
			}
			const data = {
				..._.mapObject(this.#options.ajax.parameters || {}, (el) => $(el).val()),
				[this.#options.ajax.queryParameter]: query,
			};
			if (this.#lastRequest.queryData === JSON.stringify(data))
			{
				if (
					this.#lastRequest.response !== undefined &&
					this.#lastRequest.ajax === undefined &&
					!this._status('shown')
				)
				{
					this.#render(this.#lastRequest.response);
				}
				return;
			}
			if (this.#lastRequest.ajax !== undefined)
			{
				this.#lastRequest.ajax.abort();
			}
			if (query.length < this.#options.minLength || !this.#searchInput.is(':visible'))
			{
				this._openMenu(false);
				return;
			}
			if (!this._status('shown') && this.#statusInitial)
			{
				this.#render([], true);
			}
			this.#lastRequest.queryString = query;
			this.#lastRequest.queryData = JSON.stringify(data);
			this.#lastRequest.response = undefined;
			this.#lastRequest.ajax = $.ajax({
				url: this.#options.ajax.url,
				method: 'POST',
				data,
				dataType: 'json',
				success: (json) => {
					this.#lastRequest.response = json.results;
					this.#render(this.#lastRequest.response);
				},
				complete: () => {
					this.#lastRequest.ajax = undefined;
				},
				error: () => {
					this.#lastRequest.queryData = undefined;
					this.#lastRequest.response = undefined;
				},
			});
		}

		#render(items, initialLoading = false)
		{
			const els = [];
			let firstActive;
			if (!_.isArray(items))
			{
				items = Object.entries(items); // deprecated
				Sim.triggerWarning('DropdownMenuSearch: require values as array of {value, text}');
			}
			if (items.length > 0)
			{
				if (this.#options.numberOfItems > 0)
				{
					items = items.slice(0, this.#options.numberOfItems);
				}
				else
				{
					this.$menu().addClass('m-dropdownMenu--scroll');
				}
				for (let item of items)
				{
					if (_.isArray(item))
					{
						if (!els.length) Sim.triggerWarning('DropdownMenuSearch: require values as array of {value, text}');
						item = $.isPlainObject(item[1]) ? {value: item[0], ...item[1]} : {value: item.shift(), text: item.length === 1 ? item.shift() : item};
					}
					const el = this.#menuItem.clone();
					const {value, text} = item;
					this.renderItem(value, text, el, item);
					el.attr('data-value', typeof value === 'string' ? value : JSON.stringify(value)).data('value', value);
					if (!firstActive && el.hasClass('m-dropdownMenu__row--selected') && !el.hasClass('m-dropdownMenu__row--disabled'))
					{
						firstActive = el;
					}
					els.push(el);
				}
				if (!firstActive && els[0] && !els[0].hasClass('m-dropdownMenu__row--disabled'))
				{
					firstActive = els[0];
				}
			}
			else
			{
				const el = this.#menuItem.clone();
				if (initialLoading)
				{
					el.addClass('m-dropdownMenu__row--disabled m-dropdownMenu__row--loading');
				}
				else
				{
					el.addClass('m-dropdownMenu__row--disabled').children('a').text(this.getText('noResults'));
				}
				els.push(el);
			}
			this.$menu().empty().append(els);
			this._openMenu(true, firstActive);
		}

		getText(key)
		{
			if (this.#options.texts[key] === undefined)
			{
				throw new Error(key);
			}
			return this.#options.texts[key];
		}

		getLastQueryString()
		{
			return this.#lastRequest.queryString;
		}

		highlighter(text)
		{
			return (new TextSearchMatcher(this.getLastQueryString())).highlighterTextToHtml(text, 200);
		}

		#itemToHtml(item)
		{
			let html;
			if (typeof item === 'string')
			{
				html = this.highlighter(item);
			}
			else if (item instanceof $)
			{
				html = item;
			}
			else if (typeof item === 'object' && 'tag' in item)
			{
				const innerHtml = (item.content ?? '') === '' ? '' : this.#itemToHtml(item.content);
				html = $(document.createElement(item.tag)).html(innerHtml);
				for (const [attr, val] of Object.entries(item))
				{
					if (attr === 'tag' || attr === 'content') continue;
					html.attr(attr, val);
				}
			}
			else if (_.isArray(item))
			{
				html = $('<span>');
				for (const val of item)
				{
					html.append(this.#itemToHtml(val));
				}
			}
			else
			{
				throw new Error(JSON.stringify(item));
			}
			return html;
		}

		renderItem(value, text, el, item)
		{
			const a = el.children('a');
			let last;
			for (const v of (_.isArray(text) ? text : [text]))
			{
				last = this.#menuItemText.clone();
				last.children('.js-text').html(this.#itemToHtml(v));
				a.append(last);
				a.append(' ');
			}
			if (last)
			{
				last.children('.a-interpunct').remove();
			}
		}

		selectItem(value, el, event)
		{
			this.#lastValue = value ? JSON.stringify(value) : null;
			super.selectItem(value, el, event);
		}

	}

	return DropdownMenuSearch;
});

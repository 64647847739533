Sim.require.amd.registerRaw("/app/Intranet/components/TableSelector/TableSelectorLocalRenderer.js", ["/app/Intranet/components/TableSelector/TableSelectorLocalRendererRows.js","/app/Intranet/components/TableSelector/TableSelectorLocalRendererAnimation.js"], 


 (
	TableSelectorLocalRendererRows,
	TableSelectorLocalRendererAnimation,
) => {

	class TableSelectorLocalRenderer
	{

		constructor(localTDIndexes, elements, paginator)
		{
			this.rowConstantSize = 25;
			this.minimumNumberOfRows = 4;
			this.scrollbarConstantSize = 15;
			this.localTDIndexes = localTDIndexes;
			this.elements = elements;
			this.paginator = paginator;

			const {tbody} = this._getTableBody();
			this.rows = new TableSelectorLocalRendererRows(tbody);

			this.elements.scrollbar.on('contextmenu', (e) => {
				if (e.ctrlKey && !e.altKey && !e.shiftKey && !e.metaKey)
				{
					const value = this._disableAnimation();
					this._disableAnimation = () => !value;
					return false;
				}
			});
		}

		renderScrolling(animate = true)
		{
			const {local: pLocal} = this.paginator;
			if (this.paginator.isDifferentPosition('current', pLocal.start, pLocal.itemsPerPage))
			{
				requestAnimationFrame(() => {
					const {remote: pRemote} = this.paginator;
					const tsTableScrollHeight = pLocal.itemsPerPage * this.rowConstantSize;
					this.elements.tsTableScroll.height(`${tsTableScrollHeight}px`);
					this.elements.tsTableSticky
						.addClass('done')
						.toggleClass('first', pLocal.start === 0)
						.toggleClass('last', (pLocal.start + pLocal.itemsPerPage) >= pRemote.itemCount)
						.toggleClass('empty', pRemote.itemCount === 0)
					;

					for (const [class1, class2, number] of [
						['loadPrev', 'countNumber', pLocal.start],
						['loadPrev', 'itemsPerPage', Math.min(pLocal.itemsPerPage, pLocal.start)],
						['loadNext', 'countNumber', pRemote.itemCount - pLocal.start - pLocal.itemsPerPage],
						['loadNext', 'itemsPerPage', Math.min(pLocal.itemsPerPage, pRemote.itemCount - pLocal.start - pLocal.itemsPerPage)],
					])
					{
						this.elements.cachedFind('tsTableSticky', `> .${class1} .${class2}`).text(Sim.number.format(number, 0));
					}

					const scrollPercent = pRemote.itemCount > pLocal.itemsPerPage ? pLocal.start / (pRemote.itemCount - pLocal.itemsPerPage) : null;
					const scrollbarTopCss = scrollPercent === null ? '' : `${scrollPercent * (tsTableScrollHeight - this.scrollbarConstantSize)}px`;
					this.elements.scrollbar.css('top', scrollbarTopCss);
				});

				this._startAnimation(animate);

				this.paginator.current.start = pLocal.start;
				this.paginator.current.itemsPerPage = pLocal.itemsPerPage;
			}
		}

		renderContent()
		{
			for (const position of this.rows.iterateNotFilledPositions())
			{
				const [el, filled] = this.localTable.getCloneRowAtPosition(position);
				if (el === null) continue;
				this.rows.set(position, el, filled);
			}
			if (this.renderContent === TableSelectorLocalRenderer.prototype.renderContent)
			{
				this.renderContent = Sim.idleDebounce(this.renderContent.bind(this), 200, 500);
			}
		}

		enterPixelScrolling(scroller)
		{
			const startPixels = this.paginator.local.start * this.rowConstantSize;
			const pixelScrolling = {
				update: (offsetPixels) => {
					this.paginator.current.offsetPixels = offsetPixels;
					scroller.changeLocalPage(scroller.MODE.PIXEL_ABS, startPixels - offsetPixels);
				},
				leave: _.once(() => {
					this.paginator.current.offsetPixels = undefined;
					delete pixelScrolling.update;
				}),
			};
			return pixelScrolling;
		}

		_disableAnimation()
		{
			// assume someone with prefers-reduced-motion also prefers disable smooth scrolling
			const disable = ('matchMedia' in window && window.matchMedia('(prefers-reduced-motion)').matches);
			this._disableAnimation = () => disable;
			return disable;
		}

		_getTableBody()
		{
			if (this.table === undefined)
			{
				const container = $('<div class="tsTableAnimatorContainer" data-popover-bounding-element="true">');
				const table = $('<table class="m-table tableStandard">').appendTo(container);
				const tbody = $('<tbody>').appendTo(table);
				container.appendTo(this.elements.tsTableScroll);
				this.table = {container, table, tbody};
			}
			return this.table;
		}

		_startAnimation(animate)
		{
			const changed = this._updateRows();
			if (changed)
			{
				if (this.animation === undefined)
				{
					const {local: pLocal, current: pCurrent} = this.paginator;
					const skipAnimation = (
						!animate ||
						pCurrent.start === -1 ||
						this._disableAnimation() ||
						(Math.abs(pCurrent.start - pLocal.start) > Math.max(50, pLocal.itemsPerPage, pCurrent.itemsPerPage))
					);
					if (skipAnimation)
					{
						requestAnimationFrame(() => {
							this.rows.flushAddedToDom();
							this.rows.flushDeletedToDom();
						});
					}
					else
					{
						const {container, table} = this._getTableBody();
						new TableSelectorLocalRendererAnimation(this, 'animation', container, table);
					}
				}
				if (this.animation !== undefined)
				{
					this.animation.changed();
				}
			}
		}

		_updateRows()
		{
			let changed = false;
			const {local: pLocal} = this.paginator;
			for (const position of this.paginator.iteratePositions('local'))
			{
				if (!this.rows.has(position, true))
				{
					let [el, filled] = this.localTable.getCloneRowAtPosition(position);
					if (!filled)
					{
						el = this.localTable.getEmptyRowAtPosition(position);
					}
					if (el === null) continue;
					this.rows.set(position, el, filled);
					changed = true;
				}
				else if (!this.rows.has(position, false)) // is deleted
				{
					this.rows.keep(position);
					changed = true;
				}
			}
			for (const position of this.paginator.iteratePositions('current'))
			{
				if (position < pLocal.start || position >= (pLocal.start + pLocal.itemsPerPage))
				{
					this.rows.delete(position);
					changed = true;
				}
			}
			return changed;
		}

	}

	return TableSelectorLocalRenderer;
});

Sim.require.amd.registerRaw("/app/Intranet/components/Contracts/ContractAddForm.js", ["/app/components/FormRepeater/FormRepeater.js"],  (FormRepeater) => {

	class ContractAddForm
	{

		#options;
		#form;
		#unitsRepeater;
		#dateStartInput;

		constructor(options)
		{
			this.#options = {
				dateStartSelector: 'input[name="dateStart"]',
				totalPriceSelector: 'div.totalPrice span.amount',
				totalChargesSelector: 'div.totalCharges span.amount',
				totalSumSelector: 'div.totalSum span.amount',
				...options,
			};
			this.#form = $(this.#options.form);
			this.#dateStartInput = this.#form.find(this.#options.dateStartSelector);

			FormRepeater.initExternal(this.#options.unitsContainer, (repeater) => {
				this.#unitsRepeater = repeater;
				this.#unitsRepeater.changed.on(() => this.#recalculateUnits());
			});

			this.#dateStartInput.on('change', () => this.#setBreakDayDefaults());
		}

		#recalculateUnits()
		{
			let totalPrice = 0;
			let totalCharges = 0;
			this.#unitsRepeater.getRows().find('input[name$="[price]"]').each((i, e) => { totalPrice += Sim.number.parse($(e).val()); });
			this.#unitsRepeater.getRows().find('input[name$="[charges]"]').each((i, e) => { totalCharges += Sim.number.parse($(e).val()); });
			this.#form.find(this.#options.totalPriceSelector).text(Sim.number.format(totalPrice));
			this.#form.find(this.#options.totalChargesSelector).text(Sim.number.format(totalCharges));
			this.#form.find(this.#options.totalSumSelector).text(Sim.number.format(totalPrice + totalCharges));
		}

		#setBreakDayDefaults()
		{
			const date = this.#dateStartInput.getDatepickerDate();
			const supports = new Map([
				[7, date ? date.getDay() || 7 : null],
				[31, date ? date.getDate() : null],
			]);
			for (const el of this.#form.find('select[data-round-to-break-day][data-break-day-promp-text]').get())
			{
				const select = $(el);
				const type = parseInt(select.attr('data-round-to-break-day'), 10);
				if (!supports.has(type))
				{
					throw new Error(String(type));
				}
				const day = supports.get(type) || select.attr('data-default-day-origin');
				const value = select.find(`option[value="${$.escapeSelector(day)}"]`).text();
				const text = select.attr('data-break-day-promp-text').replace(/%day%/u, value);
				select.find('option[value=""]').text(text);
			}
		}

	}

	return ContractAddForm;
});


/**
 * Umoznuje #element `position: fixed` jen uvnitr #container, z moznosti obsah #element scrolovat.
 *
 * <pre>
 *
 * 	<div id="container">
 * 		<div id="element"></div>
 * 	</div>
 *
 * 	<script>
 * 		new ScrollFixedFollow('#element', '#container');
 * 	</script>
 *
 * 	<script>
 * 		#container {
 * 			position: relative;
 * 			height: 1000px;
 * 		}
 * 		#element {
 * 			position: absolute;
 * 			top: 0px;
 * 			right: 0px;
 * 			width: 50%;
 * 		}
 * 	</script>
 *
 * </pre>
 *
 * @author Petr Procházka
 */
Sim.require.amd.registerRaw("/public/js/ScrollFixedFollow.js", [], function () {

	/**
	 * @constructor
	 * @param {(string|jQuery)}
	 * @param {?(string|jQuery)}
	 */
	var ScrollFixedFollow = function (element, onlyIn) {
		if (!onlyIn) onlyIn = document.body;

		this.element = $(element);
		this.root = $(onlyIn);
		this.calculation = ScrollFixedFollow.add(this);
		this.width = this.element.width();
	};

	/**
	 * Element dostal novy obsah, je potraba prepocitat sirku a zobrazit horni cast.
	 */
	ScrollFixedFollow.prototype.recalculate = function () {
		this._reset();
		ScrollFixedFollow.run();
	};

	/**
	 * Velikost okna se zmenila je potreba prepocitat sirku elementu (ktera muze byt v procentech).
	 * @see ScrollFixedFollow.run
	 */
	ScrollFixedFollow.prototype.recalculateWidth = function () {
		var klass = this.element.attr('class');
		var style = this.element.attr('style');
		this._reset();
		this.element.attr('class', klass);
		this.element.attr('style', style);
		if (this.element.is('.fixedTop, .fixedBottom, .fixedCenter'))
		{
			this.element.width(this.width);
		}
	};

	/**
	 * Zpocita a nastavi aktualni pozici.
	 * @see ScrollFixedFollow.run
	 */
	ScrollFixedFollow.prototype.run = function () {
		var c = this.calculation;

		if (c.root.top() >= c.scroll.top())
		{
			// je nahore neni nic potreba delat
			this._reset();
		}
		else if (c.element.height() >= c.root.height())
		{
			// modal je vetsi nez tabulka neni potreba nic delat
			this._reset();
		}
		else if (c.element.height() <= c.window.height())
		{
			// modal se vejde na stanku, staci ho mit nahore
			this._fixedTop();
		}
		else
		{
			// modal se nevejde

			if (this.element.hasClass('fixedCenter'))
			{
				// scolujeme v modalu

				if (c.scroll.top() < (c.element.top() + c.root.top()))
				{
					// pri scrolovani jsme vyjeli nad modal
					this._fixedTop();
				}
				else if ((c.scroll.top() + c.window.height()) > (c.element.top() + c.root.top() + c.element.height()))
				{
					// pri scrolovani jsme vyjeli pod modal
					this._fixedBottom();
				}

			}
			else if ((isFinite(ScrollFixedFollow.lastScrollTop) || ScrollFixedFollow.lastScrollTop === c.scroll.top()) && !this.element.is('.fixedBottom, .fixedTop'))
			{
				// je cerstve otevren

				this._fixedCenterTop();
			}
			else if (ScrollFixedFollow.lastScrollTop < c.scroll.top())
			{
				// scrolujeme dolu

				if (this.element.hasClass('fixedTop'))
				{
					// jeli jsme nahoru, ale ted uz jedem dolu, zapne se scrolovani v modalu
					this._fixedCenterTop();
				}
				else
				{
					// jsme na konci modalu

					if ((c.root.top() + c.element.height()) >= (c.scroll.top() + c.window.height()))
					{
						// jeste jsme ale nevyjeli z horejsku
					}
					else if (!this.element.hasClass('fixedBottom'))
					{
						this._fixedBottom();
					}
				}

			}
			else if (ScrollFixedFollow.lastScrollTop > c.scroll.top())
			{
				// scrolujeme nahoru

				if (this.element.hasClass('fixedBottom'))
				{
					// jeli jsme dolu, ale ted uz jedem nahoru, zapne se scrolovani v modalu
					this._fixedCenterBottom();
				}
				else if (!this.element.hasClass('fixedTop'))
				{
					// jsme na zacatku modalu
					this._fixedTop();
				}

			}
		}
	};

	/**
	 * Zobrazuje element od zacatku root.
	 * Znamena ze element se do rootu presne vejde, nebo je scolovano nahore.
	 * @private
	 */
	ScrollFixedFollow.prototype._reset = function () {
		this.element.removeClass('fixedTop fixedBottom fixedCenter');
		this.element.css('position', 'absolute');
		this.element.css('top', '0px');
		this.element.css('bottom', 'auto');
		this.element.width('');
		this.width = this.element.width();
		this.root.css('min-height', `${this.calculation.element.height()}px`);
	};
	/**
	 * Element je prichycen k hornimu okraji obrazovky.
	 * Protoze napr scrolujeme nahoru.
	 * @private
	 */
	ScrollFixedFollow.prototype._fixedTop = function () {
		this.element.removeClass('fixedTop fixedBottom fixedCenter');
		this.element.addClass('fixedTop');
		this.element.css('position', 'fixed');
		this.element.css('top', (-1 * this.calculation.element.marginTop()) + 'px');
		this.element.css('bottom', 'auto');
		this.element.width(this.width);
	};
	/**
	 * Element je prichycen k dolnimu okraji obrazovky.
	 * Protoze napr scrolujeme dolu.
	 * @private
	 */
	ScrollFixedFollow.prototype._fixedBottom = function () {
		this.element.removeClass('fixedTop fixedBottom fixedCenter');
		this.element.addClass('fixedBottom');
		this.element.css('position', 'fixed');
		this.element.css('top', 'auto');
		this.element.css('bottom', (-1 * this.calculation.element.marginBottom()) + 'px');
		this.element.width(this.width);
	};
	/**
	 * Element se v root zastavil zezhora, aby jsme mohli scolovat ovnitr obsahu elemetu.
	 * @private
	 */
	ScrollFixedFollow.prototype._fixedCenterTop = function () {
		this.element.removeClass('fixedTop fixedBottom fixedCenter');
		this.element.addClass('fixedCenter');
		this.element.css('position', 'absolute');
		this.element.css('top', (this.calculation.scroll.top() - this.calculation.root.top()) + 'px');
		this.element.css('bottom', 'auto');
		this.element.width(this.width);
	};
	/**
	 * Element se v root zastavil zezdola, aby jsme mohli scolovat ovnitr obsahu elemetu.
	 * @private
	 */
	ScrollFixedFollow.prototype._fixedCenterBottom = function () {
		this.element.removeClass('fixedTop fixedBottom fixedCenter');
		this.element.addClass('fixedCenter');
		this.element.css('position', 'absolute');
		this.element.css('top', 'auto');
		this.element.css('bottom', (this.calculation.scroll.bottom() - this.calculation.root.bottom()) + 'px');
		this.element.width(this.width);
	};

	/**
	 * Objekty u kterych je potreba prepocitavat pozici pri scrolovani nebo resizovani.
	 * @type {Array.<ScrollFixedFollow>}
	 * @private
	 */
	ScrollFixedFollow.objects = [];

	/**
	 * Prida objekt u ktereho je potreba prepocitavat pozici pri scrolovani nebo resizovani.
	 * Vraci cachovany objekt na ziskavani ruznich rozmeru.
	 * @param {ScrollFixedFollow}
	 * @returns {Object}
	 */
	ScrollFixedFollow.add = function (object) {
		if (ScrollFixedFollow.objects.length === 0)
		{
			ScrollFixedFollow.init();
		}
		ScrollFixedFollow.objects.push(object);
		setTimeout(ScrollFixedFollow.run);

		var calculation = _.clone(ScrollFixedFollow.calculation);

		calculation.root = {
			top: ScrollFixedFollow.throttle(function () {
				return object.root.position().top + calculation.element.marginTop();
			}),
			bottom: ScrollFixedFollow.throttle(function () {
				return calculation.document.height() - calculation.root.top() - calculation.root.height();
			}),
			height: ScrollFixedFollow.throttle(function () {
				const parent = object.root.parent();
				const parentAttr = parent.attr('style');
				const minHeight = object.root.css('min-height') || '';
				parent.height(parent.height());
				object.root.css('min-height', '');
				const height = object.root.height() - calculation.element.marginBottom() - calculation.element.marginTop();
				object.root.css('min-height', minHeight);
				parent.attr('style', parentAttr === undefined ? null : parentAttr);
				return height;
			}),
		};

		calculation.element = {
			top: ScrollFixedFollow.throttle(function () {
				return object.element.position().top;
			}),
			height: ScrollFixedFollow.throttle(function () {
				return object.element.height();
			}),
			marginTop: ScrollFixedFollow.throttle(function () {
				return parseInt(object.element.css('margin-top'), 10);
			}),
			marginBottom: ScrollFixedFollow.throttle(function () {
				return parseInt(object.element.css('margin-bottom'), 10);
			}),
		};

		return calculation;
	};

	/**
	 * Inicializuje se cachovany objekt na ziskavani ruznich rozmeru.
	 * Nastavi se udalosti na scrolovani nebo resizovani.
	 * @private
	 */
	ScrollFixedFollow.init = function () {

		var $window = $(window);
		var $document = $(document);
		ScrollFixedFollow.calculation = {};
		ScrollFixedFollow.calculation.scroll = {
			top: function () {
				return $window.scrollTop();
			},
			bottom: _.bind(function () {
				return this.document.height() - this.scroll.top() - this.window.height();
			}, ScrollFixedFollow.calculation),
		};
		ScrollFixedFollow.calculation.window = {
			height: ScrollFixedFollow.throttle($window, 'height'),
		};
		ScrollFixedFollow.calculation.document = {
			height: ScrollFixedFollow.throttle($document, 'height'),
		};

		window.addEventListener('scroll', ScrollFixedFollow.run, {passive: true});
		window.addEventListener('resize', _.debounce(ScrollFixedFollow.run, 500), {passive: true});
	};

	ScrollFixedFollow.throttle = function (a, b) {
		var fn = b !== undefined ? a[b].bind(a) : a;
		return _.throttle(fn, 500, {leading: true, trailing: false});
	};

	/**
	 * Aby se pri nacteni stranky pri uz nascrolovani pocitalo ze se scroluje nahoru tak je poprve na nekonecno.
	 * @type {number}
	 * @private
	 */
	ScrollFixedFollow.lastScrollTop = Infinity;

	/**
	 * Udalost pri scrolovani nebo resizovani.
	 * Prepocita pozici vsech registrovanych objektu.
	 * @param {?$.Event}
	 */
	ScrollFixedFollow.run = function (e) {
		_.each(ScrollFixedFollow.objects, function (object) {
			object.run();
			if (e && e.type === 'resize')
			{
				object.recalculateWidth();
			}
		});
		ScrollFixedFollow.lastScrollTop = ScrollFixedFollow.calculation.scroll.top();
	};

	return ScrollFixedFollow;
});

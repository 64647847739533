
Sim.require.amd.registerRaw("/public/js/Helpers/Gzip.js", ["/public/libs/pako/pako.js","/public/js/Helpers/Base64.js"],  (pako, Base64) => {

	const encode = (data, format = 'base64', options = {}) => {
		if ('to' in options) throw new Error("pako.deflate options.to='string' is deprecated");
		const result = pako.deflate(data, {
			level: 9,
			gzip: true,
			raw: false,
			...options,
		});
		if (format === 'base64')
		{
			return Base64.encode(result);
		}
		else if (format === 'binary')
		{
			return result.reduce((a, v) => a + String.fromCharCode(v), '');
		}
		else if (format === 'Uint8Array')
		{
			return result;
		}
		throw new Error(format);
	};

	const decode = (data, format = 'base64', options = {}) => {
		if (format === 'base64')
		{
			data = Base64.decode(data, true);
		}
		else if (format !== 'binary' && format !== 'Uint8Array')
		{
			throw new Error(format);
		}
		return pako.inflate(data, {
			to: format === 'Uint8Array' ? '' : 'string',
			raw: false,
			...options,
		});
	};

	return {encode, decode};
});


Sim.require.amd = new Sim.require.SimpleAMD({
	urlBase: Sim.config.basePath,
	urlArgs: `cache=${encodeURIComponent(Sim.config.buildKey)}`,
	warnOnLoad: Sim.config.isBuild,
	maps: {
		'app': '/app',
		'public': '/public',
		'libs': '/public/libs',
		'js': '/public/js',
		'': '/public/js',
	},
});

[window.define, window.require] = Sim.require.amd.createAmd();
window.define('jquery', [], _.constant(window.jQuery));
Sim.require.init();

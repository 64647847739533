Sim.require.amd.registerRaw("/app/Intranet/Banks/forms/BankConnectionAssociationControls.js", [], () => {

	class BankConnectionAssociationControls
	{

		#options;

		constructor(options)
		{
			this.#options = options;
			Sim.observeNodes(options.associationsContainer, '.m-repeaterBox', {
				addedNodes: true,
				callbackEach: ($el) => this.#checkAssociationSelections($el),
			});
		}

		#checkAssociationSelections($assoc)
		{
			if ($assoc.hasClass('notAllowed'))
			{
				return;
			}
			const owner = $assoc.find('select[name$="][owner]"]');
			const property = $assoc.find('select[name$="][property]"]');
			const contract = $assoc.find('select[name$="][contract]"]');
			const dis = this.#options.disabled;
			const check = () => {
				if (owner.val() === this.#options.ANY)
				{
					this.#options.relatedSelectBox.disableElement(property, true, dis.title, this.#options.ANY, dis.anyProperty);
				}
				else
				{
					this.#options.relatedSelectBox.disableElement(property, false);
				}
				if (owner.val() === this.#options.ANY || property.val() === this.#options.ANY)
				{
					this.#options.relatedSelectBox.disableElement(contract, true, dis.title, this.#options.ANY, dis.anyContract);
				}
				else
				{
					this.#options.relatedSelectBox.disableElement(contract, false);
				}
			};
			$().add(owner).add(property).add(contract).on('change', check);
			check();
		}

	}

	return BankConnectionAssociationControls;
});


/**
 * Komponenta na zobrazeni "levo prave" tabulky a modalu.
 *
 * <pre>
 *
 * 	<div id="id" class="leftRightPanelComponent" data-href="">
 *
 * 		<div class="leftRightPanel" n:snippet="...">
 * 			<div class="modal" data-id="1"></div>
 * 		</div>
 *
 * 		<table>
 * 			<tr data-id="1" data-href="?id=1"></tr>
 * 			<tr data-id="2" data-href="?id=2"></tr>
 * 		</table>
 *
 * 	</div>
 *
 * 	<script>
 * 		var leftRightPanelComponent = new LeftRightPanelComponent('#id');
 * 	</script>
 *
 * </pre>
 */
Sim.require.amd.registerRaw("/app/components/LeftRightPanelComponent/LeftRightPanelComponent.js", ["/public/js/ScrollFixedFollow.js","/public/js/Helpers/Scrolling.js","/app/components/AClickableArea/AClickableArea.js"],  (ScrollFixedFollow, Scrolling, AClickableArea) => {

	/**
	 * @constructor
	 * @param {(string|$)}
	 */
	var LeftRightPanelComponent = function (rootSelector) {
		this.root = $(rootSelector).filter('.leftRightPanelComponent');
		if (this.root.length !== 1) throw new Error;

		var self = this;
		this.root.on('click', '[data-id][data-href] .a-clickableArea__clickAction', function (e) {
			const row = $(e.target).closest('[data-id][data-href]');
			const id = row.attr('data-id');
			if (self.hasOpened() && self._getModal(id) !== null)
			{
				self.close();
				e.preventDefault();
			}
			else if (self._shouldTargetOpen(id, e.target))
			{
				self.open(id);
				e.preventDefault();
			}
		});
		this.root.on('click', '.leftRightPanel [data-dismiss="leftRightPanel"]', function (e) {
			self.close();
			e.preventDefault();
		});
		this.root.on('closeAndDestroyLeftRightPanelModal', () => this.closeAndDestroy());

		this.onEscapePress = Sim.onEscapePress(() => this.close());

		new AClickableArea(this.root);
	};

	/**
	 * Otevre nahled.
	 * @param {number}
	 */
	LeftRightPanelComponent.prototype.open = function (id, openWithUrl, scrollDown) {
		this._getRow(null, '.m-table__row--selected').removeClass('m-table__row--selected');
		var modal = this.root.find('.leftRightPanel').addClass('show');
		this.onEscapePress.on();
		if (!this._getRow(id).addClass('m-table__row--selected').length)
		{
			$(_.bind(function () {
				if (this._getModal(id))
				{
					this._getRow(id).addClass('m-table__row--selected');
					if (openWithUrl && this.follow && scrollDown)
					{
						this.scrollIntoView(id);
					}
				}
			}, this));
		}

		if (!this._getModal(id))
		{
			modal.addClass('loading');
			var self = this;
			this._loadData(id, function (data, textStatus, jqXHR) {
				self._destroy();
				jQuery.nette.success(data, textStatus, jqXHR);
				modal.removeClass('loading');
				self.follow.recalculate();
			});
		}
		else if (this.lastAjax)
		{
			this.lastAjax.abort();
			modal.removeClass('loading');
		}

		if (!this.follow)
		{
			this.follow = new ScrollFixedFollow(modal, this.root);
		}

		if (openWithUrl)
		{
			this.openWithUrl = true;
			if (scrollDown)
			{
				this.scrollIntoView(id);
			}
		}
	};

	LeftRightPanelComponent.prototype.close = function () {
		if (this.openWithUrl)
		{
			var rootLink = this.root.data('href');
			if (rootLink !== null)
			{
				this.root.find('.leftRightPanel').addClass('loading');
				window.location.href = rootLink;
				return;
			}
		}
		this.root.find('.leftRightPanel').removeClass('show');
		this.onEscapePress.off();
		this._getRow(null, '.m-table__row--selected').removeClass('m-table__row--selected');
	};

	LeftRightPanelComponent.prototype.closeAndDestroy = function () {
		this.close();
		this._destroy();
	};

	LeftRightPanelComponent.prototype._destroy = function () {
		const modal = this._getModal(null);
		if (modal)
		{
			modal.find('.oldAppFrameContainer[data-identifier]').trigger('Rentflow:OldAppFrame.destroy');
			modal.remove();
		}
	};

	LeftRightPanelComponent.prototype.hasOpened = function () {
		return this.root.find('.leftRightPanel').is('.show');
	};

	LeftRightPanelComponent.prototype.scrollIntoView = function (id) {
		window.history.scrollRestoration = 'manual';
		const rowScrollEl = this._getRow(id, '.m-table__row--selected').find('> :first');
		const modal = this._getModal(null, true);
		if (rowScrollEl.length)
		{
			Scrolling.scrollIntoViewIfNeeded(rowScrollEl[0], true);
			if (modal)
			{
				this.follow.recalculate();
			}
		}
		if (modal && !Scrolling.isStartMostlyInViewport(modal[0], 0.40))
		{
			Scrolling.scrollIntoViewIfNeeded(modal[0], false);
		}
	};

	/**
	 * @param {number}
	 * @param {function(object)}
	 */
	LeftRightPanelComponent.prototype._loadData = function (id, then) {
		if (this.lastAjax) this.lastAjax.abort();
		var url = this._getRow(id).data('href');
		if (!url) throw new Error(id);
		this.lastAjax = $.post({
			url: url,
			type: 'post',
			dataType: 'json',
			success: then,
			complete: function () {
				this.lastAjax = undefined;
			}.bind(this),
		});
	};

	/**
	 * @private
	 * @param {number|null} null mean all
	 * @param {null|string} only if id is null
	 * @returns {$} tr
	 */
	LeftRightPanelComponent.prototype._getRow = function (id, filterSelector) {
		filterSelector = id ? '[data-id="' + $.escapeSelector(id) + '"]' : (filterSelector || '');
		return this.root.find('[data-id][data-href]' + filterSelector);
	};

	/**
	 * @private
	 * @param {number|null} null mean any
	 * @param {bool} only for id === null
	 * @returns {$|null} tr
	 */
	LeftRightPanelComponent.prototype._getModal = function (id, fallbackToLoadingElement = false) {
		const leftRightPanel = this.root.find('.leftRightPanel');
		const modal = leftRightPanel.find('> .modal');
		if (id !== null)
		{
			const $el = modal.filter((i, el) => String($(el).data('id')) === String(id));
			return $el.length ? $el : null;
		}
		else
		{
			const $el = modal.filter((i, el) => $(el).data('id'));
			return $el.length ? $el : (fallbackToLoadingElement ? modal : null);
		}
	};

	LeftRightPanelComponent.prototype._shouldTargetOpen = function (id, target) {
		var $target = $(target).closest('a');
		if ($target.length)
		{
			var row = this._getRow(id);
			if (row.data('href') !== $target.attr('href'))
			{
				return false;
			}
		}
		return true;
	};

	return LeftRightPanelComponent;
});

Sim.require.amd.enter("/app/components/FormControls/FilesUploadControl/FilesUpload.js", () => {Sim.require.amd.registerRaw("/public/js/jquery-ui/ui/widget.js", [],  () => null);
Sim.require.amd.addListener([
	'/public/libs/jquery-file-upload/jquery.fileupload.js',
	'/public/libs/jquery-file-upload/jquery.fileupload-process.js',
	'/public/libs/jquery-file-upload/jquery.fileupload-validate.js',
], 'load:after', (module) => {
	module.exports = $.blueimp.fileupload;
});
Sim.require.amd.registerRaw("/app/components/FormControls/FilesUploadControl/FilesUpload.js", ["/public/libs/jquery-file-upload/jquery.fileupload.js","/public/libs/jquery-file-upload/jquery.fileupload-process.js","/public/libs/jquery-file-upload/jquery.fileupload-validate.js"],  () => {

	var FilesUpload = function (root) {
		this.root = root;
		this.root.data('filesUpload', this);
		this.autoSave = root.attr('data-auto-save');
		this.uploadUrl = root.attr('data-upload');
		this.htmlNameIds = root.attr('data-htmlNameIds');
		this.initSave();
		this.input = root.find('input:file.fileinput');
		this.uploadedArea = this.items = root.find('.uploaded');
		this.progress = root.find('.progress');
		this.progressBar = this.progress.find('.bar');
		if (this.input.length)
		{
			this.initJQueryCompatibility();
			this.initFileUploadEvents();
		}
	};

	FilesUpload.prototype = {

		/**
		 * @public
		 * @param {{
		 *		id: scalar,
		 *		name: string,
		 *		url: (undefined|string), // only FilesUploadWithPhotoGrid
		 *		thumbnail: (undefined|string), // only FilesUploadWithPhotoGrid
		 * }}
		 * @param {number|undefined} volitelne poradi vracene z deleteFile
		 * @returns {jQuery}
		 */
		addFile: function (file, order) {
			var el = this.createFile(file);
			if (order !== undefined && order >= 0)
			{
				var before = this.items.find('> [data-id]:eq(' + order + ')');
				(before.length === 1 ? before.before(el) : this.items.append(el));
			}
			else
			{
				this.addFileEl(el);
			}
			return el;
		},

		/**
		 * @public
		 * @param {int|string}
		 * @returns {number|undefined}
		 */
		deleteFile: function (fileId) {
			var items = this.items.find('> [data-id]');
			var item = items.filter('[data-id="' + fileId + '"]');
			if (item.length !== 1) throw new Error;
			var order = items.index(item);
			item.remove();
			return order >= 0 ? order : undefined;
		},

		/**
		 * @public
		 * @param {int|string}
		 * @returns {boolean}
		 */
		hasFile: function (fileId) {
			var item = this.items.find('> [data-id="' + fileId + '"]');
			if (item.length > 1) throw new Error;
			return item.length === 1;
		},

		initJQueryCompatibility()
		{
			jQuery.migrateMakePatchesNonStrict('type', 'isArray');

			$.type = $.type || ((o) => {
				Sim.triggerWarning('$.type deprecated');
				const types = {
					'[object Boolean]': 'boolean',
					'[object Number]': 'number',
					'[object String]': 'string',
					'[object Function]': 'function',
					'[object Array]': 'array',
					'[object Date]': 'date',
					'[object RegExp]': 'regexp',
					'[object Object]': 'object',
					'[object Error]': 'error',
					'[object Symbol]': 'symbol',
				};
				$.type = (obj) => {
					if (obj == null) // eslint-disable-line eqeqeq
					{
						return String(obj);
					}
					else if (typeof obj === 'object' || typeof obj === 'function')
					{
						return types[Object.prototype.toString.call(obj)] || 'object';
					}
					return typeof obj;
				};
				return $.type(o);
			});

			$.isArray = $.isArray || ((o) => {
				Sim.triggerWarning('$.isArray deprecated');
				return ($.isArray = (obj) => Array.isArray(obj))(o);
			});
		},

		initFileUploadEvents: function () {
			var self = this;

			var dropzone = {
				ACTIVE_DRAGGING: 'view-activeDrag',
				ACTIVE_DROPZONE: 'view-activeDrag-inDropZone',
				start: function (target) {
					self.root.addClass(dropzone.ACTIVE_DRAGGING);
					self.root.toggleClass(dropzone.ACTIVE_DROPZONE, target && $(target).closest(self.root).length !== 0);
					dropzone.moveDebounce();
				},
				stop: function () {
					self.root.removeClass(dropzone.ACTIVE_DRAGGING).removeClass(dropzone.ACTIVE_DROPZONE);
				},
				move: (target) => {
					dropzone.start(target);
					dropzone.moveDebounce();
				},
				moveDebounce: _.debounce(() => {
					dropzone.stop();
				}, 551), // dragover (every few hundred milliseconds) chrome, FF ~70ms, specification every 350ms (±200ms)
			};

			const disableSubmit = Sim.disableSubmit.create(this.root);
			const maxFileSize = parseInt(this.root.attr('data-maxfilesize'), 10) || undefined;
			this.input.fileupload({
				url: this.uploadUrl,
				formData: (form) => _.reject(form.serializeArray(), {name: '_do'}), // Nette\Application\UI\Presenter::SIGNAL_KEY
				autoUpload: true,
				prependFiles: true,
				replaceFileInput: false,
				dataType: 'json',
				dropZone: this.root,
				pasteZone: this.root,
				singleFileUploads: false,
				limitMultiFileUploadSize: maxFileSize ? Math.ceil(maxFileSize / 2) : undefined,
				maxFileSize,
				messages: {
					maxFileSize: this.root.attr('data-maxfilesize-message'),
				},
				drop: (e, data) => {
					if (!data.files.length)
					{
						self.showUnknownError();
					}
				},
				change: (e, data) => {
					this.clearInput();
				},
				start: function () {
					dropzone.stop();
					self.progress.show().addClass('progress-success');
					self.progressBar.addClass('active');
					disableSubmit.start();
				},
				fail: function (e, data) {
					self.progress.removeClass('progress-success').addClass('progress-danger');
					self.showUnknownError();
				},
				done: function (e, data) {
					self.uploadedArea.show();
					if (_.isEmpty(data.result.files))
					{
						self.showUnknownError();
					}
					_.each(data.result.files, function (file) {
						if (file.error)
						{
							self.uploadedArea.prepend(self.createError(file));
						}
						else
						{
							self.addFile(file);
						}
					});
					self.save();
				},
				always: function (e, data) {
					disableSubmit.stop();
					self.progress.hide();
					self.progressBar.removeClass('active');
					self.progressBar.width(0);
				},
				progressall: function (e, data) {
					self.updateProgressBar(data.loaded, data.total);
				},
				processfail: (e, data) => {
					this.uploadedArea.show();
					for (const file of data.files)
					{
						if (file.error)
						{
							this.uploadedArea.prepend(this.createError(file));
						}
					}
				},
			});

			$(document).on('dragenter', function (e) {
				var dataTransfer = e.originalEvent.dataTransfer;
				if (dataTransfer.items && dataTransfer.items.length && dataTransfer.items[0].kind)
				{
					if (!_.find(dataTransfer.items, function (item) { return item.kind === 'file'; }))
					{
						return;
					}
				}
				dropzone.start(e.target);
			}).on('dragend drop', function (e) {
				dropzone.stop();
			}).on('dragover', function (e) {
				dropzone.move(e.target);
			});

			this.root.find('.selectFiles').on('click keypress', (e) => {
				e.preventDefault();
				self.input.trigger('click');
			});

			this.uploadedArea.on('click', '.alert [data-dismiss="alert"]', function (e) {
				var alert = $(this).closest('.alert');
				if (alert.is('[data-id]'))
				{
					self.deleteFile(alert.attr('data-id'));
				}
				else
				{
					alert.remove();
				}
				self.save();
				e.preventDefault();
			});
		},

		clearInput()
		{
			const placeholder = $('<span>').insertAfter(this.input);
			$('<form>').append(this.input).get(0).reset();
			placeholder.replaceWith(this.input);
		},

		showUnknownError: function () {
			this.uploadedArea.show();
			this.uploadedArea.prepend(this.createError({error: this.root.attr('data-unknown-error')}));
		},

		createError: function (file) {
			var template =
				'<li class="alert alert-error">' +
					'<button type="button" class="a-closeButton" data-dismiss="alert"></button>' +
					'<span>?</span>' +
				'</li>'
			;
			var el = $(template);
			el.find('span').text(file.error ? ((file.name ? `${file.name}: ` : '') + file.error) : file.name);
			return el;
		},

		createFile: function (file) {
			const el = this.createError(file);
			el.removeClass('alert-error').addClass('alert-success');
			el.attr('data-id', file.id);
			el.data('file', file);
			el.append($('<input type="hidden">').attr('value', file.id).attr('name', this.htmlNameIds));
			return el;
		},

		addFileEl: function (el) {
			this.items.prepend(el);
		},

		updateProgressBar: function (loaded, total) {
			var percent = parseInt(loaded / total * 100, 10);
			this.progressBar.width(percent + '%');
		},

		getIds: function () {
			return _.map(this.uploadedArea.find('input[type="hidden"]'), function (el) {
				return el.value;
			});
		},

		initSave: function () {
			this.save = () => {};
			if (this.autoSave)
			{
				const handler = {
					getCurrentValue: () => this.getIds().join(','),
					trigger: () => {
						if (!handler.pause && handler.lastSavedValue !== handler.getCurrentValue())
						{
							handler.pause = true;
							handler.loading = Sim.loading(this.root, 'saving');
							handler.debounce();
						}
					},
					debounce: _.debounce(() => handler.start(), 500),
					start: () => {
						handler.value = handler.getCurrentValue();
						this.root.closest('form').trigger('submit');
					},
					stop: (success) => {
						handler.pause = false;
						handler.loading();
						if (success)
						{
							handler.lastSavedValue = handler.value;
							handler.trigger();
						}
						else
						{
							this.showUnknownError();
						}
					},
				};
				setTimeout(() => handler.lastSavedValue = handler.getCurrentValue());
				Sim.enableAjaxSubmit(this.root.closest('form'))
					.onSuccess(() => handler.stop(true))
					.onFail(() => handler.stop(false))
				;
				this.save = handler.trigger;
			}
		},

	};

	return FilesUpload;
});
});

Sim.require.amd.registerRaw("/app/components/UnifiedTableFiltering/UnifiedTableFiltering.js", ["/app/components/TagTypeahead/TagTypeahead.js"],  function (TagTypeahead) {

	var UnifiedTableFiltering = function (id, link) {
		this.$root = $(document.getElementById(id));
		this.index = this.$root.data('filter-max-index');
		var self = this;
		this.$root.on('click', 'a.add[data-filter-name]', function (e) {
			e.preventDefault();
			var $el = self.$root.find('a.add[data-filter-prerender][data-filter-name="' + $(this).attr('data-filter-name') + '"]');
			if ($el.attr('data-filter-prerender') !== '')
			{
				self.add($el);
			}
			else
			{
				$.get(link, function (data, textStatus, jqXHR) {
					jQuery.nette.success(data, textStatus, jqXHR);
					self.add(self.$root.find('a.add[data-filter-prerender][data-filter-name="' + $el.attr('data-filter-name') + '"]'));
				}, 'json');
			}
		});

		this.$root.on('click', '[data-dismiss="filter"]', function (e) {
			self.remove($(this));
			e.preventDefault();
		});

		this.$root.on('click', 'a[shift-href]', function (e) {
			if (e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey && e.which === 1)
			{
				location.href = $(this).attr('shift-href');
				return false;
			}
		});

		TagTypeahead.register();

		this.updateContainer();
	};

	UnifiedTableFiltering.prototype.add = function ($el) {
		var html = $el.data('filter-prerender');
		if (!html) throw new Error('Filters are not prerendered.');
		this.index++;
		html = html.replace(/(['"]|&#39;|&quot;)([\w]+)\[form\]\[/g, '$1filters[' + this.index + '][$2][form][');
		// filterName[form][xxx] => filters[x][filterName][form][xxx]

		const idMask = Sim.escapeRegex(this.$root.attr('data-filter-id-mask'));
		const re = new RegExp(`(['"]|&#39;|&quot;)(${idMask})-filters-([\\w]+)-form-`, 'gu');
		html = html.replace(re, `$1$2-form-filters-${this.index}-$2-form-`);
		// frm-filter-filters-filterName-form-xxx => frm-filter-form-filters-x-filterName-form-xxx

		html = html.replace(/(\?|&|&amp;)filter-name=(?:0|filters)(&|$)/i, '$1filter-name=' + this.index + '$2');

		var filter = this.$root.find('.active-filters').append(html).find('.active-filter:last');
		filter.find(':input:first').trigger('focus');
		Sim.init(filter);

		TagTypeahead.register();

		$el.addClass('active');
		this.updateContainer();

		filter.find('.focus-on-init:first').trigger('focus');
	};

	UnifiedTableFiltering.prototype.remove = function ($el) {
		var activeFilter = $el.closest('.active-filter').remove();

		this.$root.find('a.add[data-filter-prerender][data-filter-name="' + activeFilter.attr('data-filter-name') + '"]')
			.toggleClass(
				'active',
				this.$root.find('.active-filters > .active-filter[data-filter-name="' + activeFilter.attr('data-filter-name') + '"]').length !== 0
			)
		;
		this.updateContainer();
	};

	UnifiedTableFiltering.prototype.updateContainer = function () {
		var el = this.$root.find('[data-count-active-filters]');
		var n = this.$root.find('.active-filters > .active-filter').length;
		el.toggleClass('hide', !el.data('count-active-filters') && !n);
	};

	return UnifiedTableFiltering;
});

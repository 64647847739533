Sim.require.amd.registerRaw("/app/components/HtmlEditor/Buttons/BaseButton.js", ["/app/components/HtmlEditor/Simditor.js","/app/components/HtmlEditor/Utils.js"],  (Simditor, Utils) => {

	class BaseButton extends Simditor.Button
	{

		iconClassOf(icon)
		{
			return icon;
		}

		mustDisableForHtmlMode()
		{
			return true;
		}

		isGloballyAllowedTag(tag, attr = null)
		{
			return Utils.isGloballyAllowedTag(this.editor, tag, attr);
		}

	}

	return BaseButton;
});

Sim.require.amd.registerRaw("/app/components/TagTypeahead/TagTypeahead.js", ["/public/js/Helpers/TextSearchMatcher.js"],  (TextSearchMatcher) => {

	var TagTypeahead = function TagTypeahead(el) {
		this.el = this.$input = $(el);
		if (this.el.length !== 1) throw new Error;
		if (!this.el.is('input:text')) throw new Error;
		if (!this.el.data('TagTypeahead'))
		{
			this.inicializeTypeahead();
			this.inicializeTags();
			this.el.data('TagTypeahead', this);
		}
	};

	TagTypeahead.prototype = {

		SEPARATOR: ';', // Semicolon (U+003B) Sim\TagTypeahead::SEPARATOR
		SEPARATOR_FAKE_PLACEHOLDER: ';', // Greek question mark (U+037E) Sim\TagTypeahead::SEPARATOR_FAKE_PLACEHOLDER

		inicializeTypeahead: function () {

			this.th = {
				shown: false,
				focused: false,
				mousedover: false,
				query: '',
				$menu: this.$menu = $('<ul class="m-dropdownMenu"></ul>'),
				options: {
					minLength: parseInt(this.$input.attr('data-minlength') ?? 1, 10),
					items: parseInt(this.$input.attr('data-items') ?? 12, 10),
					footerText: this.$input.attr('data-footer-text'),
					defaults: JSON.parse(this.$input.attr('data-defaults') ?? '[]'),
				},
			};

			this.th.show = () => {
				const pos = $.extend({}, this.$input.position(), {height: this.$input[0].offsetHeight});
				this.$menu.insertAfter(this.$input).show();
				this.$menu.css({
					top: `${pos.top + pos.height}px`,
					left: `${Sim.config.isRTL ? pos.left - this.$menu.outerWidth() + this.$input.outerWidth() : pos.left}px`,
				});
				this.th.shown = true;
			};

			this.th.hide = () => {
				this.$menu.hide();
				this.th.shown = false;
			};

			this.th.select = () => {
				const val = this.$menu.find('.active').attr('data-value');
				this.$input.val(this.updater(val)).trigger('change');
				this.th.hide();
			};

			this.$menu.on('click', (e) => {
				e.stopPropagation();
				e.preventDefault();
				this.th.select();
				this.$input.trigger('focus');
			});

			this.$input.on('focus', () => {
				this.th.focused = true;
				// Kdyz obsahuje prvek text tak se zobrazi moznosti pri vraceni, nebo kdyz je zaple ze se ma zobrazit vzdy.
				this.th.lookup();
				// Kdyz pri vybrani prvku mysi nad jinym menu poposkoci tak ze mys skonci nad druhym prvkem,
				// tak se i po kliknuti na druhy prvek menu z prvniho prvku nezmizi. Protoze se nezavola ze mys z menu odjela.
				this.th.mousedover = false;
			});

			this.$input.on('blur', () => {
				this.th.focused = false;
				if (!this.th.mousedover && this.th.shown) this.th.hide();
			});

			this.$menu.on('mouseenter', '> .m-dropdownMenu__row', (e) => {
				this.th.mousedover = true;
				this.$menu.find('.active').removeClass('active');
				$(e.currentTarget).addClass('active');
			});

			this.$menu.on('mouseleave', '> .m-dropdownMenu__row', () => {
				this.th.mousedover = false;
				if (!this.th.focused && this.th.shown) this.th.hide();
			});

			this.th.lookup = () => {
				// Kdyz se nic nenajde tak neobsahuje v dom prvky.
				this.$menu.empty();
				this.th.query = this.$input.val() || '';
				if (this.th.query.length < this.th.options.minLength)
				{
					if (this.th.query.length === 0 && this.th.options.defaults.length)
					{
						this.th.process((items) => {
							const res = [];
							for (const key of this.th.options.defaults)
							{
								const item = _.find(items, {key});
								if (item)
								{
									res.push(item);
								}
							}
							return res;
						}, (items) => items);
						return;
					}
					if (this.th.shown) this.th.hide();
					return;
				}
				this.th.process(
					(items, matcher) => _.filter(items, (item) => matcher.isMatch(item.normalized)),
					(items, matcher) => this.sorter(items, matcher).slice(0, this.th.options.items)
				);
			};

			this.th.process = (p1, p2) => {
				const process = (items) => {
					const matcher = this.createMatcher();
					items = p1(items, matcher);
					this.th.render(p2(items, matcher), items.length, matcher);
				};
				const items = this.getSource(this.th.query, process);
				if (items) process(items);
			};

			// Pro prazdne hledani neni prvni moznost aktivni.
			// pass full item to highlighter, but sets only item.text to data-value
			this.th.render = (items, total, matcher) => {
				if (!items.length)
				{
					if (this.th.shown) this.th.hide();
					return;
				}
				const els = items.map((item) => {
					const el = $('<li class="m-dropdownMenu__row"><a></a></li>').attr('data-value', item.key);
					el.find('a').html(this.highlighter(item.text, item, matcher));
					return el[0];
				});
				if (els.length > 0 && this.$input.val() !== '')
				{
					$(els[0]).addClass('active');
				}
				this.$menu.html(els);
				if (this.th.options.footerText && els.length !== total)
				{
					const text = this.th.options.footerText
						.replace('%number%', els.length)
						.replace('%total%', total)
					;
					this.$menu.append($('<li class="m-dropdownMenu__footer">').text(text));
				}
				this.th.show();
			};

			// Enter se spracovava odlisne.
			// Vzdy prida hodnoty, i kdyz se nic nenajde.
			// A odesila jen kdyz je prvek prazdny.

			this.$input.on('keydown', (e) => {
				let nextOrPrev;
				switch (e.keyCode)
				{
					case 9: // tab
					case 27: // escape
						if (!this.th.shown) return;
						e.preventDefault();
						break;

					case 13: // enter
						if (this.$input.val() === '' && (!this.th.shown || !this.$menu.children('.active').length))
						{
							return;
						}
						e.preventDefault();
						break;

					case 38: // up arrow
					case 40: // down arrow
						if (!this.th.shown) return;
						e.preventDefault();
						nextOrPrev = this.$menu.find('.active').removeClass('active');
						nextOrPrev = e.keyCode === 38 ? nextOrPrev.prev() : nextOrPrev.next();
						if (!nextOrPrev.length)
						{
							nextOrPrev = this.$menu.find('> .m-dropdownMenu__row');
							nextOrPrev = e.keyCode === 38 ? nextOrPrev.last() : nextOrPrev.first();
						}
						nextOrPrev.addClass('active');
						break;
				}
				e.stopPropagation();
			});

			this.$input.on('keyup', (e) => {
				switch (e.keyCode)
				{
					case 40: // down arrow
					case 38: // up arrow
					case 16: // shift
					case 17: // ctrl
					case 18: // alt
						break;

					case 9: // tab
						if (!this.th.shown) return;
						this.th.select();
						break;

					case 13: // enter
						if (this.$input.val() === '' && (!this.th.shown || !this.$menu.children('.active').length))
						{
							return;
						}
						this.th.select();
						this.$input.trigger('focus');
						break;

					case 27: // escape
						if (!this.th.shown) return;
						this.th.hide();
						break;

					default:
						this.th.lookup();
				}
				e.stopPropagation();
				e.preventDefault();
			});

			// Submit prida hodnotu, jako by se mackl enter v prvku.
			this.el.closest('form').on('submit', _.bind(function (e) {
				if (this.el.val() !== '') this.select();
			}, this));
		},

		inicializeTags: function () {
			this.hidden = $('<input type="hidden">').attr('name', this.el.attr('name'));
			this.hidden.insertAfter(this.el);
			this.el
				.removeAttr('name')
				.attr('autocomplete', 'off')
			;

			this.selected = {};
			for (let key of this.el.val().split(this.SEPARATOR))
			{
				if (key.indexOf(this.SEPARATOR_FAKE_PLACEHOLDER) !== -1)
				{
					key = key.replace(new RegExp(Sim.escapeRegex(this.SEPARATOR_FAKE_PLACEHOLDER), 'gu'), this.SEPARATOR);
				}
				this.addText(key);
			}
			this.el.val('');

			// Backspace maze jednu posledni vybranou hodnotu pri prazdnem inputu.
			this.el.on('keydown', _.bind(function (e) {
				this.backspaceOnEmptyPressed = (
					e.which === 8 ? ( // backspace
						this.backspaceOnEmptyPressed === undefined ?
						this.el.val() === '' :
						this.backspaceOnEmptyPressed
					) : false
				);
			}, this));
			this.el.on('keyup', _.bind(function (e) {
				if (e.which === 8 && this.backspaceOnEmptyPressed) // backspace
				{
					this.removeText(_.last(_.keys(this.selected)));
				}
				this.backspaceOnEmptyPressed = undefined;
			}, this));
		},

		select: function () {
			if (this.th.shown && this.th.$menu.children('.active').length)
			{
				this.th.select();
			}
			else if (!this.th.shown && this.el.val() !== '' && this.th.$menu.children().length)
			{
				this.th.$menu.children('.active').removeClass('active');
				this.th.$menu.children(':first').addClass('active');
				this.th.select();
			}
			else if (this.el.val() !== '')
			{
				this.addText(this.el.val());
				this.el.val('');
			}
		},

		addText: function (text) {
			if (text === undefined || text === '') return;
			const sourceItem = this.getSourceItem(text);
			const item = sourceItem ? sourceItem.key : text;
			if (this.selected[item] === undefined)
			{
				this.selected[item] = {
					label: this.createLabel(
						item,
						sourceItem ? sourceItem.text : text,
						undefined,
						!sourceItem
					).insertBefore(this.el),
				};
			}
			this.updateHidden();
		},

		removeText: function (text) {
			if (this.selected[text] !== undefined)
			{
				this.selected[text].label.remove();
				delete this.selected[text];
			}
			this.updateHidden();
		},

		updateHidden() {
			this.hidden.val(_.keys(this.selected).map((key) => {
				if (key.indexOf(this.SEPARATOR) !== -1)
				{
					key = key.replace(new RegExp(Sim.escapeRegex(this.SEPARATOR), 'gu'), this.SEPARATOR_FAKE_PLACEHOLDER);
				}
				return key;
			}).join(this.SEPARATOR));
		},

		createLabel(item, text, search = text, notFound) {
			return $('<span>')
				.addClass('TagTypeahead-label')
				.toggleClass('notFound', notFound)
				.append(
					$('<span>')
						.text(text)
						.attr('title', text)
						.one('dblclick', _.bind(function () {
							this.removeText(item);
							this.select();
							this.el.val(search).trigger('focus');
							return false;
						}, this))
				)
				.append(
					$('<a>')
						.attr('href', '#')
						.attr('title', this.el.attr('data-remove-text'))
						.html('<i class="icon-remove-sign"></i>')
						.one('click', _.bind(function () {
							this.removeText(item);
							return false;
						}, this))
				)
			;
		},

		getSource: function () {
			if (this.source === undefined)
			{
				var items = JSON.parse(this.el.attr('data-source'));
				this.el.removeAttr('data-source'); // faster dom inspector
				this.source = Object.values(this.createSource(items));
				const sourceHashMap = new Map;
				for (const item of this.source)
				{
					item.normalized = this.normalize(item.text);
					if (sourceHashMap.has(item.key) && sourceHashMap.get(item.key).text !== item.text)
					{
						const tmp = sourceHashMap.get(item.key).text;
						throw new Error(`incompatible labels in duplicate '${item.key}' are not supported ('${tmp}' and '${item.text}')`);
					}
					else
					{
						sourceHashMap.set(item.key, item);
					}
				}
				for (const item of this.source)
				{
					if (!sourceHashMap.has(item.text))
					{
						sourceHashMap.set(item.text, item);
					}
				}
				this.sourceHashMap = sourceHashMap;
			}
			return this.source;
		},

		createSource: function (items) {
			items = _.map(items, (item) => ({
				key: String(item.key),
				text: String(item.text),
			}));
			if (!this.el.attr('data-source-keep-order'))
			{
				items = _.filter(items, 'text');
				items = _.sortBy(items, 'text');
				items = _.uniq(items, true, 'text');
			}
			return items;
		},

		getSourceItem(keyOrText) {
			this.getSource(); // init
			return this.sourceHashMap.get(keyOrText);
		},

		updater: function (text) {
			this.addText(text);
			return '';
		},

		normalize: TextSearchMatcher.normalize,

		createMatcher: function () {
			return new TextSearchMatcher(this.th.query);
		},

		sorter: function (items, matcher) {
			return matcher.sortItemsBasedOnBestMatch(items, (item) => item.text);
		},

		highlighter: function (text, item, matcher) {
			return matcher.highlighterTextToHtml(text, 200);
		},

	};

	TagTypeahead.register = function () {
		$('[data-provide="TagTypeahead"]').each(function () {
			new TagTypeahead(this);
		});
	};

	return TagTypeahead;
});

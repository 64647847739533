Sim.require.amd.registerRaw("/app/components/HtmlEditor/HtmlEditor.js", ["/app/components/HtmlEditor/Simditor.js","/app/components/HtmlEditor/Utils.js","/app/components/HtmlEditor/Buttons/BaseButton.js","/app/components/HtmlEditor/Buttons/TitleButton.js","/app/components/HtmlEditor/Buttons/LinkButton.js","/app/components/HtmlEditor/Buttons/HtmlButton.js","/app/components/HtmlEditor/Buttons/FullscreenButton.js"], 







 (
	Simditor,
	Utils,
	BaseButton,
	TitleButton,
	LinkButton,
	HtmlButton,
	FullscreenButton,
) => {

	class HtmlEditor
	{

		#options;
		#simditor;

		constructor(options)
		{
			this.#options = options;
			const $textarea = $(options.textarea);
			if ($textarea.length !== 1) throw new Error;
			if (!$textarea.is('textarea')) throw new Error;

			this.#simditor = new Simditor({
				textarea: $textarea,
				toolbar: ['bold', 'italic', 'title', 'ul', 'link', 'html', 'fullscreen'],
				allowedTags: ['p', 'a', 'b', 'strong', 'i', 'em', 'br', 'h1', 'h2', 'h3', 'ul', 'li'],
				allowedAttributes: {a: options.enableLinkTarget ? ['href', 'target'] : ['href']},
				tabIndent: false,
				allowedStyles: {},
				codeLanguages: [],
				translate: (...a) => this.translate(...a),
			});

			if (options.addClassName)
			{
				this.#simditor.el.addClass(options.addClassName);
			}
			this.#simditor.el.addClass('o-htmlEditor');
			this.#simditor.body.attr('lang', this.#simditor.textarea.attr('lang'));
			this.#simditor.body.attr('dir', this.#simditor.textarea.attr('dir'));

			const controlKey = this.#simditor.util.os.mac ? 'cmd' : 'ctrl';
			for (const [tag, key] of [['b', 'b'], ['i', 'i'], ['u', 'u'], ['ul', '.'], ['ol', '/']])
			{
				if (!Utils.isGloballyAllowedTag(this.#simditor, tag))
				{
					this.#simditor.hotkeys.add(`${controlKey}+${key}`, () => false);
				}
			}

			$textarea.on('change', () => {
				this.#simditor.hidePopover();
				this.#simditor.body.trigger('focus');
				this.#simditor.undoManager._pushUndoState();
				this.#simditor.trigger('selectionchanged');
				this.#simditor.setValue($textarea.val());
				this.#simditor.body.trigger('blur');
				$('input[type="text"]').trigger('focus').trigger('blur'); // otherwise simditor becomes stuck
			});
		}

		translate(key)
		{
			const text = Simditor.i18n['en-US'][key] ?? null;
			const translation = (this.#options.translations[key] ?? (text !== null ? this.#options.translations[text] : null) ?? null);
			if (translation === null)
			{
				Sim.triggerWarning(`HtmlEditor: missing translation for '${key}' '${text}'`);
			}
			// Simditor xss, some translations are escaped twice
			return Sim.escapeHtml(translation ?? text ?? key);
		}

		static addButton(Klass)
		{
			if (!(Klass.prototype instanceof BaseButton)) throw new Error;
			if (!Klass.prototype.name) throw new Error;
			Simditor.Toolbar.addButton(Klass);
		}

	}

	HtmlEditor.addButton(TitleButton);
	HtmlEditor.addButton(LinkButton);
	HtmlEditor.addButton(HtmlButton);
	HtmlEditor.addButton(FullscreenButton);

	return HtmlEditor;
});

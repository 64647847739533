Sim.require.amd.registerRaw("/public/js/ContinuousAjaxUpdated.js", [], () => {

	class ContinuousAjaxUpdated
	{

		static MAX_NUMBER_OF_REQUESTS = 2;
		static #numberOfRequestsRunning = 0;
		static #cache = new Map;

		static IDLE = 0;
		static RUNNING = 1;
		static ABORTED_EARLY = 2;

		#options;
		#runningState = ContinuousAjaxUpdated.IDLE;
		#lastFinishedCacheKey;
		#lastRunningCacheKey;
		#abort;

		constructor(options)
		{
			if (!options.url || !options.update || !options.args)
			{
				throw new Error;
			}
			this.#options = {
				delay: 100,
				parametersPrefix: '',
				before: () => {},
				after: () => {},
				disabled: () => null,
				spinner: $(),
				...options,
			};
			this.#options.spinner.hide();
		}

		request()
		{
			if (this.#runningState === ContinuousAjaxUpdated.IDLE)
			{
				this.#runningState = ContinuousAjaxUpdated.RUNNING;
				setTimeout(() => {
					this.#run()
						.finally(() => { this.#runningState = ContinuousAjaxUpdated.IDLE; })
						.catch((err) => { throw err; })
					;
				}, 0);
			}
			else
			{
				this.rerunTimeout && clearTimeout(this.rerunTimeout);
				this.rerunTimeout = setTimeout(() => {
					if (this.#runningState === ContinuousAjaxUpdated.RUNNING && this.#isChanged())
					{
						this.#abort ? this.#abort() : (this.#runningState = ContinuousAjaxUpdated.ABORTED_EARLY);
					}
				}, 200);
			}
		}

		#isChanged()
		{
			if (this.#lastRunningCacheKey !== undefined)
			{
				const {runCacheKey} = this.#prepareParameters();
				return runCacheKey !== this.#lastRunningCacheKey;
			}
		}

		async #run()
		{
			let data;
			let changed = false;
			let changedAwaited = false;
			let deep = 0;
			while (true) // eslint-disable-line no-constant-condition
			{
				if (changed && changedAwaited)
				{
					await new Promise((resolve) => setTimeout(resolve, this.#options.delay)); // sleep
				}

				const {runCacheKey, ajaxParameters, getAjaxCacheKey, args, disabled} = this.#prepareParameters();
				if (String(runCacheKey) === this.#lastFinishedCacheKey && (!changed || data !== undefined))
				{
					break; // nothing changed
				}
				this.#lastRunningCacheKey = runCacheKey;

				if (!changed)
				{
					changed = true;
					this.#options.spinner.show();
					this.#options.before();
				}

				if (deep++ > 10000)
				{
					throw new Error(`Infinite loop detected for ${runCacheKey}`);
				}

				if (disabled !== undefined)
				{
					data = disabled;
				}
				else
				{
					changedAwaited = true;
					data = await this.#makeCachedRequest(ajaxParameters, getAjaxCacheKey());
				}
				if (data !== undefined)
				{
					this.#lastFinishedCacheKey = runCacheKey;
					this.#options.update(data, args);
				}
			}
			this.#lastRunningCacheKey = undefined;

			if (changed)
			{
				this.#options.after(data);
				this.#options.spinner.hide();
			}
		}

		#prepareParameters()
		{
			const args = this.#options.args();
			const disabled = this.#options.disabled(args) ?? undefined;
			if (disabled !== undefined)
			{
				return {
					args,
					disabled,
					runCacheKey: this.#getCacheKey(disabled, 'disabled'),
				};
			}
			const runParameters = {};
			const ajaxParameters = {};
			for (const [key, value] of Object.entries(args))
			{
				runParameters[this.#options.parametersPrefix + key] = value;
				if (key.substring(0, 1) !== '#') // private
				{
					ajaxParameters[this.#options.parametersPrefix + key] = value;
				}
			}
			return {
				args,
				runCacheKey: this.#getCacheKey(runParameters),
				ajaxParameters,
				getAjaxCacheKey: () => this.#getCacheKey(ajaxParameters),
			};
		}

		#getCacheKey(parameters, type = this.#options.url)
		{
			return `${type}?${JSON.stringify(parameters)}`;
		}

		async #makeCachedRequest(ajaxParameters, cacheKey)
		{
			if (!ContinuousAjaxUpdated.#cache.has(cacheKey))
			{
				if (ContinuousAjaxUpdated.#numberOfRequestsRunning >= ContinuousAjaxUpdated.MAX_NUMBER_OF_REQUESTS)
				{
					await new Promise((resolve) => setTimeout(resolve, 400)); // sleep
					return undefined; // rerun with fresh args
				}
				let data;
				try
				{
					ContinuousAjaxUpdated.#numberOfRequestsRunning++;
					const promise = ContinuousAjaxUpdated.#makeRequest(this.#options.url, ajaxParameters);
					this.#handleAbortRequest(promise);
					ContinuousAjaxUpdated.#cache.set(cacheKey, promise);
					data = await promise;
				}
				catch (err)
				{
					ContinuousAjaxUpdated.#cache.delete(cacheKey);
					throw err;
				}
				finally
				{
					ContinuousAjaxUpdated.#numberOfRequestsRunning--;
				}
				if (data === undefined) // aborted
				{
					ContinuousAjaxUpdated.#cache.delete(cacheKey);
				}
				else
				{
					ContinuousAjaxUpdated.#cache.set(cacheKey, data);
				}
				return data;
			}
			const promise = ContinuousAjaxUpdated.#cache.get(cacheKey);
			this.#handleAbortRequest(promise);
			return promise;
		}

		#handleAbortRequest(promise)
		{
			this.#abort = null;
			if (promise instanceof Promise)
			{
				this.#abort = promise.createCustomAbort();
			}
			if (this.#runningState === ContinuousAjaxUpdated.ABORTED_EARLY)
			{
				this.#runningState = ContinuousAjaxUpdated.RUNNING;
				this.#abort && this.#abort();
			}
		}

		static #makeRequest(url, ajaxParameters)
		{
			let abortHoldCounter = 0;
			let ajax;
			const promise = new Promise((resolve, reject) => {
				ajax = $.ajax({
					url: url,
					data: ajaxParameters,
					dataType: 'json',
					method: 'POST',
					global: false, // ignore Sim.loading
					success: (data) => {
						abortHoldCounter = -1;
						resolve(data);
					},
					error: (jqXHR, textStatus) => {
						abortHoldCounter = -1;
						if (textStatus === 'abort')
						{
							resolve(undefined);
						}
						else
						{
							reject(new Error);
						}
					},
				});
			});
			promise.createCustomAbort = () => {
				abortHoldCounter++;
				let abortedHold = false;
				return () => {
					(!abortedHold && --abortHoldCounter === 0) && ajax.abort();
					abortedHold = true;
				};
			};
			return promise;
		}

	}

	return ContinuousAjaxUpdated;
});

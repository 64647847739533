Sim.require.amd.registerRaw("/app/components/DropdownMenuSearch/SearchSelectBox.js", ["/app/components/DropdownMenuSearch/DropdownMenuSearch.js","/public/js/Helpers/TextSearchMatcher.js"], 


 (DropdownMenuSearch, TextSearchMatcher) => {

	Sim.addNetteValidator('Rentflow:SearchSelectBox.unselected', (elem) => (
		!$(elem).prop('disabled') &&
		$(elem).data('Rentflow:SearchSelectBox.searchValue') === ($(elem).data('Rentflow:SearchSelectBox.selectedSearchValue') ?? '')
	));

	class SearchSelectBox extends DropdownMenuSearch
	{

		#options;
		#searchInput;
		#selectBox;

		constructor(selectBox, options = {})
		{
			options.minLength = 0;
			let $selectBox = $(selectBox);
			if ($selectBox.is('select'))
			{
				options.container = $selectBox.parent();
			}
			else
			{
				options.container = $selectBox;
				$selectBox = $selectBox.find(':input');
			}
			if ($selectBox.length !== 1) throw new Error;
			if (!$selectBox.is('select')) throw new Error;
			if ($selectBox.data('SearchInput')) throw new Error;
			const $searchInput = options.searchInputSelector = $('<input type="text" class="a-selectBoxLikeInput">');
			$selectBox.hide().after($searchInput).after('<span class="a-selectBoxLikeInput__icon">');
			$searchInput.after('<span class="a-selectBoxLikeInput__icon">');
			$selectBox.data('SearchInput', $searchInput);

			$searchInput.on('focus mousedown', () => {
				this.#setSearchValue(true);
			});
			$searchInput.on('blur', () => {
				if (!document.hasFocus())
				{
					return;
				}
				this.#setSearchValue(false);
			});
			$selectBox[0].focus = () => { $searchInput.trigger('focus'); }; // support netteForms

			super(options);
			this.#options = options;
			this.$menu().addClass('m-dropdownMenu--fixed');

			this.#searchInput = $searchInput;
			this.#selectBox = $selectBox;

			this.selectItem($selectBox.val());
			$selectBox.on('Rentflow:SearchSelectBox.change change', (event) => {
				this.selectItem($selectBox.val(), null, event);
			});

			const id = $selectBox.attr('id');
			if (id)
			{
				$(`label[for="${$.escapeSelector(id)}"]`).attr('for', `${id}--SearchSelectBox`);
				$searchInput.attr('id', `${id}--SearchSelectBox`);
			}

			$searchInput.attr('data-nette-rules', JSON.stringify([{
				op: 'Rentflow:SearchSelectBox.unselected',
				msg: this.getText('noResults'),
			}]));

			this.#initSize();
		}

		lookupNonAjax(query = '', raw = false)
		{
			const items = [];
			const dups = new Set;
			const matcher = new TextSearchMatcher(query);
			for (const option of this.#selectBox.find('option')) // include inside of optgroup
			{
				const $option = $(option);
				const text = $option.text();
				if (matcher.isMatch(text))
				{
					const value = $option.attr('value');
					if (this.#options.ignoreDuplicateValues && query !== '')
					{
						if (dups.has(value)) continue;
						dups.add(value);
					}
					items.push({
						value,
						text: raw ? text : [
							matcher.highlighterTextToHtml(text),
							..._.map($option.parents('optgroup'), (optgroup) => {
								const $optgroup = $(optgroup);
								return {
									tag: 'span',
									class: 'a-muted',
									content: $optgroup.attr('label'),
								};
							}),
						],
					});
				}
			}
			return items;
		}

		highlighter(text)
		{
			return document.createTextNode(text);
		}

		renderItem(value, text, el, item)
		{
			if (value === this.#selectBox.val())
			{
				el.addClass('m-dropdownMenu__row--selected');
			}
			super.renderItem(value, text, el, item);
		}

		selectItem(value, el, event)
		{
			super.selectItem(value, el, event);
			const prevValue = this.#selectBox.val();
			this.#selectBox.val(value);
			const newValue = this.#selectBox.val();
			if (prevValue !== newValue)
			{
				this.#selectBox.trigger(new $.Event('change', {delegatedEvent: event}));
			}
			this.#searchInput.attr('placeholder', _.findWhere(this.lookupNonAjax('', true), {value: newValue})?.text);
			this.#setSearchValue(false, true);

			this.#searchInput.prop('disabled', this.#selectBox.prop('disabled') ?? false);
			this.#searchInput.attr('title', this.#selectBox.attr('title') ?? null);
		}

		#setSearchValue(focused, selected = false)
		{
			const text = this.getLastQueryString();
			this.#searchInput.data('Rentflow:SearchSelectBox.searchValue', text);
			this.#searchInput.val(focused ? text : '');
			if (selected)
			{
				this.#searchInput.data('Rentflow:SearchSelectBox.selectedSearchValue', text);
			}
		}

		#initSize()
		{
			if (this.#searchInput.css('box-sizing') === 'border-box')
			{
				this.#searchInput.addClass('a-selectBoxLikeInput--sizeStatic');
				return;
			}
			const sizeDynamic = () => {
				this.#selectBox.show();
				this.#searchInput.css({
					'height': `${this.#selectBox.outerHeight()}px`,
					'width': `${this.#selectBox.outerWidth()}px`,
				});
				this.#selectBox.hide();
				if (!$.contains(document, this.#searchInput[0]))
				{
					clearInterval(interval); // eslint-disable-line no-use-before-define
				}
			};
			const interval = setInterval(sizeDynamic, 1000);
			this.#searchInput.addClass('a-selectBoxLikeInput--sizeDynamic');
			sizeDynamic();
		}

	}

	return SearchSelectBox;
});

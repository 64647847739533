Sim.require.amd.registerRaw("/app/Intranet/components/HellForms/MultipleHellFormQueue.js", ["/app/components/Popups/Popups.js"],  (Popups) => {

	class MultipleHellFormQueue
	{

		constructor(options)
		{
			this.options = options;
			this.queue = options.queue;
			this.root = $(options.root);
			this.progress = this.root.find('.progress');
			this.progressBar = this.progress.find('.bar');
			this.progressTotal = this.root.find('.progressTitle .total');
			this.progressCurrent = this.root.find('.progressTitle .current');
			this.closeButton = this.root.find('.modal-footer .cancel');
			this.start();
		}

		start()
		{
			this.offPreventClosing = this.preventClosing();
			this.closeButton.hide();
			this.done = 0;
			this.total = this.queue.length + 1;
			this.progress.addClass('progress-success');
			this.progressBar.addClass('active');
			this.next();
		}

		next()
		{
			this.done++;
			this.progressTotal.text(`${this.done}/${this.total}`);
			this.progressBar.width(`${this.done / this.total * 100}%`);

			const queueItem = this.queue.shift();
			if (queueItem === undefined)
			{
				this.stop();
				return;
			}
			this.progressCurrent.text(queueItem.title);

			$.ajax({
				url: queueItem.url,
				dataType: 'json',
				error: () => this.stop(new Error(JSON.stringify(queueItem))),
				success: (json) => {
					if (json.result !== 'ok') this.stop(new Error(JSON.stringify(queueItem)));
					this.next();
				},
			});
		}

		stop(error)
		{
			this.offPreventClosing();
			this.offPreventClosing = null;
			if (error)
			{
				this.closeButton.show();
				this.progress.removeClass('progress-success').addClass('progress-danger');
				this.progressCurrent.text('error');
				setTimeout(() => alert('error'), 0);
				throw error;
			}
			else
			{
				this.progressCurrent.text('');
				Sim.redirect(this.options.doneLink);
			}
		}

		preventClosing()
		{
			const unload = (e) => {
				e.preventDefault();
				e.returnValue = this.options.closeText || '';
			};
			window.addEventListener('beforeunload', unload);

			const onEscapePress = Sim.onEscapePress();
			onEscapePress.on();

			let disableClose = true;
			Popups.onClose(this.options.popupName, (e) => {
				if (disableClose)
				{
					e.preventDefault();
					alert(this.options.closeText);
				}
			});

			return () => {
				window.removeEventListener('beforeunload', unload);
				onEscapePress.off();
				disableClose = false;
			};
		}

	}

	return MultipleHellFormQueue;
});

Sim.require.amd.registerRaw("/app/Intranet/components/Contracts/ContractNoPmcTotalSum.js", [], () => {

	class ContractNoPmcTotalSum
	{

		#form;

		constructor(form)
		{
			this.#form = $(form);
			this.#form.on('change input', 'input[name="price"], input[name="charges"]', () => this.#recalculateNoPmcUnits());
			this.#recalculateNoPmcUnits();
		}

		#recalculateNoPmcUnits()
		{
			const total = Sim.number.parse(this.#form.find('input[name="price"]').val()) + Sim.number.parse(this.#form.find('input[name="charges"]').val());
			this.#form.find('div.totalSum span.amount').text(Sim.number.format(total));
		}

	}

	return ContractNoPmcTotalSum;
});

Sim.require.amd.registerRaw("/public/js/Helpers/TextSearchMatcher.js", ["/app/model/Services/Stuff/UnicodeToAscii/UnicodeToAsciiTable.jsonp.js"],  (UnicodeToAsciiTable) => {

	class TextSearchMatcher
	{

		#query;
		#normalizedSplitQuery;

		static #cache;
		static #sortDefinition;

		constructor(query)
		{
			this.#query = query;
			if (!TextSearchMatcher.#cache || TextSearchMatcher.#cache[0] !== query)
			{
				const normalizedSplitQuery = [];
				for (let t of query.split(/\s+/u))
				{
					t = TextSearchMatcher.normalize(t);
					if (t === '') continue;
					normalizedSplitQuery.push(t);
				}
				TextSearchMatcher.#cache = [query, normalizedSplitQuery];
			}
			this.#normalizedSplitQuery = TextSearchMatcher.#cache[1];
		}

		static normalize(text)
		{
			text = text.replace(/[^a-zA-Z0-9 ]/gu, (char) => UnicodeToAsciiTable[char] ?? '');
			text = text.toLowerCase();
			text = text.replace(/[^a-z0-9]+/gu, '');
			return text;
		}

		isMatch(text)
		{
			if (this.#normalizedSplitQuery.length === 0)
			{
				return true;
			}
			const normText = TextSearchMatcher.normalize(text);
			for (const part of this.#normalizedSplitQuery)
			{
				if (!normText.includes(part))
				{
					return false;
				}
			}
			return true;
		}

		highlighterTextToHtml(highlightText, truncateTo = null)
		{
			const h = new HighlighterBuilder(truncateTo);
			h.buildFromInternalMatcher(this.#normalizedSplitQuery, highlightText);
			return h.finalizeToHtml();
		}

		static createCustomHighlighterBuilder(truncateTo = null)
		{
			return new HighlighterBuilder(truncateTo);
		}

		sortItemsBasedOnBestMatch(items, mapFn = (v) => v)
		{
			if (this.#normalizedSplitQuery.length === 0)
			{
				return items;
			}

			if (!TextSearchMatcher.#sortDefinition)
			{
				const isSame = (a, b) => (a === b);
				const includes = (a, b) => a.includes(b);
				const any = () => true;
				const lower = (a, b, fn) => fn(a.toLowerCase(), b.toLowerCase());
				const normalize = (item, query, fn, queryNormalized) => fn(TextSearchMatcher.normalize(item), queryNormalized); // assume no words()
				const words = (a, b, fn) => {
					const as = _.filter(a.split(' '));
					const bs = _.filter(b.split(' '));
					return _.every(bs, (b2) => _.some(as, (a2) => fn(a2, b2)));
				};
				const sortText = (a, b, query) => a.toLowerCase().indexOf(query) - b.toLowerCase().indexOf(query);
				const sortNormalized = (a, b, query, queryNormalized) => (
					TextSearchMatcher.normalize(a).indexOf(queryNormalized) - TextSearchMatcher.normalize(b).indexOf(queryNormalized)
				);
				TextSearchMatcher.#sortDefinition = _.map([
					[isSame, null],
					[lower, isSame, null],
					[includes, sortText],
					[lower, includes, sortText],
					[normalize, isSame, null],
					[normalize, includes, sortNormalized],
					[words, isSame, null],
					[words, lower, isSame, null],
					[words, includes, null],
					[words, lower, includes, null],
					[any, null],
				], (defs, priority) => {
					const sortCompare = defs.pop();
					let isPriorityMatch = defs.pop();
					for (const defFn of defs.reverse())
					{
						const fn = isPriorityMatch;
						isPriorityMatch = (a, b, ...rest) => defFn(a, b, (a2, b2) => fn(a2, b2, ...rest), ...rest);
					}
					return {
						priority,
						isPriorityMatch,
						sortCompare,
					};
				});
			}

			const queryNormalized = this.#normalizedSplitQuery.join('');
			const result = TextSearchMatcher.#sortDefinition.map(() => []);
			for (const item of items)
			{
				const mappedItem = mapFn(item);
				for (const {priority, isPriorityMatch} of TextSearchMatcher.#sortDefinition)
				{
					if (isPriorityMatch(mappedItem, this.#query, queryNormalized))
					{
						result[priority].push(item);
						break;
					}
				}
			}

			for (const {priority, sortCompare} of TextSearchMatcher.#sortDefinition)
			{
				if (sortCompare !== null)
				{
					result[priority].sort((a, b) => sortCompare(mapFn(a), mapFn(b), this.#query, queryNormalized));
				}
			}

			return Array.prototype.concat.apply([], result);
		}

	}

	class HighlighterBuilder
	{

		#truncateTo;
		#html = $('<div>');

		#firstHighlight = false;
		#buffersBefore = [];
		#buffersBeforeLength = 0;
		#buffersAfter = [];
		#buffersAfterLength = 0;

		static #highlighterResCache;

		constructor(truncateTo = null)
		{
			this.#truncateTo = truncateTo;
		}

		#appendTextNotTruncated(text, isHighlight, prepend = false)
		{
			if (text !== '')
			{
				this.#html[prepend ? 'prepend' : 'append'](isHighlight ? $('<strong>').text(text) : document.createTextNode(text));
			}
		}

		appendTextTruncated(buffer, isHighlight, isRest = false)
		{
			if (this.#truncateTo !== null)
			{
				if (isHighlight) this.#firstHighlight = true;
				(this.#firstHighlight ? this.#buffersAfter : this.#buffersBefore).push([buffer, isHighlight]);
				(this.#firstHighlight ? this.#buffersAfterLength += buffer.length : this.#buffersBeforeLength += buffer.length);

				if (isRest)
				{
					const [buffersBefore, buffersAfter, buffersBeforeLength, buffersAfterLength] = this.#firstHighlight ?
						[this.#buffersBefore, this.#buffersAfter, this.#buffersBeforeLength, this.#buffersAfterLength] :
						[this.#buffersAfter, this.#buffersBefore, this.#buffersAfterLength, this.#buffersBeforeLength]
					;
					this.#firstHighlight = false;
					this.#buffersBefore = [];
					this.#buffersAfter = [];
					this.#buffersBeforeLength = 0;
					this.#buffersAfterLength = 0;

					const truncateBeforeTmp = Math.min(this.#truncateTo * 0.3, buffersBeforeLength);
					let truncateBefore = this.#truncateTo - Math.min(this.#truncateTo - truncateBeforeTmp, buffersAfterLength);
					let truncateAfter = this.#truncateTo - truncateBeforeTmp;
					const truncateBeforeTotal = truncateBefore;
					const truncateAfterTotal = truncateAfter;

					buffersBefore.reverse().forEach(([text, highlight]) => {
						text = this.truncateText(text, truncateBefore, false, '');
						truncateBefore -= text.length;
						this.#appendTextNotTruncated(text, highlight, true);
					});
					if (truncateBefore <= 0 && truncateBeforeTotal < buffersBeforeLength)
					{
						this.#appendTextNotTruncated('…', false, true);
					}
					buffersAfter.forEach(([text, highlight]) => {
						text = this.truncateText(text, truncateAfter, true, '');
						truncateAfter -= text.length;
						this.#appendTextNotTruncated(text, highlight);
					});
					if (truncateAfter <= 0 && truncateAfterTotal < buffersAfterLength)
					{
						this.#appendTextNotTruncated('…', false);
					}
				}
			}
			else
			{
				this.#appendTextNotTruncated(this.truncateText(buffer, this.#truncateTo), isHighlight);
			}
		}

		truncateText(text, truncateTo, after = true, add = '…')
		{
			if (truncateTo === null || text.length <= truncateTo) return text;
			if (truncateTo <= 0) return add;
			return after ? text.substr(0, truncateTo) + add : add + text.substr(text.length - truncateTo);
		}

		#splitTextToNormalizedPositions(highlightText)
		{
			let normalizedText = '';
			const splitsPositions = [];
			const splits = [];
			let position = 0;
			for (const char of highlightText.split(''))
			{
				const split = {char, highlight: false};
				const normalized = TextSearchMatcher.normalize(char);
				for (let i = 0; i < normalized.length; i++)
				{
					(splitsPositions[position] ?? (splitsPositions[position] = [])).push(split);
					position++;
				}
				normalizedText += normalized;
				splits.push(split);
			}
			return {normalizedText, splitsPositions, splits};
		}

		buildFromInternalMatcher(normalizedSplitQuery, highlightText)
		{
			if (normalizedSplitQuery.length > 0)
			{
				const {normalizedText, splitsPositions, splits} = this.#splitTextToNormalizedPositions(highlightText);

				if (!HighlighterBuilder.#highlighterResCache || HighlighterBuilder.#highlighterResCache[0] !== normalizedSplitQuery)
				{
					HighlighterBuilder.#highlighterResCache = [
						normalizedSplitQuery,
						_.map(normalizedSplitQuery, (word) => new RegExp(`(${Sim.escapeRegex(word)})`, 'gu')),
					];
				}

				for (const re of HighlighterBuilder.#highlighterResCache[1])
				{
					normalizedText.replace(re, ($1, match, offset) => {
						const stop = offset + match.length;
						for (let pos = offset; pos < stop; pos++)
						{
							for (const split of splitsPositions[pos])
							{
								split.highlight = true;
							}
						}
					});
				}

				let isHighlight = false;
				let buffer = '';
				for (const split of splits)
				{
					if (isHighlight !== split.highlight)
					{
						this.appendTextTruncated(buffer, isHighlight);
						buffer = '';
						isHighlight = split.highlight;
					}
					buffer += split.char;
				}
				this.appendTextTruncated(buffer, isHighlight, true);
			}
			else
			{
				this.#appendTextNotTruncated(this.truncateText(highlightText, this.#truncateTo));
			}
		}

		finalizeToHtml()
		{
			if (this.#buffersBefore.length || this.#buffersAfter.length)
			{
				this.appendTextTruncated('', false, true);
			}
			return $(this.#html.contents());
		}

	}

	return TextSearchMatcher;
});

Sim.require.amd.registerRaw("/app/Intranet/PropertyManagement/forms/PropertyFinancesForms/SetDefaultsIfConditionsMatch.js", ["/public/js/Nette.js"],  (Nette) => {

	class SetDefaultsIfConditionsMatch
	{

		constructor(options)
		{
			const type = $(options.element);
			if (type.length !== 1) throw new Error;
			type.on('change', () => { this.change(type.val(), options.setDefaultsConditions); });
		}

		change(type, conditions)
		{
			if (conditions[type] !== undefined)
			{
				for (const [id, defaultValue] of Object.entries(conditions[type].equals))
				{
					if (!this.compare(defaultValue, document.getElementById(id)))
					{
						return;
					}
				}
				for (const [id, value] of Object.entries(conditions[type].defaults))
				{
					const el = $(document.getElementById(id));
					el.val(String(value)).trigger('change');
				}
			}
		}

		compare(value, elem)
		{
			value = String((value === null || value === undefined) ? '' : value);
			const a = Nette.getValue(elem);
			const b = Nette.getValue($(elem).clone().val(value));
			return Nette.validators.equal(elem, b, a);
		}

	}

	return SetDefaultsIfConditionsMatch;
});

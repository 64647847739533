(function (window) {

	try { window.frameElement && window.frameElement.dispatchEvent(new Event('Rentflow:OldAppFrame.earlyTrigger')); } catch (e) {}

	var document = window.document;
	var $ = window.jQuery;
	var Sim = window.Sim || (window.Sim = {});
	const isRTLTestMode = (Sim.config.isRTL && document.documentElement.getAttribute('rtl-test') !== null);
	Sim.config.isRTLTestMode = isRTLTestMode;

	Sim.enableAjaxSubmit = (forms, disableSubmitOptions = {}) => {
		const createOnSubmit = (self) => {
			return (form, submittedBy, restoreSubmit) => {
				$.ajax({
					url: form.attr('action'),
					data: (function () {
						var data = form.serializeArray();
						if (submittedBy && submittedBy.name && !submittedBy.disabled)
						{
							data.push({name: submittedBy.name, value: submittedBy.value});
						}
						return $.param(data);
					})(),
					type: form.attr('method') || 'GET',
					error: (jqXHR, textStatus) => {
						_.invoke(self.fail, 'call', null, form, textStatus, jqXHR, submittedBy);
						if (textStatus === 'parsererror' && jqXHR.status === 200)
						{
							const ct = jqXHR.getResponseHeader('Content-Type');
							const rt = jqXHR.responseText;
							if (ct && rt && ct.startsWith('text/html') && rt.startsWith('<!DOCTYPE html>') && rt.includes('<body'))
							{
								const body = $('body');
								const html = rt.replace(/^[^]*<body[^]*?>|<\/body>/u, '');
								const fragment = document.createRange().createContextualFragment(html);
								body.empty();
								body[0].appendChild(fragment); // to trigger scripts after body was filled, require appendChild and createContextualFragment, important for e.g. error.cleanOutput.phtml
								Sim.init(body);
							}
						}
					},
					success: function (payload, textStatus, jqXHR) {
						_.invoke(self.success, 'call', null, form, payload, textStatus, jqXHR, submittedBy);
					},
					complete: function () {
						restoreSubmit();
					},
				});

				return false;
			};
		};
		return Sim._enableAjaxSubmit.initialize(createOnSubmit, forms, disableSubmitOptions);
	};

	Sim.init.addDirectEvent('a[data-confirm]', 'click', function (el, event) {
		event.stopPropagation();
		return confirm(this.getAttribute('data-confirm'));
	});
	Sim.init.addDirectEvent('button[data-confirm], input[type="submit"][data-confirm]', 'click', function (el, event) {
		return confirm(this.getAttribute('data-confirm'));
	});
	Sim.init.addDirectEvent('a.ajax', 'click', function (el, event) {
		event.preventDefault();
		$.get(this.href);
	});

	Sim.init.addDirectHandler('.mainSearchForm', 'click', (el, event) => {
		el.on('click', '.areaChoice a[data-area]', function (e) {
			var el = $(this);
			el.closest('.mainSearchForm').find('.areaSelect').val(el.data('area'));
			el.closest('.areaChoice').find('a[data-area].active').removeClass('active');
			el.addClass('active');
			if (!e.isTrigger) el.closest('.mainSearchForm').find('.query').trigger('focus');
		});
		if (event) $(event.target).trigger(event);
	});

	/**
	 * @see Nette\Forms\Container::addDatePicker
	 * @see Sim\DatePicker
	 */
	Sim.init.addDirectHandler('input[data-datepicker-input]:not(.hasDatepicker)', 'focusin', function (el) {
		var value = el.val();
		var type = el.attr('type');
		var placeholder = $('<span>');
		el.replaceWith(placeholder).attr('type', 'text');
		placeholder.replaceWith(el);
		var internalFormat = $.datepicker.W3C;
		var parse = function (date, isFull) {
			return date === null ? null : $.datepicker.parseDate(internalFormat, (isFull === false && type === 'month') ? date + '-01' : date);
		};

		var option = {
			minDate: parse(el.attr('min') || null),
			maxDate: parse(el.attr('max') || null),
			defaultDate: parse(el.attr('currentdate') || null),
			dateFormat: el.attr('data-user-format').replace(/\u00A0/g, ' '),
			firstDay: Sim.config.firstDayOfWeek,
			shortYearCutoff: 69, // php 1970-2069
			changeYear: true,
			changeMonth: true,
			showButtonPanel: type === 'month' ? true : false,
			isRTL: Sim.config.isRTL,
		};
		if (type === 'month')
		{
			var set = function (inst) {
				var date = new Date(inst.drawYear, inst.drawMonth, 1);
				if (el.val() !== $.datepicker.formatDate(el.datepicker('option', 'dateFormat'), date))
				{
					el.datepicker('setDate', date);
				}
			};
			option.onChangeMonthYear  = function (year, month, inst) { set(inst); };
			option.afterUpdateShow = function (inst) {
				inst.dpDiv.find('.ui-datepicker-calendar, .ui-datepicker-current').hide();
				inst.dpDiv.find('.ui-datepicker-close').one('click', function () { set(inst); });
			};
			option.beforeShow = function (foo, inst) {
				var val = $(this).val();
				var date;
				if (!date) try { date = $.datepicker.parseDate(option.dateFormat + '-dd', val + '-01'); } catch (e) {}
				if (date)
				{
					el.datepicker('option', 'defaultDate', date);
					el.datepicker('setDate', date);
				}
			};
		}

		el.attr('autocomplete', 'off');
		el.datepicker(option);
		el.val($.datepicker.formatDate(option.dateFormat, parse(value || null, false)));
	});
	Sim.redefineFunction($.datepicker, '_updateDatepicker', function (old, inst) {
		old();
		var fn = $.datepicker._get(inst, 'afterUpdateShow');
		!fn || fn.apply(inst.input, [inst]);
	});
	Sim.redefineFunction($.datepicker, 'parseDate', function (old) {
		let date = null;
		try {
			date = old();
		} catch (err) {
			try {
				date = old({1: $.datepicker.W3C}); // fallback internal format
			}
			catch (foo)
			{
				throw (typeof err === 'string' ? new Error(err) : err);
			}
		}
		return date;
	});
	Sim.redefineFunction($.datepicker, 'formatDate', function (old, format, date) {
		if (date && !(date instanceof Date))
		{
			let type = typeof date;
			type = (type === 'object' && date.constructor && date.constructor.name) || (type === 'string' ? `"${date}"` : type);
			throw new Error(`$.datepicker.formatDate: invalid input; expected Date or falsy value; given '${type}'`);
		}
		if (date instanceof Date && Number.isNaN(date.getTime()))
		{
			throw new Error('$.datepicker.formatDate: Date is "invalid date"');
		}
		old();
	});
	Sim.redefineFunction($.datepicker, '_doKeyPress', function (old, event) {
		const chrCode = event.charCode == null ? event.keyCode : event.charCode;
		return old() || chrCode === 45 || chrCode === 32; // normal dash, normal space
	});
	Sim.redefineFunction($.datepicker, '_findPos', (old, input) => {
		const pos = old();
		pos[1] += $(input).outerHeight(); // jquery ui workaround _showDatepicker vs _checkOffset subpixel comparison when zoomed
		return {
			0: pos[0],
			get 1() { return pos[1]; },
			set 1(v) {}, // ignore change as workaround for bug where input.offsetHeight is used in _showDatepicker but should be $(input).outerHeight()
		};
	});

	/** @return {(Date|null)} */
	$.fn.getDatepickerDate = function ({allowNonDatepickerFallback = false} = {}) {
		const val = this.val();
		if (this.length !== 1 || (!this.is('[data-datepicker-input]') && !this.data('datepicker')))
		{
			if (allowNonDatepickerFallback && this.length === 1)
			{
				return val === '' ? null : $.datepicker.parseDate('yy-mm-dd', val);
			}
			throw new Error('this is not datepicker element');
		}
		let fromUserInputDate = null;
		try
		{
			fromUserInputDate = $.datepicker.parseDate(this.attr('data-user-format').replace(/[^a-z]{1,}/gu, '-'), val.replace(/[^\d]{1,}/gu, '-'));
		}
		catch (e)
		{
			return null;
		}
		return fromUserInputDate;
	};

	Sim.addNetteValidator('Sim\\DatePicker::validateCorrect', (elem, arg, val) => {
		return Sim.require.get('Nette').validators['pattern'](elem, elem.getAttribute('pattern'), elem.value);
	});

	Sim.addNetteValidator('Sim\\FormRepeaterContainer::validateCount', (elem, arg) => {
		const [scriptParameters, operation, value] = arg;
		const repeaterEl = $(elem).closest('form').find(`#${$.escapeSelector(scriptParameters['rootId'])}`);
		if (!repeaterEl.length) throw new Error(JSON.stringify(arg));
		const repeater = repeaterEl.data('repeater');
		if (repeater)
		{
			const count = repeater.getRows().length;
			return {
				'==': count == value, // eslint-disable-line eqeqeq
				'!=': count != value, // eslint-disable-line eqeqeq
				'<': count < value,
				'<=': count <= value,
				'>': count > value,
				'>=': count >= value,
			}[operation];
		}
	});

	Sim.init.addGlobalEvent('.popover-toggle', 'mouseenter', (el) => {
		Sim.require.callback('app/components/Popover/Popover', (Popover) => Popover.init(el));
	});

	Sim.init.addGlobalEvent('a[data-popup]', 'click', (el) => {
		Sim.require.callback('app/components/Popups/Popups', (Popups) => Popups.initOnMouseClick(el));
		return false;
	});

	Sim.init.addDirectEvent('[data-expand-target][data-expand-root]', 'click', function (el) {
		el.closest(el.attr('data-expand-root')).attr('data-expanded', function (i, old) {
			var t = el.attr('data-expand-target');
			if (el.hasClass('btn')) el.toggleClass('active', old !== t);
			return old === t ? null : t;
		});
	});

	var isChrome = (window.chrome && window.navigator.vendor === 'Google Inc.' && !window.opr && !window.navigator.userAgent.match(/CriOS|Edge/));
	if (isChrome)
	{
		var chromeOff = 'x-chrome-off';
		Sim.init.addDirectHandler([
			'input[data-chrome-autofill="off"]',
			'select[data-chrome-autofill="off"]',
			'textarea[data-chrome-autofill="off"]',
		].join(','), 'focusin mouseenter', function (el) {
			var autocomplete = el.attr('autocomplete');
			el.attr('autocomplete', (!autocomplete || autocomplete === 'off') ? chromeOff : autocomplete);
		});
		if ('autocomplete' in $.attrHooks) throw new Error;
		$.attrHooks['autocomplete'] = {
			get: function (elem, name) {
				var value = jQuery.find.attr(elem, name);
				return value === chromeOff ? 'off' : value;
			},
			set: function (elem, value, name) {
				if (value === 'off' && jQuery.find.attr(elem, 'data-chrome-autofill') === 'off')
				{
					elem.setAttribute(name, chromeOff);
					return value;
				}
			},
		};
	}

	if (isRTLTestMode)
	{
		Sim.init.addDirectHandler('#page', null, (page) => {
			if (page.is('html[rtl-test] > body > #page'))
			{
				const els = []
					.concat(...page)
					.concat(...page.find('> #tableSelector, > #tableSelector > .tsContent'))
					.reverse()
				;
				const $els = els.map((el) => $(el));
				const fn = () => {
					const docEl = document.documentElement;
					const {scrollLeft, scrollWidth, clientWidth} = docEl;
					let changed = false;
					$els.forEach(($el) => {
						$el.css('margin-left', '');
						$el.css('padding-left', '');
					});
					$els.forEach(($el) => {
						const max = Math.max(0, $el[0].scrollWidth - $el[0].offsetWidth);
						$el.css('margin-left', `-=${max}px`);
						$el.css('padding-left', `+=${max}px`);
						changed = (changed || $el.data('RTLTestMode-widthFix') !== max);
						$el.data('RTLTestMode-widthFix', max);
					});
					const beforeOver = scrollWidth - clientWidth;
					const afterOver = docEl.scrollWidth - docEl.clientWidth;
					docEl.scrollLeft = beforeOver === 0 ? -afterOver : afterOver * scrollLeft / beforeOver;
					changed && Sim.onResize.trigger();
				};
				fn();
				Sim.onResize(els, fn, 200);
			}
		});
	}

	// Indicate AJAX activity
	(function () {
		var loadings = [];
		$(document).ajaxStart(function() {
			loadings.push(Sim.loading());
		}).ajaxStop(function() {
			loadings.shift().call();
		});
	})();

	if (Sim.config.debuggerEnabled)
	{
		/** Don't stop loading the page if you click an editor link. */
		$(document).on('click', 'a[href^="editor://open"]', function (e) {
			if (!e.isDefaultPrevented() && document.readyState !== 'complete')
			{
				e.preventDefault();
				var iframe = $('<iframe>').attr('src', this.href).hide().appendTo('body');
				setTimeout(function () { iframe.remove(); });
			}
		});
	}

	Sim.init.ready();

})(window);

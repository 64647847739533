Sim.require.amd.registerRaw("/app/components/Popups/PopupAjax.js", [], () => {

	class PopupAjax
	{

		KEY = 'Popup::CONTENT_SNIPPET';

		constructor()
		{
			this.AcceptableUserError = Sim.createCustomError('AcceptableUserError');
			this.cache = new Map;
		}

		loadContentSnippets(from)
		{
			if (typeof from === 'object' && 'onSuccess' in from && 'onFail' in from) // Sim.enableAjaxSubmit
			{
				return this._hookAjaxSubmit(from);
			}
			return this._loadFromUrl(from);
		}

		async _loadFromUrl(href)
		{
			href = String(href);
			if (this.cache.has(href))
			{
				return this.cache.get(href);
			}
			const promise = this._makeRequest(href);
			this.cache.set(href, promise);
			try
			{
				return await promise;
			}
			finally
			{
				this.cache.delete(href);
			}
		}

		_makeRequest(href)
		{
			let postArgs = {};
			if (href.length > 512)
			{
				const url = new URL(href);
				const popupPOST = url.searchParams.get('popup');
				url.searchParams.set('popup', 'POST');
				postArgs = {
					url: String(url),
					method: 'POST',
					data: {popupPOST},
				};
			}
			return new Promise((resolve, reject) => {
				$.ajax({
					url: href,
					dataType: 'json',
					success: (...args) => this._onSuccess(href, resolve, reject, ...args),
					error: (...args) => this._onError(href, reject, ...args),
					...postArgs,
				});
			});
		}

		_hookAjaxSubmit(ajaxSubmitObject)
		{
			return new Promise((resolve, reject) => {
				ajaxSubmitObject.onSuccess((form, ...args) => {
					this._onSuccess(form.attr('src'), resolve, reject, ...args);
				});
				ajaxSubmitObject.onFail((form, textStatus, jqXHR) => {
					this._onError(form.attr('src'), reject, jqXHR);
				});
			});
		}

		_onSuccess(href, resolve, reject, payload, textStatus, jqXHR)
		{
			$.nette.success(payload, textStatus, jqXHR);
			const snippets = payload[this.KEY];
			if (typeof snippets !== 'object')
			{
				reject(new Error(href));
			}
			else
			{
				resolve(snippets);
			}
		}

		_onError(href, reject, jqXHR)
		{
			const errMessage = jqXHR.getResponseHeader('X-SIM-Popup-Error-Message');
			if (errMessage !== null && [404, 403].includes(jqXHR.status))
			{
				jqXHR.ignoreSimErrorReporting = true;
				const msg = JSON.parse(errMessage);
				reject(new this.AcceptableUserError(msg));
			}
			else
			{
				reject(new this.AcceptableUserError('Content could not be loaded.'));
			}
		}

	}

	return PopupAjax;
});

Sim.require.amd.registerRaw("/app/Intranet/components/TableSelector/TableSelectorLocalTable.js", [], () => {

	class TableSelectorLocalTable
	{

		constructor(elements, paginator, localRenderer, fullTable)
		{
			this.elements = elements;
			this.paginator = paginator;
			this.localRenderer = localRenderer;
			this.fullTable = fullTable;
			this.positions = {};

			this.localRenderer.localTable = {
				getCloneRowAtPosition: (...args) => this.getCloneRowAtPosition(...args),
				getEmptyRowAtPosition: (...args) => this.getEmptyRowAtPosition(...args),
			};
		}

		async moveToPosition(start, itemsPerPage = this.paginator.local.itemsPerPage, animate = true)
		{
			itemsPerPage = Math.max(Math.min(parseInt(itemsPerPage, 10), this.paginator.remote.itemCount), 0);
			start = Math.max(Math.min(parseInt(start, 10), this.paginator.remote.itemCount - itemsPerPage), 0);
			const originalStart = this.paginator.local.start;
			const changed = this.paginator.isDifferentPosition('local', start, itemsPerPage);
			if (changed)
			{
				this.paginator.local.start = start;
				this.paginator.local.itemsPerPage = itemsPerPage;
			}
			this.localRenderer.renderScrolling(animate);
			if (changed)
			{
				for (const position of this.paginator.iteratePositions('local'))
				{
					if (this.positions[position] === undefined)
					{
						try
						{
							await this.fullTable.moveToPosition(position);
						}
						catch (err)
						{
							if (err instanceof this.fullTable.ajaxDataLoader.AbortError)
							{
								break;
							}
							throw err;
						}
						this.positions[position] = this.positions[position] || false;
						this._convertFullToLocal();
					}
				}
				if (animate)
				{
					this.fullTable.preloadPage(start > originalStart ? 1 : (start < originalStart ? -1 : 0));
				}
			}
			this.localRenderer.renderContent();
		}

		_convertFullToLocal()
		{
			for (const position of this.paginator.iteratePositions('remote'))
			{
				this.positions[position] = this.positions[position] || true;
			}
			for (const rawEl of this.elements.realTable.find('> tbody > tr'))
			{
				const el = $(rawEl);
				const position = el.attr('data-position');
				const tds = el.find('> td').filter((index) => this.localRenderer.localTDIndexes.indexOf(index) !== -1).clone();
				const tr = $('<tr>');
				tr.attr('data-position', position);
				tr.attr('class', el.attr('class'));
				tr.append(tds);
				tr.find('.tsHideInContentView').remove();
				this.positions[position] = tr;
			}
		}

		getEmptyRowAtPosition(position)
		{
			if (this.emptyRowCache === undefined)
			{
				const emptyRow = $('<tr>')
					.attr('data-position', '')
					.addClass('tsRow empty')
					.append($('<td>').attr('colspan', this.localRenderer.localTDIndexes.length))
				;
				this.emptyRowCache = [];
				const word = (min, max) => '▄'.repeat(_.random(min, max));
				for (let i = 0; i <= 5; i++)
				{
					const text = [word(8, 14)].concat(_.range(0, _.random(0, 2)).map(() => word(4, 8))).join(' ');
					const row = emptyRow.clone();
					row.find('td').text(text);
					this.emptyRowCache.push(row);
				}
			}
			const r = this.emptyRowCache[_.random(0, 5)].clone().attr('data-position', position);
			return r;
		}

		getCloneRowAtPosition(position, needFilled = false)
		{
			if (this.positions[position] === true) return [null, true];
			if (!this.positions[position])
			{
				if (needFilled) throw new Error(position);
				return [null, false];
			}
			const el = this.positions[position].clone();
			Sim.init(el);
			return [el, true];
		}

	}

	return TableSelectorLocalTable;
});

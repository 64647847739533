Sim.require.amd.registerRaw("/app/Intranet/components/Contracts/ContractLengthCount.js", ["/public/js/ContinuousAjaxUpdated.js","/public/js/Nette.js","/app/components/Popover/Popover.js"],  (ContinuousAjaxUpdated, Nette, Popover) => {

	class ContractLengthCount
	{

		#form;
		#ajax;
		#popoverEl;
		#textEl;
		#spinner = ' <i class="loading icon-spinner icon-spin"></i>';

		constructor(options)
		{
			this.#form = $(options.form);
			const $el = this.#form.find('.js-contractLengthCount');
			if ($el.length !== 1) throw new Error;

			this.#popoverEl = $el.closest('.popover-toggle');
			this.#popoverEl = this.#popoverEl.length === 0 ? null : this.#popoverEl;

			let spinner = $();
			if (this.#popoverEl)
			{
				this.#textEl = $el;
			}
			else
			{
				$el.html(`<span class="text"></span>${this.#spinner}`);
				this.#textEl = $el.find('> .text');
				spinner = $el.find('> .loading');
			}

			this.#ajax = new ContinuousAjaxUpdated({
				...options.ajax,
				spinner,
				before: (...a) => this.#before(...a),
				update: (...a) => this.#update(...a),
				after: (...a) => this.#after(...a),
				args: () => this.#createArguments(),
				disabled: (args) => ((!args.dateStart || !args.dateEnd) ? {text: ''} : null),
			});

			this.#form.on('Rentflow:ContractLengthCount.update', () => this.#ajax.request());
			this.#form.on('change input', [
				'input[name="dateStart"]',
				'input[name="dateStop"]',
				'input[name="dateStopNew"]',
				'.stageRow:not(.stageRow--header, .stageRow--footer, .stageRow--toDelete):first input[name$="[dateStart]"]',
				'.stageRow:not(.stageRow--header, .stageRow--footer, .stageRow--toDelete):last input[name$="[dateEnd]"]',
			].join(', '), () => this.#ajax.request());
			if (!this.#popoverEl)
			{
				// case with popover has data prefilled in latte
				this.#ajax.request();
			}
		}

		#getDateInputs()
		{
			let dateStart;
			let dateEnd;
			if (this.#popoverEl)
			{
				const stageRows = this.#form.find('.stageRow:not(.stageRow--header, .stageRow--footer, .stageRow--toDelete)');
				dateStart = stageRows.first().find('input[name$="[dateStart]"]');
				dateEnd = stageRows.last().find('input[name$="[dateEnd]"]');
			}
			else
			{
				dateEnd = this.#form.find('input[name="dateStopNew"], input[name="dateStop"]').not(':disabled');
				const dateStartName = dateEnd.is('input[name="dateStopNew"]') ? 'dateStop' : 'dateStart';
				dateStart = this.#form.find(`input[name="${dateStartName}"]`);
			}
			return {dateStart, dateEnd};
		}

		#createArguments()
		{
			const dateInputs = this.#getDateInputs();
			return {
				dateStart: Sim.parseDatePickerValue(dateInputs.dateStart),
				dateEnd: Sim.parseDatePickerValue(dateInputs.dateEnd),
				isExtend: dateInputs.dateStart.is('input[name="dateStop"]'),
			};
		}

		#before()
		{
			if (this.#popoverEl)
			{
				const dateInputs = this.#getDateInputs();
				const start = dateInputs.dateStart.getDatepickerDate();
				const end = dateInputs.dateEnd.getDatepickerDate();
				this.#textEl.text(`${start ? Sim.formatDate(start) : '—'} - ${end ? Sim.formatDate(end) : '—'}`);
				Popover.changeContent(this.#popoverEl, {contentHtml: this.#spinner});
			}
			else
			{
				this.#textEl.text('');
			}
		}

		#update({text})
		{
			if (this.#popoverEl)
			{
				Popover.changeContent(this.#popoverEl, {contentHtml: Sim.escapeHtml(text) + this.#spinner});
			}
			else
			{
				this.#textEl.text(text);
			}
		}

		#after({text})
		{
			if (this.#popoverEl)
			{
				Popover.changeContent(this.#popoverEl, {contentHtml: Sim.escapeHtml(text) || '&mdash;'});
			}
		}

	}

	return ContractLengthCount;
});

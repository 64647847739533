Sim.require.amd.registerRaw("/app/Intranet/components/DownloadTableData/DownloadTableDataPopup.js", ["/public/js/Helpers/ElementFinder.js"],  (ElementFinder) => {

	class DownloadTableDataPopup
	{

		constructor(formEl)
		{
			const $form = new ElementFinder(formEl, 'form');
			$form.$root.attr('data-popup-disable-ajax-submit', true); // so custom reEnableTimeout can be used
			Sim.enableAjaxSubmit($form.$root, {onlyClickedButton: true})
				.enableEarlyMustIf((form, submittedBy) => $form.findOne('input[name="preview"]').is(submittedBy))
				.onSuccess((form, payload, textStatus, jqXHR, submittedBy) => {
					$.nette.success(payload, textStatus, jqXHR);
					$form.findOne('.m-popupForm__topOfBody')[0].scrollIntoView({block: 'end'});
				})
			;
			Sim.enableAjaxSubmit($form.$root, {reEnableTimeout: 3, onlyClickedButton: true})
				.enableEarlyMustIf((form, submittedBy) => $form.findOne('input[name="save"]').is(submittedBy))
			;
		}

	}

	return DownloadTableDataPopup;
});

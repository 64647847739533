Sim.require.amd.enter("/app/components/HtmlEditor/Simditor.js", () => {Sim.require.amd.registerRaw("/public/libs/simditor/simple-uploader/simple-uploader.js", [],  () => null);
Sim.require.amd.registerRaw("/public/libs/simditor/dompurify/dompurify.js", [],  () => null);
Sim.require.amd.registerRaw("/app/components/HtmlEditor/Simditor.js", ["/public/libs/simditor/simditor/simditor.js","/public/libs/simditor/simple-hotkeys/hotkeys.js"],  (Simditor, simpleHotkeys) => {

	Simditor.prototype._t = function (...a) {
		return this.opts.translate(...a);
	};

	for (const [klass, method, patches] of [
		[Simditor, '_render', 'shorthand-deprecated-v3'],
		[Simditor, 'blur', 'shorthand-deprecated-v3'],
		[Simditor, 'focus', 'shorthand-deprecated-v3'],
		[Simditor, 'sync', 'trim'],
		[Simditor.Selection, 'range', 'shorthand-deprecated-v3'],
		[Simditor.Selection, 'nodes', 'unique'],
		[Simditor.Clipboard, '_getPasteContent', 'shorthand-deprecated-v3'],
		[Simditor.UndoManager, '_getNodeOffset', ['isNumeric', 'type']],
		[Simditor.Toolbar, '_init', 'isArray'],
		[Simditor.Button, '_init', 'trim'],
	])
	{
		Sim.redefineFunction(klass.prototype, method, function (old) {
			jQuery.migrateMakePatchesNonStrictCall(patches, old);
		});
	}

	Sim.redefineFunction(Object.getPrototypeOf(simpleHotkeys({el: $()})), '_getHander', (old) => {
		const fn = old();
		if (typeof fn !== 'function') return fn;
		return (...a) => jQuery.migrateMakePatchesNonStrictCall('shorthand-deprecated-v3', () => fn(...a));
	});

	Sim.redefineFunction(Simditor.prototype, 'setValue', function (old) {
		old();
		this.sync();
	});

	Sim.redefineFunction.ifPresent(window.console, 'info', (old, message) => {
		typeof message === 'string' && message.startsWith('simditor: ') && Sim.triggerWarning(message, '*.info');
		old();
	});

	// https://github.com/mycolorway/simditor/pull/429
	for (const method of ['startNodes', 'endNodes', 'containerNode', 'nodes', 'blockNodes', 'rootNodes'])
	{
		Sim.redefineFunction(Simditor.Selection.prototype, method, (old) => (old() || $()));
	}

	Sim.redefineFunction(Simditor.Formatter.prototype, '_init', function (old) {
		old();
		this._allowedTags = this.opts.allowedTags || [];
		this._allowedAttributes = this.opts.allowedAttributes || {};
		this._allowedStyles = this.opts.allowedStyles || {};
	});

	Sim.redefineFunction(Simditor.Formatter.prototype, 'format', function (old, $el) {
		$el = $el || this.editor.body;
		$el.find('p').filter((e, el) => $(el).html().trim() === '').append(this.editor.util.phBr);
		old();
		// https://github.com/mycolorway/simditor/pull/495
		$el.find('p').filter((e, el) => $(el).html().trim() === '').remove();
	});

	Sim.redefineFunction(Simditor.Formatter.prototype, 'cleanNode', function (old, node, recursive) {
		const $node = $(node);
		if (node && node.nodeType === Node.TEXT_NODE)
		{
			let text = $node.text();
			// https://github.com/mycolorway/simditor/pull/554
			text = text.replace(/^(\r\n|\n)+|(\r\n|\n)+$/gu, '').replace(/\r\n|\n|\r/gu, ' ');
			text ? $node.replaceWith(document.createTextNode(text)) : $node.remove();
			return;
		}
		if (recursive && $node.is('pre'))
		{
			const $new = $('<td>').append($node.contents());
			$node.replaceWith($new);
			old({1: $new[0]});
		}
		else
		{
			old();
		}
	});

	Sim.redefineFunction(Simditor.Formatter.prototype, '_cleanNodeStyles', function (old, $node) {
		old();
		if ($node.attr('style') === '')
		{
			$node.removeAttr('style');
		}
	});

	return Simditor;
});
});
Sim.require.amd.registerRaw("/app/Intranet/Config/components/SendCustomizedMessage/SendCustomizedMessage.js", ["/public/js/Helpers/ElementFinder.js"],  (ElementFinder) => {

	class SendCustomizedMessage
	{

		constructor(formEl)
		{
			const $form = new ElementFinder(formEl, 'form');
			this.#initAjaxSubmit($form);
			this.#initDiscardChanged($form);
		}

		#initAjaxSubmit($form)
		{
			const saveButtons = $form.findSome('input[type="submit"][name="save"]');
			const backButton = $form.findOne('button[type="submit"][name="backFromReview"]', false);
			const review = $form.findOne('input[type="checkbox"][name="review"]');
			Sim.enableAjaxSubmit($form.$root)
				.addIsEnabled((form) => (
					(saveButtons.is(form[0]['nette-submittedBy']) && !review.prop('checked')) ||
					(backButton.is(form[0]['nette-submittedBy']) && review.prop('checked'))
				))
				.onSuccess((form, payload, textStatus, jqXHR, submittedBy) => {
					$.nette.success(payload, textStatus, jqXHR);
				})
			;
		}

		#initDiscardChanged($form)
		{
			$form.on('button.js-discardChanges', 'click', (e) => {
				this.#discard($form, 'input[type="text"]', 'subject', 'subjectRealGenerated', false);
				this.#discard($form, 'input[type="hidden"]', 'subjectSeenGenerated', 'subjectRealGenerated', false);
				this.#discard($form, 'input[type="hidden"]', 'subjectPrevGenerated', 'subjectRealGenerated', false);
				this.#discard($form, 'textarea', 'content', 'contentRealGenerated');
				this.#discard($form, 'input[type="hidden"]', 'contentSeenGenerated', 'contentRealGenerated');
				this.#discard($form, 'input[type="hidden"]', 'contentPrevGenerated', 'contentRealGenerated');
				this.#discard($form, 'input[type="hidden"]', 'dataSeenGenerated', 'dataRealGenerated');

				$(e.target).closest('.alert-warning').remove();
				return false;
			});
		}

		#discard($form, inputType, name, valueFromName, must = true)
		{
			const $input = $form.findOne(`${inputType}[name="filling[${name}]"]`, !!must);
			if ($input.length)
			{
				const $from = $form.findOne(`input[type="hidden"][name="filling[${valueFromName}]"]`);
				if ($input.is('input[type="hidden"]') || !$input.is(':visible')) // also simditor
				{
					$input.val($from.val()).trigger('change');
				}
				else
				{
					this.#changeValueWithUndo($input, $from.val());
				}
			}
		}

		#changeValueWithUndo($input, newValue)
		{
			$input.trigger('select');
			if (!document.execCommand('insertText', false, newValue))
			{
				$input.val(newValue);
				$input.trigger('input');
			}
			$input.trigger('blur');
		}

	}

	return SendCustomizedMessage;
});

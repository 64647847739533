Sim.require.amd.registerRaw("/public/js/SimpleEvent.js", [], function () {

	function SimpleEvent(parent, property) {
		this.parent = parent;
		this.events = [];
		if (parent[property] !== undefined) throw new Error;
		parent[property] = this;
		this.trigger = this.trigger.bind(this);
	}
	SimpleEvent.prototype = {
		trigger: function () {
			return this._trigger.apply(this, arguments);
		},
		setTriggerDebounce: function (debounce) {
			this._trigger = _.debounce(SimpleEvent.prototype._trigger, debounce);
		},
		setTriggerCreateArguments: function (callback) {
			this.triggerCreateArguments = callback;
		},
		on: function (callback, context) {
			var fn = this._fn(callback, context);
			this._on(fn);
			return this.parent;
		},
		onNow: function (callback, context) {
			var fn = this._fn(callback, context);
			this._now(fn);
			this._on(fn);
			return this.parent;
		},
		onceNow: function (callback, context) {
			var fn = this._fn(callback, context);
			if (!this._now(fn)) this._on(_.once(fn));
			return this.parent;
		},
		_trigger: function () {
			var args = this.lastValue = (this.triggerCreateArguments ? this.triggerCreateArguments.apply(this, arguments) : _.toArray(arguments));
			_.each(this.events, function (fn) { fn.apply(null, args); });
			return this.parent;
		},
		_fn: function (callback, context) {
			return _.bind(callback, context || this.parent);
		},
		_now: function (fn) {
			if (this.lastValue !== undefined)
			{
				fn.apply(null, this.lastValue);
				return true;
			}
		},
		_on: function (fn) {
			this.events.push(fn);
		},
	};

	return SimpleEvent;
});

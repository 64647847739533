Sim.require.amd.registerRaw("/app/Intranet/components/TableSelector/TableSelectorScroller.js", [], () => {

	class TableSelectorScroller
	{

		constructor(elements, paginator, localTable)
		{
			this.MODE = {
				NONE: 0,
				HOME: 1,
				END: 2,
				PAGE: 3,
				LINE: 4,
				PIXEL: 6,
				PIXEL_ABS: 7,
			};

			this.elements = elements;
			this.paginator = paginator;
			this.localTable = localTable;

			this._handleButtons();
			this._handleKeyShortcuts();
			this._handleWheel();
			this._handleTouch();

			this.elements.whenStickySizeChange(() => this.changeLocal());
		}

		_handleButtons()
		{
			for (const [buttonClass, pageJump, fullJump] of [
				['loadPrev', [this.MODE.PAGE, -1], [this.MODE.HOME]],
				['loadNext', [this.MODE.PAGE, 1], [this.MODE.END]],
			])
			{
				this.elements.tsTableSticky.find(`> .${buttonClass}`).on('click', (event) => {
					this.changeLocalPage(...(event.ctrlKey || event.shiftKey) ? fullJump : pageJump);
					return false;
				});
			}
		}

		_handleWheel()
		{
			this.elements.tsTableScroll.on('wheel', (event) => {
				const {deltaY, deltaX, deltaZ, deltaMode} = event.originalEvent;
				const deltaYAbs = Math.abs(deltaY);
				if (
					(!event.ctrlKey && !event.shiftKey && !event.altKey && !event.metaKey) &&
					deltaYAbs > 0 &&
					deltaYAbs > Math.abs(deltaX) &&
					deltaYAbs > Math.abs(deltaZ)
				)
				{
					let jump;
					if (deltaMode === WheelEvent.DOM_DELTA_PIXEL)
					{
						const devicePixelRatio = window.devicePixelRatio || 1;
						const amount = deltaY / devicePixelRatio;
						if (Math.abs(amount) < this.localTable.localRenderer.rowConstantSize)
						{
							jump = [this.MODE.LINE, deltaY > 0 ? 1 : -1];
						}
						else
						{
							jump = [this.MODE.PIXEL, amount];
						}
					}
					else if (deltaMode === WheelEvent.DOM_DELTA_LINE)
					{
						jump = [this.MODE.LINE, deltaY];
					}
					else if (deltaMode === WheelEvent.DOM_DELTA_PAGE)
					{
						jump = [this.MODE.PAGE, deltaY];
					}
					else
					{
						throw new Error(deltaMode);
					}
					this.changeLocalPage(...jump);
					return false;
				}
			});
		}

		_handleTouch()
		{
			const threshold = 10;
			let startClientY;
			let pixelScrolling;
			const move = (e) => {
				if (!e.cancelable) return;
				if (startClientY === undefined) return;
				if (e.touches.length !== 1) return;
				const [touch] = e.touches;
				const delta = touch.clientY - startClientY;
				if (pixelScrolling === undefined && Math.abs(delta) > threshold)
				{
					pixelScrolling = this.localTable.localRenderer.enterPixelScrolling(this);
				}
				if (pixelScrolling !== undefined)
				{
					e.preventDefault();
					pixelScrolling.update(delta);
				}
			};
			const end = (e) => {
				pixelScrolling && pixelScrolling.leave();
				pixelScrolling = startClientY = undefined;

				e.target.removeEventListener('touchmove', move);
				e.target.removeEventListener('touchend', end);
				e.target.removeEventListener('touchcancel', end);
			};
			const start = (e) => {
				startClientY = e.touches.length === 1 ? e.touches[0].clientY : undefined;
				pixelScrolling = undefined;

				// when target is removed from dom, touch events won't bubble to parents anymore (which make sense but it is not obvious that e.target is where browser triggers from)
				e.target.addEventListener('touchmove', move);
				e.target.addEventListener('touchend', end);
				e.target.addEventListener('touchcancel', end);
			};
			this.elements.tsTableScroll[0].addEventListener('touchstart', start);
		}

		_handleKeyShortcuts()
		{
			let mouseIn = false;
			this.elements.tsTableScroll.on({
				'mouseenter': () => { mouseIn = true; },
				'mouseleave': () => { mouseIn = false; },
			});
			const inputsSelector = ['button', 'checkbox', 'file', 'hidden', 'image', 'radio', 'reset', 'submit']
				.map((t) => `input:not([type="${t}"])`)
				.concat('textarea,select,[contenteditable]')
				.join(',')
			;
			document.addEventListener('keydown', (e) => {
				let jump;
				if (e.keyCode === 36 && !e.shiftKey && !e.altKey && !e.metaKey) // Home, ctrl+Home
				{
					jump = [this.MODE.HOME];
				}
				else if (e.keyCode === 35 && !e.shiftKey && !e.altKey && !e.metaKey) // End, ctrl+End
				{
					jump = [this.MODE.END];
				}
				else if (e.keyCode === 33 && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) // PageUp
				{
					jump = [this.MODE.PAGE, -1];
				}
				else if (e.keyCode === 34 && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) // PageDown
				{
					jump = [this.MODE.PAGE, 1];
				}
				else if (e.keyCode === 38 && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) // ArrowUp
				{
					jump = [this.MODE.LINE, -1];
				}
				else if (e.keyCode === 40 && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) // ArrowDown
				{
					jump = [this.MODE.LINE, 1];
				}
				if (
					jump !== undefined &&
					(mouseIn || $(e.target).closest(this.elements.tsTableScroll).length) &&
					!$(e.target).closest(inputsSelector).length
				)
				{
					e.preventDefault();
					this.changeLocalPage(...jump);
				}
			});
		}

		changeLocal()
		{
			let size = 0;
			if (this.elements.tableSelector.hasClass('tsShowContent'))
			{
				let minHeight = 0;
				for (const el of this.elements.filterAffectParentHeight(this.elements.tsTableSticky.children().not(this.elements.tsTableScroll)))
				{
					minHeight += $(el).outerHeight(true);
				}
				minHeight += this.localTable.localRenderer.minimumNumberOfRows * this.localTable.localRenderer.rowConstantSize;
				requestAnimationFrame(() => {
					this.elements.tsTableSticky.css('min-height', `${minHeight}px`);
				});
				size += Math.max(this.elements.tsTableSticky.height(), minHeight);
				size -= this.elements.tsTable.position().top;
				size -= this.elements.tsTableScroll.position().top; // prevAll
				for (const el of this.elements.filterAffectParentHeight(this.elements.tsTableScroll.nextAll()))
				{
					size -= $(el).outerHeight(true);
				}
			}
			this.itemsPerPage = Math.max(Math.floor(size / this.localTable.localRenderer.rowConstantSize), this.localTable.localRenderer.minimumNumberOfRows);
			this.changeLocalPage(this.MODE.NONE, undefined, false);
		}

		changeLocalPage(mode, amount = 1, animate = true)
		{
			const {itemsPerPage = this.paginator.local.itemsPerPage} = this;
			const {itemCount} = this.paginator.remote;
			let {start} = this.paginator.local;
			if (mode === this.MODE.HOME)
			{
				start = 0;
			}
			else if (mode === this.MODE.END)
			{
				start = itemCount - itemsPerPage;
			}
			else if (mode === this.MODE.PAGE)
			{
				start += itemsPerPage * amount;
			}
			else if (mode === this.MODE.LINE)
			{
				start += amount;
			}
			else if (mode === this.MODE.PIXEL || mode === this.MODE.PIXEL_ABS)
			{
				const num = amount / this.localTable.localRenderer.rowConstantSize;
				const int = Math.round(num);
				if (mode === this.MODE.PIXEL_ABS)
				{
					start = 0;
				}
				start += int;
			}
			else if (mode !== this.MODE.NONE)
			{
				throw new Error(mode);
			}
			this.localTable.moveToPosition(start, itemsPerPage, animate);
		}

	}

	return TableSelectorScroller;
});

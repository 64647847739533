Sim.require.amd.registerRaw("/app/components/HtmlEditor/Buttons/HtmlButton.js", ["/app/components/HtmlEditor/Buttons/BaseButton.js","/app/components/HtmlEditor/Simditor.js"],  (BaseButton, Simditor) => {

	class HtmlButton extends BaseButton
	{

		get name() { return 'html'; }
		get icon() { return 'simditor-icon simditor-icon-code'; }
		get needFocus() { return false; }

		_init()
		{
			super._init();
			this.editor.textarea.on('focus', () => {
				this.editor.el.addClass('focus').removeClass('error');
			});
			this.editor.textarea.on('blur', () => {
				this.editor.el.removeClass('focus');
				// setValue updated via change event
			});
			document.addEventListener('keydown', (e) => {
				if (this.editor.textarea[0] === e.target && (e.keyCode || e.which) === 9 && !e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey)
				{
					e.preventDefault();
					document.execCommand('insertText', false, '\t');
				}
			});
			this.editor.sync = () => {
				let val = Simditor.prototype.sync.apply(this.editor);
				val = this.#beautifyHTML(val);
				this.editor.textarea.val(val);
				return val;
			};
		}

		command(param = null)
		{
			const clsName = 'o-htmlEditor--isHtmlMode';
			param = param === null ? !this.editor.el.hasClass(clsName) : !!param;
			this.editor.trigger('blur');
			this.editor.el.toggleClass(clsName, param);
			if (param)
			{
				this.editor.hidePopover();
				this.editor.sync();
				this.editor.textarea.trigger('focus');
			}
			else
			{
				// setValue updated via change event
				this.editor.body.trigger('focus');
			}
			this.#disableAllButtons(param);
		}

		#disableAllButtons(disable)
		{
			for (const button of this.editor.toolbar.buttons)
			{
				if (button instanceof HtmlButton)
				{
					button.setActive(disable);
				}
				else if (!(button instanceof BaseButton) || button.mustDisableForHtmlMode())
				{
					button.setDisabled(disable);
				}
			}
		}

		#beautifyHTML(html)
		{
			html = html.replace(/\s+/gu, ' ');

			const blockNodes = ['br', 'div', 'p', 'ul', 'ol', 'li', 'blockquote', 'hr', 'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'table'];
			const indentString = '\t';
			const re = /<(\/?)(\w+)\b.*?(\/?)>/gu;
			let result = '';
			let level = 0;
			let lastMatch;
			let match;
			while ((match = re.exec(html)) !== null)
			{
				const tag = match[2].toLowerCase();
				match.isBlockNode = blockNodes.includes(tag);
				match.isEmpty = tag === 'br';
				match.isStartTag = (match[1] !== '/' && match[3] !== '/' && !match.isEmpty);
				match.isEndTag = ((match[1] === '/' || match[3] === '/') && !match.isEmpty);
				const cursor = lastMatch ? lastMatch.index + lastMatch[0].length : 0;
				const str = html.substring(cursor, match.index);
				if (str.trim().length > 0)
				{
					result += str;
				}
				if (match.isBlockNode && match.isEndTag && !match.isStartTag)
				{
					level -= 1;
				}
				if (match.isBlockNode && (match.isStartTag || match.isEmpty))
				{
					if (!lastMatch || !lastMatch.wasNewLineAdded)
					{
						result += '\n';
					}
					result += indentString.repeat(level);
				}
				result += match[0];
				if (match.isBlockNode && match.isEndTag)
				{
					result += '\n';
					if (level === 0)
					{
						result += '\n';
					}
					match.wasNewLineAdded = true;
				}
				if (match.isBlockNode && match.isStartTag && !match.isEndTag)
				{
					level += 1;
				}
				lastMatch = match;
			}
			html = `${result.trim()}\n`;

			html = html.replace(/^(\t*)(<br\s*\/?>)(?!\n|<\/)/mgui, '$1$2\n$1');
			html = html.replace(/^(<p>)([^\n]*)(?<!<\/p>)\n(\t*)([^]*?)(?<!\n)(<\/p>)$/mgu, '$1\n$3$2\n$3$4\n$5');
			html = html.replace(/^(\t*)<(\w+)>\s*(<br\s*\/?>)\s*<\/\2>$/mgu, '$1<$2>$3</$2>');
			html = html.trim() === '' ? '' : html;
			return html;
		}

	}

	return HtmlButton;
});

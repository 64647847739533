Sim.require.amd.registerRaw("/app/components/DropdownMenuSearch/DropdownMenuPick.js", ["/public/js/Helpers/ElementFinder.js","/public/js/Helpers/Scrolling.js"],  (ElementFinder, Scrolling) => {

	let globalTabKeydown = false;
	const fn = (e) => { globalTabKeydown = (e.type === 'keydown' && e.which === 9); }; // tab
	for (const type of ['keydown', 'keyup', 'mousedown'])
	{
		document.addEventListener(type, fn, {capture: true, passive: true});
	}

	class DropdownMenuPick
	{

		#selectFn;
		#root;
		#triggerElement;
		#menu;

		#statuses = {
			shown: false,
			focused: false,
			hovered: false,
			hoveredHoldOpen: false,
			clickedBounce: false,
		};

		constructor(options)
		{
			if (!$.isPlainObject(options))
			{
				const $el = ElementFinder.$one(options);
				options = {
					container: $el.parent(),
					triggerElementSelector: $el,
					menu: '.m-dropdownMenu',
					select: DropdownMenuPick.clickableSelect,
				};
			}

			this.#selectFn = options.select;
			this.#root = new ElementFinder(options.container);
			this.#triggerElement = this.#root.findOne(options.triggerElementSelector);

			this.#menu = options.menu === null ? $('<ul class="m-dropdownMenu">') : this.#root.findOne(options.menu);
			if (!this.#menu.is('.m-dropdownMenu')) throw new Error;
			this.#menu.attr('tabindex', '-1').hide();

			if (!this.#triggerElement.is('input, button, a, [tabindex]'))
			{
				this.#triggerElement.attr('tabindex', '0');
			}
			const isTextInput = this.#triggerElement.is('input[type="text"]');

			this.#triggerElement.on({
				'focus': () => {
					this.#statuses.focused = true;
				},
				'focus mousedown': (e) => {
					if (e.type === 'mousedown' && (this.#menu[0] === e.target || $.contains(this.#menu[0], e.target)))
					{
						return;
					}
					if (e.type === 'focus' && isTextInput && globalTabKeydown)
					{
						return;
					}
					globalTabKeydown = false;
					if (options.open)
					{
						options.open();
					}
					else if (!this.#statuses.shown && (e.type === 'focus' || e.type === 'mousedown'))
					{
						this.#showMenu();
						if (!isTextInput)
						{
							this.#statuses.clickedBounce = true;
							setTimeout(() => { this.#statuses.clickedBounce = false; }, 100);
						}
					}
					else if (e.type === 'mousedown' && !this.#statuses.clickedBounce && !isTextInput)
					{
						this.#hideMenu();
					}
				},
				'blur': () => {
					if (!document.hasFocus())
					{
						return;
					}
					this.#statuses.focused = false;
					if (!this.#statuses.hovered && !this.#statuses.hoveredHoldOpen)
					{
						this.#hideMenu();
					}
				},
				'keyup': (e) => {
					if (!this.#statuses.shown)
					{
						if (
							e.which === 13 || // enter
							e.which === 40 // down arrow
						)
						{
							e.stopPropagation();
							e.preventDefault();
							this.#triggerElement.trigger('focus');
						}
						return;
					}
					switch (e.which)
					{
						case 9: // tab
						case 13: // enter
							e.stopPropagation();
							e.preventDefault();
							if (e.which !== 9 || isTextInput)
							{
								this.#select(e);
							}
							break;

						case 27: // escape
							e.stopPropagation();
							e.preventDefault();
							this.#hideMenu();
							break;
					}
				},
				'keydown': (e) => {
					if (!this.#statuses.shown)
					{
						if (e.which === 13) // enter
						{
							e.stopPropagation();
							e.preventDefault();
						}
						return;
					}
					switch (e.which)
					{
						case 9: // tab
						case 13: // enter
						case 27: // escape
							e.stopPropagation();
							e.preventDefault();
							if (e.which === 9 && !isTextInput)
							{
								this.#moveActiveItem(e.shiftKey ? 'prev' : 'next');
							}
							break;

						case 38: // up arrow
						case 40: // down arrow
							e.preventDefault();
							this.#moveActiveItem(e.which === 38 ? 'prev' : 'next');
							break;

						case 36: // home
						case 33: // page up
							e.preventDefault();
							this.#moveActiveItem('first');
							break;

						case 35: // end
						case 34: // page down
							e.preventDefault();
							this.#moveActiveItem('last');
							break;
					}
				},
			});

			this.#menu.on({
				'click': (e, data) => {
					if (e.type === 'click' && data && data.clickThrough)
					{
						return;
					}
					this.#select(e);
					return false;
				},
				'mouseenter': () => {
					this.#statuses.hovered = true;
				},
				'focusin': () => {
					this.#triggerElement.trigger('focus');
				},
				'mouseleave': () => {
					this.#statuses.hovered = false;
					if (!this.#statuses.focused)
					{
						this.#hideMenu();
					}
				},
			});

			let holdOpenSelector = '.js-dropdownMenuHoverHoldOpen';
			if (this.#triggerElement.is('input[id]'))
			{
				holdOpenSelector += `, label[for="${$.escapeSelector(this.#triggerElement.attr('id'))}"]`;
			}
			$(document)
				.on('mouseenter', holdOpenSelector, () => {
					this.#statuses.hoveredHoldOpen = true;
					this.#menu.addClass('m-dropdownMenu--holdLower');
				})
				.on('mouseleave', holdOpenSelector, () => {
					this.#statuses.hoveredHoldOpen = false;
					this.#menu.removeClass('m-dropdownMenu--holdLower');
					if (!this.#statuses.focused && this.#statuses.shown)
					{
						this.#triggerElement.trigger('focus');
					}
				})
			;

			this.#menu.on('mouseenter', '> .m-dropdownMenu__row', (e) => {
				this.#moveActiveItem('current', e.currentTarget, false);
			});
		}

		$menu()
		{
			return this.#menu;
		}

		$triggerElement()
		{
			return this.#triggerElement;
		}

		_status(name)
		{
			if (!(name in this.#statuses)) throw new Error(name);
			return this.#statuses[name];
		}

		$find(selector, requireOne = true)
		{
			return arguments.length === 0 ? this.#root : this.#root.findOne(selector, requireOne);
		}

		#hideMenu()
		{
			this.#menu.hide();
			this.#statuses.shown = false;
			this.#root.$root.trigger('Rentflow:DropdownMenuSearch.changeActive');
		}

		#updateMenuPos(force = false)
		{
			if (!this.#statuses.shown && !force)
			{
				return;
			}
			if (!this.#triggerElement.is(':visible'))
			{
				return;
			}

			let pos;
			if (this.#menu.is('.m-dropdownMenu--fixed'))
			{
				const offset = this.#triggerElement.offset();
				const $document = $(document);
				pos = {
					top: offset.top - $document.scrollTop(),
					left: offset.left - $document.scrollLeft(),
				};
			}
			else
			{
				pos = this.#triggerElement.position();
			}

			let top;
			let left;
			if (this.#menu.is('.m-dropdownMenu--up'))
			{
				const menuOuterHeight = this.#menu.outerHeight(true);
				top = pos.top - menuOuterHeight;
			}
			else
			{
				const height = this.#triggerElement[0].offsetHeight;
				top = pos.top + height;
			}
			if (this.#menu.is('.m-dropdownMenu--left'))
			{
				const width = this.#triggerElement.outerWidth(true);
				const menuOuterWidth = this.#menu.outerWidth(true);
				left = pos.left + width - menuOuterWidth;
			}
			else
			{
				left = pos.left;
			}
			this.#menu.css({
				top: `${top}px`,
				left: `${left}px`,
				right: '0', // rtl
			});
		}

		#showMenu()
		{
			if (this.#menu.is('.m-dropdownMenu--fixed') && !this.#triggerElement.data('DropdownMenuPick-scrollRegistered'))
			{
				$(this.#triggerElement.parents()).add($(document)).on('scroll', () => this.#updateMenuPos());
				this.#triggerElement.data('DropdownMenuPick-scrollRegistered', true);
			}
			this.#updateMenuPos(true);
			if (!$.contains(this.#root.$root[0], this.#menu[0]))
			{
				this.#triggerElement.add(this.#triggerElement.nextAll('.js-dropdownMenuSearchMenuAfter')).last().after(this.#menu);
			}
			this.#menu.show();
			this.#statuses.shown = true;
		}

		#moveActiveItem(type, element, scroll = true)
		{
			if (!this.#statuses.shown)
			{
				return;
			}
			const current = this.#menu.children('.m-dropdownMenu__row--active').removeClass('m-dropdownMenu__row--active');
			let move;
			if (type === 'prev')
			{
				move = current.prev();
			}
			else if (type === 'next')
			{
				move = current.next();
			}
			else if (type === 'current')
			{
				move = $(element).closest('.m-dropdownMenu__row');
			}
			if (type === 'first' || (type === 'next' && !move.length))
			{
				move = this.#menu.children('.m-dropdownMenu__row:first');
			}
			else if (type === 'last' || (type === 'prev' && !move.length))
			{
				move = this.#menu.children('.m-dropdownMenu__row:last');
			}
			move.addClass('m-dropdownMenu__row--active');
			if (this.#menu.hasClass('m-dropdownMenu--scroll') && move.length && scroll)
			{
				Scrolling.scrollIntoViewIfNeeded(move[0]);
			}
			this.#root.$root.trigger('Rentflow:DropdownMenuSearch.changeActive');
		}

		_openMenu(open = true, firstActive = undefined)
		{
			if (open)
			{
				this.#showMenu();
				this.#moveActiveItem('current', firstActive);
			}
			else
			{
				this.#hideMenu();
			}
		}

		#select(e)
		{
			if (!this.#statuses.shown)
			{
				return;
			}
			const el = this.#menu.children('.m-dropdownMenu__row--active');
			const value = el.data('value') ?? el.children('a').attr('href'); // [data-value]
			if (value !== undefined && !el.hasClass('m-dropdownMenu__row--disabled'))
			{
				this.selectItem(value, el, e);
				this.#hideMenu();
				setTimeout(() => { if (this.#statuses.shown) this.#hideMenu(); }, 0); // handle alert re-triggering focus
				return;
			}
			this.#triggerElement.trigger('focus');
		}

		selectItem(value, el, event)
		{
			if (this.#selectFn)
			{
				this.#selectFn(value, el, event);
			}
		}

		static clickableSelect(value, el, event)
		{
			if (el && el.is('a[data-popup][href]') && el.attr('href') === value)
			{
				el.trigger('click', {clickThrough: true});
			}
			else
			{
				location.href = value;
			}
		}

	}

	return DropdownMenuPick;
});

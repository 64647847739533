Sim.require.amd.registerRaw("/app/Intranet/components/HellForms/MultipleHellSearchForm.js", ["/app/components/DropdownMenuSearch/DropdownMenuSearch.js"],  (DropdownMenuSearch) => {

	class SearchByKeyword extends DropdownMenuSearch
	{

		#newPopupName;
		#multipleHellFormPopup;
		#selectButton;
		#used = null;

		constructor(options)
		{
			options.searchInputSelector = 'input[name="keyword"]';
			super(options);
			this.#newPopupName = this.$find('input[name="newPopupName"]');
			this.#initAjaxSubmit();
		}

		#initAjaxSubmit()
		{
			this.#multipleHellFormPopup = this.$find('.o-multipleHellFormPopup');
			this.#selectButton = this.$find('input[type="submit"][name="select"]');
			const snippetsSuccess = jQuery.nette.createLocalSuccess()
				.addSnippetHandler(
					'*-multipleRows*',
					() => { this.#multipleHellFormPopup.trigger('init'); },
					(el, content) => el.append(content)
				)
			;
			Sim.enableAjaxSubmit(this.$find().deriveClosest('form').$root)
				.addIsEnabled((form) => this.#selectButton.is(form[0]['nette-submittedBy']))
				.onSuccess((form, ...ajaxArgs) => snippetsSuccess(...ajaxArgs))
			;
		}

		#isUsed(popupName, set = null)
		{
			if (this.#used === null)
			{
				setTimeout(() => this.#used = null);
				const obj = new Set;
				for (const checkbox of this.#multipleHellFormPopup.find('input[type="checkbox"]'))
				{
					obj.add($(checkbox).attr('name').match(/\[(.*?)\]/u)[1]);
				}
				this.#used = obj;
			}
			if (set)
			{
				this.#used.add(popupName);
			}
			return this.#used.has(popupName);
		}

		renderItem(value, text, el, item)
		{
			if (value === null)
			{
				el
					.addClass('m-dropdownMenu__row--disabled')
					.attr('title', this.getText('noPermissionRow'))
				;
			}
			else if (this.#isUsed(value))
			{
				el
					.addClass('m-dropdownMenu__row--disabled m-dropdownMenu__row--selected')
					.attr('title', this.getText('selectedRow'))
				;
			}
			super.renderItem(value, text, el, item);
		}

		selectItem(pn, el, event)
		{
			super.selectItem(pn, el, event);
			if (pn === null) return;
			this.#isUsed(pn, true);
			el.addClass('m-dropdownMenu__row--disabled');
			this.#newPopupName.val(pn);
			this.#selectButton.trigger('click');
		}

	}

	return SearchByKeyword;
});

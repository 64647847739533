
Sim.require.amd.registerRaw("/app/Intranet/components/Offers/OfferForm/Objects.js", ["/app/components/FormRepeater/FormRepeater.js"],  function (FormRepeater) {

	function OfferFormObjects (objectsParameters, objectsLayoutsParameters, objectIdentifiers, formatRooms) {
		this.objects = new FormRepeater(objectsParameters);
		this.objectsLayouts = [];
		this.objectsLayoutsParameters = objectsLayoutsParameters;
		this.objectIdentifiers = objectIdentifiers;
		this.formatRooms = formatRooms;

		this.objects.changed.setTriggerDebounce(50);
		this.objects.changed.setTriggerCreateArguments(this.createChangedArguments.bind(this));

		Sim.redefineFunction(this.objects, 'add', function (old) {
			old();
			this.triggerAddObject(true);
		}.bind(this));
		Sim.redefineFunction(this.objects, 'remove', function (old) {
			var objectRow = old();
			this.addReturnUnitPossibility(objectRow.data('objectIdentifier'), objectRow);
		}.bind(this));

		this.initEvents();
		this.triggerAddObject();
	}

	OfferFormObjects.prototype = {

		initEvents: function () {
			var self = this;

			this.objects.$target.on('change', '.objectRow .type select.value', function (event) {
				var objectRow = $(this).closest('.objectRow');
				var oi = self.getObjectIdentifier(this.value, true);
				self.addReturnUnitPossibility(_.first(objectRow.data('wasObjectIdentifiers')), objectRow);
				objectRow.attr('data-mode', oi.mode).find('input.mode').val(oi.mode);
				objectRow.attr('data-type', oi.type).find('input.type').val(oi.type);
				objectRow.data('wasObjectIdentifiers', [oi.objectIdentifier].concat(objectRow.data('wasObjectIdentifiers') || []));
				objectRow.data('objectIdentifier', oi.objectIdentifier).find('select.options').val(oi.sourceObjectIdentifier);
				self.updateSelectBox(objectRow);
				self.objects.changed.trigger(event);
			});
			this.objects.$target.on('focus', '.objectRow .type select.value', function () {
				var el = $(this).closest('.objectRow').find('.type select.options')[0];
				$(el).trigger('focus').trigger('click');
				var event;
				try {
					event = new MouseEvent('mousedown');
				} catch (e) {
					event = document.createEvent('MouseEvents');
					event.initMouseEvent('mousedown', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
				}
				setTimeout(function () {
					el.dispatchEvent(event); // chrome only
				});
			});
			this.objects.$target.on('change', '.objectRow .type select.options', function () {
				var $el = $(this).closest('.objectRow').find('.type select.value');
				var oi = self.getObjectIdentifier(this.value);
				$el.append($('<option>').attr('value', oi.objectIdentifier).text(oi.labelFull)).val(oi.objectIdentifier).trigger('change');
			});
			this.objects.$target.on('focus', '.objectRow .type select.options', function () {
				var objectRow = $(this).closest('.objectRow');
				self.updateSelectBox(objectRow);
			});

			this.objects.changed.on(function () {
				_.each(this.objects.$target.find('.objectRow'), function (el) {
					this.updateInfo($(el));
				}, this);
			}, this);

			this.objects.$target.on('click', '.objectRow .sizeChange', function () {
				var objectRow = $(this).closest('.objectRow');
				var size = objectRow.attr('data-size');
				if (size === '')
				{
					var isf = objectRow.find('input[name$="[internalSizeFrom]"]').val();
					var ist = objectRow.find('input[name$="[internalSizeTo]"]').val();
					var esf = objectRow.find('input[name$="[externalSizeFrom]"]').val();
					var est = objectRow.find('input[name$="[externalSizeTo]"]').val();
					size = ((isf === ist && esf === est) || (ist === '' && est === '')) ? 'single' : 'range';
				}
				else
				{
					size = size === 'single' ? 'range' : 'single';
				}
				objectRow.attr('data-size', size);
				if (size === 'single')
				{
					objectRow.find('input[name$="[internalSizeTo]"], input[name$="[externalSizeTo]"]').val('').trigger('change');
				}
			});
		},

		updateInfo: function (objectRow) {
			var get = _.bind(function (k) {
				if (objectRow.attr('data-mode') === 'linked')
				{
					var oi = this.getObjectIdentifier(objectRow.data('objectIdentifier'));
					if (!oi.infoData)
					{
						oi = this.getObjectIdentifier(oi.sourceObjectIdentifier);
					}
					return oi.infoData ? oi.infoData[k] : null;
				}
				if (k === 'layouts')
				{
					return _.map(objectRow.find('.layouts .layout'), function (el) {
						var $el = $(el);
						var layout = {};
						_.each(['rooms', 'bathrooms', 'bedrooms'], function (k) {
							var v = $el.find('input[name$="[' + k + ']"]:visible').val();
							layout[k] = (v === undefined || v === '') ? null : parseInt(v, 10);
						});
						var k = 'kitchenType';
						var v = $el.find('select[name$="[' + k + ']"]:visible').val();
						layout[k] = (v === undefined || v === '') ? null : v;
						return layout;
					});
				}
				var v = objectRow.find('input[name$="[' + k + ']"]:visible').val();
				return (v === undefined || v === '') ? null : Sim.number.parse(v);
			}, this);
			objectRow.find('.info .size').toggle(_.reject(['internalSize', 'externalSize'], function (k) {
				var from = get(k + 'From');
				var to = get(k + 'To');
				var size = {
					from: to === null ? from : (from === null ? to : Math.min(from, to)),
					to: (to === null || from === null || from === to) ? null : Math.max(from, to),
				};
				var text = size.from === null ? '\u2014' : Sim.number.format(size.from) + (size.to === null ? '': '\u2013' + Sim.number.format(size.to)) + ' \u33A1';
				objectRow.find('.info .size .' + k).html($('<div>').text(text).html().replace('\u33A1', 'm<sup>2</sup>'));
				return (size.from === null && size.to === null);
			}).length !== 0);
			var layoutsText = _.filter(_.map(get('layouts'), this.formatRooms)).join(', ');
			objectRow.find('.info .layout').text(layoutsText);
			objectRow.find('.info .layout-text').toggle(layoutsText !== '');
		},

		getObjectIdentifier: function (objectIdentifier, allowEmpty) {
			var oi = this.objectIdentifiers[objectIdentifier];
			if (allowEmpty && !oi) return {};
			if (!oi) throw new Error(objectIdentifier);
			if (oi.objectIdentifier === oi.sourceObjectIdentifier)
			{
				oi = _.extend({}, oi, {
					objectIdentifier: oi.objectIdentifier + '-' + Math.random(),
					sourceObjectIdentifier: oi.sourceObjectIdentifier,
				});
				this.objectIdentifiers[oi.objectIdentifier] = oi;
			}
			return oi;
		},

		addReturnUnitPossibility: function (objectIdentifier, objectRow) {
			if (!objectIdentifier) return;
			if (!this.returnUnitPossibilities) this.returnUnitPossibilities = [];
			this.returnUnitPossibilities.push({
				objectIdentifier: objectIdentifier,
				objectRow: objectRow,
			});
		},

		updateSelectBox: function (objectRow) {
			var oi = this.getObjectIdentifier(objectRow.data('objectIdentifier'), true);
			var options = objectRow.find('select.options option');
			options.prop('disabled', false).removeClass('highlighted active');
			if (oi.mode === 'linked')
			{
				options.filter('[data-mode="unlinked"][data-type="' + oi.type + '"]').addClass('highlighted');
			}
			_.each(this.objects.$target.find('.objectRow[data-mode="linked"]'), function (el) {
				var oi = this.getObjectIdentifier($(el).data('objectIdentifier'));
				options.filter('[value="' + oi.sourceObjectIdentifier + '"]').prop('disabled', true);
			}, this);
			options.filter('[value="0"]').prop('disabled', true); // no unit
			options.filter('[value="' + oi.sourceObjectIdentifier + '"]').prop('disabled', false).addClass('active');
		},

		triggerAddObject: function (justNow) {
			var self = this;

			this.objects.$target.find('.objectRow .type select.value').trigger('change');
			if (justNow) this.objects.$target.find('.objectRow .type select.value:last').trigger('focus');

			this.objects.$target.find('.layouts[data-repeater]').each(function () {
				if ($(this).data('repeater')) return;
				self.createObjectsLayout($(this).closest('.objectRow').attr('data-repeater-row'));
			});
			if (this.objects.getRows().length === 1)
			{
				var l = this.objects.$target.find('.layouts[data-repeater]:last').data('repeater');
				if (l.getRows().length === 0) l.add();
			}

			this.objects.$target.find('.objectRow[data-size=""] .sizeChange').trigger('click');
		},

		createObjectsLayout: function (objectIndex) {
			var p = this.objectsLayoutsParameters;
			var r = new FormRepeater(this.objects.fillIndex(p.rootId, objectIndex), p.formName, this.objects.fillIndex(p.containerName, objectIndex));
			this.objectsLayouts.push(r);
		},

		createChangedArguments: function () {
			var getObjectData = function (objectIdentifier, objectRow, returnUnitPossibility) {
				var oi = this.getObjectIdentifier(objectIdentifier);
				if (objectRow === undefined)
				{
					objectRow = _.reduce(this.objects.$target.find('.objectRow'), function (memo, el) {
						if (!memo && oi.objectIdentifier === $(el).data('objectIdentifier'))
						{
							return $(el);
						}
						return memo;
					}, null);
				}
				return {
					objectIdentifier: oi.objectIdentifier,
					wasObjectIdentifiers: objectRow ? _.clone(objectRow.data('wasObjectIdentifiers')) || [] : [],
					objectId: oi.realObjectId,
					label: oi.labelFull,
					has: !!objectRow,
					returnUnit: function () {
						if (!returnUnitPossibility) throw new Error;
						var objectRow = returnUnitPossibility.objectRow;
						if (!$.contains(this.objects.$target[0], objectRow[0]))
						{
							objectRow = this.objects.add(objectRow);
						}
						objectRow.find('.type select.options').val(oi.sourceObjectIdentifier).trigger('change');
						setTimeout(function () {
							objectRow.find('.type select.options').trigger('blur');
						}.bind(this));
					}.bind(this),
				};
			}.bind(this);
			return [
				function getSelectedObjects() {
					var result = [];
					_.each(this.objects.$target.find('.objectRow'), function (el) {
						var objectRow = $(el);
						var objectIdentifier = objectRow.data('objectIdentifier');
						if (!objectIdentifier) return;
						result.push(getObjectData(objectIdentifier, objectRow));
					}, this);
					return result;
				}.bind(this),
				function getRealObjects() {
					var result = {};
					var add = function (objectIdentifier, objectRow, rup) {
						if (!objectIdentifier) return;
						var oi = _.isObject(objectIdentifier) ? objectIdentifier : this.getObjectIdentifier(objectIdentifier);
						if (!oi.realObjectId) return;
						if (result[oi.realObjectId]) return;
						result[oi.realObjectId] = getObjectData(oi.objectIdentifier, objectRow, rup);
					}.bind(this);
					_.each(this.objects.$target.find('.objectRow'), function (el) {
						var objectRow = $(el);
						add(objectRow.data('objectIdentifier'), objectRow);
					}, this);
					_.each(_.clone(this.returnUnitPossibilities || []).reverse(), function (rup) {
						add(rup.objectIdentifier, null, rup);
					}, this);
					_.each(this.objectIdentifiers, function (oi) {
						add(oi, null);
					}, this);
					return _.values(result);
				}.bind(this),
			];
		},

	};

	return OfferFormObjects;
});

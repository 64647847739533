Sim.require.amd.registerRaw("/app/components/DropdownMenuSearch/SearchInput.js", ["/app/components/DropdownMenuSearch/DropdownMenuSearch.js"],  (DropdownMenuSearch) => {

	class SearchInput extends DropdownMenuSearch
	{

		#changeButton;
		#selectedText;
		#hiddenInputs;
		#searchInput;

		constructor(options)
		{
			options.searchInputSelector = 'input[type="text"].o-searchInput__searchInput';
			super(options);
			this.#searchInput = this.$find(options.searchInputSelector);

			this.#hiddenInputs = this.$find().findAny('input[type="hidden"].o-searchInput__hiddenInput');
			for (const el of this.#hiddenInputs)
			{
				if ($(el).data('SearchInput'))
				{
					throw new Error($(el).attr('name'));
				}
			}
			this.#hiddenInputs.data('SearchInput', true);

			this.#changeButton = this.$find('.o-searchInput__changeButton');
			this.#changeButton.on('click', (e) => {
				this.selectItem(null, null, e);
				setTimeout(() => this.#searchInput.trigger('focus'));
			});

			this.#selectedText = this.$find('.o-searchInput__selectedText');
			const submittedItem = this.#selectedText.data('submittedValue'); // [data-submitted-value]
			if (submittedItem)
			{
				const el = $('<span><a></a></span>');
				this.renderItem(submittedItem.value, submittedItem.text, el, submittedItem);
				this.#selectedText.html(el.children('a').contents());
				super.selectItem(submittedItem.value, null, undefined); // skips this is to fill super.#lastValue
				this.#changeButton.toggleClass('o-searchInput__changeButton--hideIfDefault', !!options.hideChangeButtonIfDefault);
			}
			else if (options.focusSearchIfFilled && (options.minLength === 0 || this.#searchInput.val() !== '') && this.#searchInput.is(':visible'))
			{
				this.#searchInput.trigger('focus');
			}
		}

		renderItem(value, text, el, item)
		{
			if (item.disabled || typeof item.disabled === 'string')
			{
				el
					.addClass('m-dropdownMenu__row--disabled')
					.attr('title', typeof item.disabled === 'string' ? item.disabled : null)
				;
			}
			super.renderItem(value, text, el, item);
		}

		selectItem(value, el, event)
		{
			super.selectItem(value, el, event);
			this.#hiddenInputs.val('');
			for (const [name, id] of Object.entries(value || []))
			{
				const $el = this.#hiddenInputs.filter(`[name$="[${$.escapeSelector(name)}]"]`);
				if ($el.length !== 1) throw new Error(name);
				$el.val(id);
			}
			this.#hiddenInputs.trigger('change');
			this.#selectedText.html(el ? el.children('a').contents().clone() : ''); // keeps highlighting
			this.#changeButton.removeClass('o-searchInput__changeButton--hideIfDefault');
		}

	}

	return SearchInput;
});

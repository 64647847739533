
Sim.require.amd.registerRaw("/app/components/ClickthruEdit/ClickthruEdit.js", [], function () {

	var Clickthru = function (id) {
		this.$root = $(document.getElementById(id));
		if (this.$root.length !== 1 || !this.$root.hasClass('clickthru-edit')) throw new Error(id);
		this.$cancel = this.$root.find('a.cancel');
		this.$save = this.$root.find('a.save');
		this.$edit = this.$root.find('.edit');

		this.$edit.on('keyup paste cut', _.bind(this.change, this));

		if (this.$edit.hasClass('a-privateText'))
		{
			this.$edit.on('click focusin', () => {
				if (!this.$edit.hasClass('a-privateText--revealed'))
				{
					this.$edit.trigger('blur');
				}
			});
		}

		this.$cancel.on('click', _.bind(function (e) {
			e.preventDefault();
			this.cancel();
		}, this));

		this.$save.on('click', _.bind(function (e) {
			e.preventDefault();
			this.save();
		}, this));

		this.$edit.on('paste', _.bind(function () {setTimeout(_.bind(function () {
			this.$edit.find('*').each(function () {
				var $this = $(this);
				_.each(_.pluck(this.attributes, 'name'), function (attribute) {
					$this.removeAttr(attribute);
				});
			});
		}, this));}, this));
		this.$edit.on('focusout', _.bind(function () {setTimeout(_.bind(function () {
			this.setValue(this.getValue());
		}, this), 500);}, this));
	};

	Clickthru.prototype.change = function () {
		if (this.saving) return;
		this.$save.show();
		this.$cancel.show();
	};

	Clickthru.prototype.getValue = function () {
		return this.$edit[0].innerText.replace(/\u00A0/g, ' ');
	};

	Clickthru.prototype.setValue = function (value) {
		this.$edit.html(this.escapeValue(value));
	};

	Clickthru.prototype.escapeValue = function (value) {
		value = $('<div/>').text(value).html(); // escape
		value = value.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2'); // nl2br
		return value;
	};

	Clickthru.prototype.cancel = function () {
		if (this.saving) return;
		this.$edit.html(this.$edit.data('original'));
		this.$cancel.hide();
		this.$save.hide();
	};

	Clickthru.prototype.save = function () {
		if (this.saving) return;
		var value = this.getValue();
		var data = {}; data[this.$root.attr('id')  + '-value'] = value;
		this.$root.trigger('clickthru-save', [this]);
		this.$cancel.hide();
		this.setValue(value);
		$.ajax(this.$save.attr('href'), {
			data: data,
			beforeSend: _.bind(function () {
				this.saving = true;
				this.$save.text(this.$save.data('saving'));
			}, this),
			error: _.bind(function () {
				this.$save.text(this.$save.data('error'));
			}, this),
			success: _.bind(function (payload) {
				if (payload.status !== 'success') throw new Error(payload);
				this.$edit.data('original', value);
				this.$save.text(this.$save.data('success'));
				this.$save.fadeOut(_.bind(function () {
					this.$save.text(this.$save.data('default'));
					this.saving = false;
				}, this));
			}, this),
		});
	};

	return Clickthru;
});

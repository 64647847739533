
Sim.require.amd.registerRaw("/app/Intranet/components/OldAppFrame/OldAppFrame.js", [], () => {

	class OldAppFrame
	{

		constructor(identifier, SWITCH_KEY)
		{
			this.SWITCH_KEY = SWITCH_KEY;
			this.frame = new OldAppFrameElements(identifier);
			this.events = new OldAppFrameEventHandler(this.frame);

			this.events.onFrameChanged(() => {
				if (!this.frame.isOldAppFrame && !this.frame.isErrorPage)
				{
					const url = new URL(this.frame.window.location.href);
					url.searchParams.delete(SWITCH_KEY);
					this.frame.window.stop && this.frame.window.stop();
					this.isRedirect = true;
					Sim.redirect(url.href, true);
				}
				else
				{
					this.frame.$container.addClass('ready');
					this.frame.$container.toggleClass('userError', this.frame.isErrorPage);
					this.resize();
					this.events.addEvent(this.frame.document, 'mouseup', () => _.defer(() => this.resize())); // mouseup because click is usually canceled in js
				}
			});

			this.events.onFrameLoaded(() => this.frame.$container.addClass('loaded'));
			this.events.onFrameFatalError(() => this.frame.$container.addClass('error'));
			this.events.onFrameUnloaded(() => this.frame.$container.removeClass('ready loaded error userError'));

			this.events.onFrameLoaded(() => this.resize());

			this.resizeEvery(6000, 300);
			this.events.onFrameLoaded.once(() => {
				this.resizeEvery(3000);
				this.frame.$element.on({
					'mouseenter': () => this.resizeEvery(1500, 300),
					'mouseleave': () => this.resizeEvery(3000),
				});
			});

			this.events.onFrameUnloading(() => this.startLoading());
			this.events.onFrameLoaded(() => this.stopLoading());
			this.events.onTopUnloadingPrevented(() => this.stopLoading());
			this.events.onFrameFatalError(() => this.stopLoading());
			this.events.onTopUnloading(() => this.ignoreLoading());

			this.refreshHandler(document);
			this.events.onFrameChanged(() => this.refreshHandler(this.frame.document));

			this.frame.$container.on('Rentflow:OldAppFrame.destroy', () => this.destroy());
		}

		destroy()
		{
			this.ignoreLoading();
			this.#offResizeEvery();
			this.events.destroy();
			this.frame.destroy();
			delete this.events;
			delete this.frame;
		}

		resize(complete = true)
		{
			if (!this.frame.$container.hasClass('ready'))
			{
				return;
			}
			if (complete) // slower because causes repaint, but can catch when frame becomes smaller
			{
				this.frame.$element.height(0);
			}
			const {body, page} = this.frame;
			const verticalScrollbarSize = page ? page.offsetHeight - page.clientHeight : 0; // (height + padding + borders + scrollbars) - (height + padding)
			const bottomBodyMargin = body ? $.css(body, 'marginBottom', true) : 0; // old app bluescreen forces margin-bottom
			const height = verticalScrollbarSize + Math.max(
				this.frame.html.scrollHeight - verticalScrollbarSize - bottomBodyMargin,
				body ? body.scrollHeight - verticalScrollbarSize : 0,
				page ? page.scrollHeight : 0
			);
			if (height !== this.lastHeight)
			{
				this.lastHeight = height;
				this.frame.$element.height(height);
				this.frame.$container.height(height);
			}
			else if (complete)
			{
				this.frame.$element.height(height);
			}
		}

		startLoading()
		{
			this.stopLoading();
			const off1 = Sim.loading();
			const off2 = Sim.loading(this.frame.html);
			this.stopLoading = () => {
				off1();
				off2();
				this.stopLoading = () => {};
			};
		}

		ignoreLoading()
		{
			this.stopLoading();
			this.startLoading = () => {};
		}

		stopLoading()
		{
		}

		resizeEvery(completeMilliseconds, partialMilliseconds = null)
		{
			this.resize();
			this.#offResizeEvery();
			if (!partialMilliseconds || partialMilliseconds >= completeMilliseconds)
			{
				this.resizeInterval = setInterval(() => this.resize(true), completeMilliseconds);
			}
			else // skips complete when partial runs at same time, this means partialMilliseconds is not accurate but rounded up of multiples of completeMilliseconds
			{
				const reset = Math.ceil(completeMilliseconds / partialMilliseconds);
				let counter = 0;
				this.resizeInterval = setInterval(() => {
					const complete = ++counter === reset;
					if (complete) counter = 0;
					this.resize(complete);
				}, partialMilliseconds);
			}
		}

		#offResizeEvery()
		{
			this.resizeInterval && clearInterval(this.resizeInterval);
		}

		refreshHandler(doc)
		{
			const isSafeToReloadTop = () => {
				if (this.isRedirect) return true;
				if (!this.events.isAccessible()) return true;
				const {href} = this.frame.window.location;
				if (href === 'about:blank') return true;
				const url = new URL(href);
				return url.searchParams.has(this.SWITCH_KEY); // this mean frame changed with non-top navigation e.g. submitting form
			};
			if (doc === document)
			{
				this.events.addEvent(window, 'beforeunload', (e) => {
					if (!isSafeToReloadTop())
					{
						e.preventDefault();
						e.returnValue = 'Leave?';
					}
				});
			}
			this.events.addEvent(doc, 'keydown', (e) => {
				if (
					(e.keyCode === 116 && !e.altKey && !e.metaKey) || // F5, ctrl+F5, shift+F5
					(e.keyCode === 82 && e.ctrlKey && !e.altKey && !e.metaKey) // ctrl+R, ctrl+shift+R
				)
				{
					if (!isSafeToReloadTop())
					{
						e.preventDefault();
						this.frame.window.location.reload();
					}
				}
			});
		}

	}

	class OldAppFrameElements
	{

		constructor(identifier)
		{
			this.$container = $(`.oldAppFrameContainer[data-identifier="${$.escapeSelector(identifier)}"]`);
			this.$element = this.$container.find('> iframe.oldAppFrame');
			if (this.$container.length !== 1 || this.$element.length !== 1) throw new Error;
			this.element = this.$element.get(0);
		}

		destroy()
		{
			this.$container.remove();
			delete this.element;
			delete this.$element;
			delete this.$container;
		}

		get window()
		{
			return this.element.contentWindow;
		}

		get document()
		{
			const doc = this.element.contentDocument;
			if (!doc)
			{
				try
				{
					return this.window.document;
				}
				catch (err) // SecurityError: Blocked a frame with origin "..." from accessing a cross-origin frame.
				{
					return null;
				}
			}
			return doc;
		}

		get html()
		{
			return this.window.document.documentElement;
		}

		get body()
		{
			return this.window.document.body;
		}

		get page()
		{
			return this.window.document.getElementById('page');
		}

		get isNewApp()
		{
			return (this.window.Sim && this.window.Sim.config);
		}

		get isOldApp()
		{
			return this.window.isRentflowOldApp;
		}

		get isOldAppFrame()
		{
			return (this.isOldApp && this.window.isRentflowOldAppFrame);
		}

		get isErrorPage()
		{
			const apache = this.document.querySelector('body > address');
			return Boolean(
				(apache && apache.textContent.indexOf('Apache') !== -1) ||
				this.document.getElementById('tracyBluescreen') || // new/libs/Nette/Tracy/templates/bluescreen.phtml
				this.document.getElementById('netteBluescreen') || // old/libs/Nette/Debug/templates/bluescreen.phtml
				this.document.querySelector('h2[data-rentflow-http-error-code]') // old/app/templates/Error.404.phtml, old/app/templates/Error.500.phtml, old/app/templates/Error.auth.phtml
			);
		}

	}

	class OldAppFrameEventHandler
	{

		#offAll = [];

		constructor(frame)
		{
			if (window.parent !== window) throw new Error;
			this.frame = frame;
			this.checkedKey = `__OldAppFrameAlreadyChecked${Math.random()}`;
			this.onFrameFatalError = this._createEventFunction();
			this.onFrameChanged = this._createEventFunction();
			this.onFrameLoaded = this._createEventFunction();
			this.onFrameUnloaded = this._createEventFunction();
			this.onFrameUnloading = this._createEventFunction();
			this.onTopUnloading = this._createEventFunction();
			this.onTopUnloadingPrevented = this._createEventFunction(); // trigger even when user click leave

			setTimeout(() => this._startupEvents(), 0);
		}

		destroy()
		{
			this.#offAll.splice(0).forEach((fn) => fn());
			delete this.frame;
		}

		_startupEvents()
		{
			this.addEvent(this.frame.element, 'load', () => this._whenOnLoad());
			if (document.readyState !== 'complete')
			{
				this._waitUntilFrameIsChanged();
			}
			else
			{
				let href;
				try
				{
					href = this.frame.html ? this.frame.window.location.href : undefined;
				}
				catch (e) {}
				if (!href || href === 'about:blank')
				{
					this._waitUntilFrameIsChanged();
				}
				else
				{
					this._whenOnLoad();
				}
			}
		}

		addEvent(element, type, listener = undefined, options = {})
		{
			if (listener === undefined)
			{
				return {
					once: (_listener) => this.addEvent(element, type, _listener, {...options, once: true}),
					passive: (_listener) => this.addEvent(element, type, _listener, {...options, passive: true}),
					capture: (_listener) => this.addEvent(element, type, _listener, {...options, capture: true}),
				};
			}
			element.addEventListener(type, listener, options);
			const off = () => {
				try
				{
					element.removeEventListener(type, listener, options);
				}
				catch (err)
				{
					// after frame is unloaded into blocked frame
				}
			};
			this.#offAll.push(off);
			return off;
		}

		_whenOnLoad()
		{
			this._frameIsChanged();
			if (!this.isAccessible())
			{
				this._triggerEventFunction(this.onFrameFatalError);
				return;
			}
			this._triggerEventFunction(this.onFrameLoaded);
		}

		_registerUnloadEvents()
		{
			this._unloadLastIfWasNotYet && this._unloadLastIfWasNotYet(null, true);

			const defaultPreventedCheck = (e, no = null, yes = null) => {
				setTimeout(() => (e.defaultPrevented ? (yes && yes()) : (no && no())), 0);
			};

			if (this.offInnerEvents === undefined)
			{
				this.offInnerEvents = [];
				const unloadingTop = _.once(() => {
					this.offInnerEvents.splice(0).forEach((fn) => fn());
					this._triggerEventFunction(this.onTopUnloading);
				});
				const unloadingTopPrevented = () => {
					this._triggerEventFunction(this.onTopUnloadingPrevented);
				};
				this.addEvent(window, 'beforeunload').passive((e) => {
					defaultPreventedCheck(e, unloadingTop, unloadingTopPrevented);
				});
				// if user decide to leave we can't know that until unload
				this.addEvent(window, 'pagehide').passive().once(unloadingTop);
				this.addEvent(window, 'unload').passive().once(unloadingTop);
			}
			else
			{
				this.offInnerEvents.splice(0).forEach((fn) => fn());
			}

			const unloading = _.once(() => {
				this._triggerEventFunction(this.onFrameUnloading);
			});
			const unloaded = this._unloadLastIfWasNotYet = _.once((e, triggeredAfterTheFact) => {
				unloading();
				this._triggerEventFunction(this.onFrameUnloaded);
				if (triggeredAfterTheFact !== true)
				{
					this._waitUntilFrameIsChanged();
				}
			});

			if (!this.isAccessible())
			{
				return;
			}
			this.offInnerEvents.push(this.addEvent(this.frame.window, 'beforeunload').passive((e) => {
				defaultPreventedCheck(e, unloading);
			}));
			// unload has better browser support than pagehide, but unload may not trigger always
			this.offInnerEvents.push(this.addEvent(this.frame.window, 'pagehide').passive().once(unloaded));
			this.offInnerEvents.push(this.addEvent(this.frame.window, 'unload').passive().once(unloaded));
		}

		_frameIsChanged()
		{
			this.waitInterval && clearInterval(this.waitInterval);
			this.waitInterval = null;
			this.waitEventOff && this.waitEventOff();
			this.waitEventOff = null;
			if (this._isFrameChanged())
			{
				this.lastCheckedFrameDocument = this.frame.document;
				if (this.isAccessible())
				{
					this.frame.window[this.checkedKey] = true;
				}
				this._registerUnloadEvents();
				if (this.isAccessible())
				{
					this._triggerEventFunction(this.onFrameChanged);
				}
			}
		}

		_isFrameChanged()
		{
			try
			{
				return !(this.checkedKey in this.frame.window);
			}
			catch (err)
			{
				// SecurityError: Blocked a frame with origin "..." from accessing a cross-origin frame.
				this._notAccessible = true;
			}
			return (
				this.lastCheckedFrameDocument === undefined ||
				this.lastCheckedFrameDocument !== this.frame.document
			);
		}

		isAccessible()
		{
			return !this._notAccessible;
		}

		_waitUntilFrameIsChanged()
		{
			this.waitInterval && clearInterval(this.waitInterval);
			this.waitInterval = setInterval(() => this._checkIfFrameIsChanged(), 250);
			this.waitEventOff && this.waitEventOff();
			this.waitEventOff = this.addEvent(this.frame.element, 'Rentflow:OldAppFrame.earlyTrigger').once(() => this._checkIfFrameIsChanged());
			this._checkIfFrameIsChanged();
		}

		_checkIfFrameIsChanged()
		{
			if (!this._isFrameChanged())
			{
				return;
			}
			if (!this.isAccessible())
			{
				this._whenOnLoad(); // firefox does not trigger onload, so jump into _whenOnLoad instead of _frameIsChanged (https://bugzilla.mozilla.org/show_bug.cgi?id=444165 https://bugzilla.mozilla.org/show_bug.cgi?id=1418975)
				return;
			}
			if (this.frame.isOldApp || this.frame.isNewApp)
			{
				// no need to wait any longer, at this point we can detect, if not old or new app, onFrameChanged will wait until onload
				this._frameIsChanged();
			}
		}

		_triggerEventFunction(event)
		{
			for (const [fn, once] of event.events)
			{
				fn();
				if (once !== undefined)
				{
					event.events[once] = [() => {}];
				}
			}
		}

		_createEventFunction()
		{
			const event = (fn) => { event.events.push([fn]); };
			event.events = [];
			event.once = (fn) => { event.events.push([fn, event.events.length]); };
			return event;
		}

	}

	return OldAppFrame;
});

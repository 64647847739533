Sim.require.amd.registerRaw("/app/Intranet/components/TableSelector/TableSelectorElements.js", [], () => {

	class TableSelectorElements
	{

		constructor()
		{
			this.tableSelector = this._initFind(null, '#tableSelector');
			this.tsTable = this._initFind(this.tableSelector, '> .tsTable');
			this.tsTableSticky = this._initFind(this.tsTable, '> .tsTableSticky');
			this.realTable = this._initFind(this.tsTableSticky, '> table.realTable');
			this.tsTableScroll = this._initFind(this.tsTableSticky, '> .tsTableScroll');
			this.tableFiltering = this._initFind(this.realTable, '> thead.tableFiltering');
			this.scrollbar = this._initFind(this.tsTableScroll, '> .tsScrollbar');
		}

		cachedFind(from, selector)
		{
			const context = this[from];
			if (!('tsCacheFind' in context))
			{
				context.tsCacheFind = {};
			}
			if (!(selector in context.tsCacheFind))
			{
				context.tsCacheFind[selector] = context.find(selector);
			}
			return context.tsCacheFind[selector];
		}

		_initFind(context, selector, count = 1)
		{
			const el = context ? context.find(selector) : $(selector);
			if (count === null && el.length !== count)
			{
				throw new Error(`not found '${selector}'`);
			}
			return el;
		}

		whenChildrenAddedOrRemoved(el, change)
		{
			if ('MutationObserver' in window)
			{
				new window.MutationObserver(() => change()).observe(el, {childList: true});
			}
			else
			{
				setInterval(change, 1000);
			}
			change();
			return change;
		}

		whenStickySizeChange(change) {
			if ('ResizeObserver' in window && 'MutationObserver' in window)
			{
				const notEls = new Set([this.tsTableScroll[0]]);
				const resizeObserver = new window.ResizeObserver(change);
				const add = (nodes) => {
					for (const node of nodes)
					{
						if (node.nodeType !== Node.ELEMENT_NODE) continue;
						if (notEls.has(node)) continue;
						notEls.add(node);
						resizeObserver.observe(node);
					}
				};
				add(this.tsTableSticky);
				add(this.tsTableSticky.children());
				const mutationObserver = new window.MutationObserver((records) => records.forEach((r) => add(r.addedNodes)));
				mutationObserver.observe(this.tsTableSticky[0], {childList: true});
			}
			else
			{
				setInterval(change, 1000);
				change();
			}
		}

		filterAffectParentHeight($els)
		{
			return $els
				.filter(':visible')
				.filter((i, el) => !['absolute', 'fixed', 'sticky'].includes($(el).css('position')))
			;
		}

	}

	return TableSelectorElements;
});

Sim.require.amd.registerRaw("/app/Intranet/components/TableSelector/TableSelector.js", ["/app/Intranet/components/TableSelector/TableSelectorElements.js","/app/Intranet/components/TableSelector/TableSelectorPaginator.js","/app/Intranet/components/TableSelector/TableSelectorAjaxDataLoader.js","/app/Intranet/components/TableSelector/TableSelectorFullTable.js","/app/Intranet/components/TableSelector/TableSelectorLocalRenderer.js","/app/Intranet/components/TableSelector/TableSelectorLocalTable.js","/app/Intranet/components/TableSelector/TableSelectorScroller.js","/app/Intranet/components/TableSelector/TableSelectorToggler.js","/app/components/AClickableArea/AClickableArea.js","/app/Intranet/components/TableSelector/TableSelectorLocalRendererRows.js","/app/Intranet/components/TableSelector/TableSelectorLocalRendererAnimation.js","/public/libs/bezier-easing/index.js"], 












 (
	TableSelectorElements,
	TableSelectorPaginator,
	TableSelectorAjaxDataLoader,
	TableSelectorFullTable,
	TableSelectorLocalRenderer,
	TableSelectorLocalTable,
	TableSelectorScroller,
	TableSelectorToggler,
	AClickableArea,
) => {

	class TableSelector
	{

		constructor(localTDIndexes, ajax)
		{
			this.elements = new TableSelectorElements;
			this.paginator = new TableSelectorPaginator(this.elements);
			this.ajaxDataLoader = new TableSelectorAjaxDataLoader(ajax, this.elements, this.paginator);
			this.fullTable = new TableSelectorFullTable(this.elements, this.paginator, this.ajaxDataLoader);
			this.localRenderer = new TableSelectorLocalRenderer(localTDIndexes, this.elements, this.paginator);
			this.localTable = new TableSelectorLocalTable(this.elements, this.paginator, this.localRenderer, this.fullTable);
			this.scroller = new TableSelectorScroller(this.elements, this.paginator, this.localTable);
			this.toggler = new TableSelectorToggler(this.elements, this.paginator, this.localTable, this.fullTable);

			new AClickableArea(this.elements.tsTableScroll); // animated table
		}

	}

	return TableSelector;
});

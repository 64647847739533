Sim.require.amd.registerRaw("/app/components/Popups/Popup.js", [], () => {

	const scopedEval = (code, bindThis, args) => {
		const reduce = (memo, key) => `${memo + JSON.stringify(key.replace(/[^\w]/ug, '_'))},`;
		const keysJson = Object.keys(args).reduce(reduce, '');
		// eslint-disable-next-line no-new-func
		const func = Function(`return Function(${keysJson}${JSON.stringify(code)})`)();
		return func.apply(bindThis, Object.values(args));
	};

	class Popup
	{

		static lastId = 0;

		constructor(handler, popupName)
		{
			if (typeof popupName !== 'string' || /^\d$/u.test(popupName) || !/^[\w-]+$/u.test(popupName))
			{
				throw new Error(popupName);
			}
			this.handler = handler;
			this.name = popupName;
			this.id = ++Popup.lastId;
			this.$el = this._createElement();
		}

		onClose(callback)
		{
			this.$el.on('close.SimPopups', callback);
		}

		close()
		{
			return this.handler.stack.close(this);
		}

		activate()
		{
			this.handler.stack.activate(this);
		}

		_createElement()
		{
			return $('<div>', {
				'class': 'modal',
				data: {
					popupId: this.id,
					popupName: this.name,
				},
			});
		}

		updateContent(html, {restoreScroll = undefined} = {})
		{
			const restoreScrollLater = this.restoreScroll(restoreScroll);

			if (_.isFunction(html))
			{
				this.$el.append(html(this));
			}
			else
			{
				this.$el.html(html);
			}

			this.$el.find('*').add(this.$el).contents().each((i, el) => {
				if (el.nodeType === Node.COMMENT_NODE)
				{
					const match = el.nodeValue.match(/^\s*Sim\.Popups\.eval\((".+")\)\s*$/u);
					if (match) // Sim.Popups.eval
					{
						const code = JSON.parse(match[1]);
						scopedEval(code, this, {
							popup: this.$el, // bc
						});
						el.parentNode.removeChild(el);
					}
					else if (/Sim\.Popups\.eval/iu.test(el.nodeValue))
					{
						throw new Error(`Invalid Sim.Popups.eval in '${el.nodeValue}'`);
					}
				}
			});

			Sim.init(this.$el);

			const ajaxSubmitObject = Sim.enableAjaxSubmit(this.$el.find('form'))
				.disableEarlyIfNotUnique()
				.enableEarlyMustIf(($form) => !$form.is('[data-popup-disable-ajax-submit]'))
				.addIsEnabled(() => this.handler.stack.getNumberOfPopups() > 1)
			;
			this.handler.load(ajaxSubmitObject, {
				checkPopupName: this.name,
				reload: true,
			});

			this.$el.on('click', '[data-dismiss="modal"], [data-dismiss="popup"]', () => {
				this.close();
				return false;
			});

			this.$el.off('click.data-reload-popup-content');
			this.$el.on('click.data-reload-popup-content', '[data-reload-popup-content]:not([data-reload-popup-content=""]):not(.loading)', (e) => {
				const link = $(e.target).closest('[data-reload-popup-content]:not([data-reload-popup-content=""])').addClass('loading');
				this.handler.load(link.attr('data-reload-popup-content'), {
					checkPopupName: this.name,
					reload: true,
					restoreScroll: link.attr('data-popup-restore-scroll') || 'down',
				});
			});

			restoreScrollLater();

			const focusEl = $(':focus');
			if (focusEl.length === 0 || !$.contains(this.$el[0], focusEl[0]))
			{
				this.$el.attr('tabindex', '-1').trigger('focus');
			}
		}

		restoreScroll(restoreScroll)
		{
			if (restoreScroll === undefined)
			{
				return () => {};
			}
			const body = this.$el.find('.modal-body');
			const scrollTop = [
				this.$el[0].scrollTop,
				body[0]?.scrollTop || 0,
			];
			const setTo = (scroll) => [
				this.$el[0].scrollTop,
				(this.$el.find('.modal-body')[0] || {}).scrollTop,
			] = scroll;
			if (restoreScroll === 'down')
			{
				return () => { setTo(scrollTop); };
			}
			else if (restoreScroll === 'up')
			{
				const getHeight = ($body) => [
					this.$el[0].getBoundingClientRect().height,
					$body[0]?.getBoundingClientRect().height || 0,
				];
				const setMinHeight = ($body, value) => $body?.find('> :last-child').css('min-height', value);
				setMinHeight(body, '');
				const scrollHeightBefore = getHeight(body);
				return () => {
					const body2 = this.$el.find('.modal-body');
					const scrollHeightAfter = getHeight(body2);
					const scroll = [
						scrollTop[0] + scrollHeightAfter[0] - scrollHeightBefore[0],
						scrollTop[1] + scrollHeightAfter[1] - scrollHeightBefore[1],
					];
					setMinHeight(body2, `${500 + scroll[1]}px`);
					setTo(scroll);
				};
			}
			throw new Error(restoreScroll);
		}

	}

	return Popup;
});

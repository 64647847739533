Sim.require.amd.registerRaw("/app/components/HtmlEditor/Utils.js", [], () => {

	class Utils
	{

		static isGloballyAllowedTag(simditor, tag, attr = null)
		{
			return (
				simditor.formatter._allowedTags.includes(tag) &&
				(attr === null || (simditor.formatter._allowedAttributes[tag] ?? []).includes(attr))
			);
		}

	}

	return Utils;
});


Sim.require.amd.registerRaw("/app/components/UnifiedTableFiltering/Tracker/Base/PaymentTypeFilter.js", ["/app/components/TagTypeahead/TagTypeahead.js"],  function (TagTypeahead) {


	function PaymentTypeFilter(el) {
		TagTypeahead.call(this, el);
		this.th.options.minLength = 0;
		this.th.options.items = 999;
		if (this.el.is(':focus'))
		{
			this.el.trigger('focus');
		}
	}
	PaymentTypeFilter.prototype = _.extend((function (child, parent) {
		var F = function () {}; F.prototype = parent.prototype; var P = new F; P.constructor = child; return P;
	})(PaymentTypeFilter, TagTypeahead), {

		createSource: function (items) {
			const make = (data, parents = []) => {
				const result = [];
				for (const value of Object.values(data))
				{
					const item = {
						key: value.key,
						text: value.label,
						offset: parents.length,
						children: [].concat(parents),
					};
					item.children.push(item);
					result.push(item);
					for (const item2 of make(value.children || [], [].concat(item.children)))
					{
						item.children.push(item2);
						result.push(item2);
					}
				}
				return result;
			};
			return make(items);
		},

		sorter: function (items, matcher) {
			if (this.th.query.length > 0)
			{
				const result = {};
				_.each(items, (item) => {
					for (const item2 of item.children)
					{
						result[item2.key] = item2;
					}
				});
				return _.toArray(result);
			}
			return items;
		},

		highlighter: Sim.extendFunction(TagTypeahead.prototype.highlighter, (old, text, item, matcher) => {
			return $('<span>')
				.html(old())
				.css('padding-left', (item.offset * 13) + 'px')
			;
		}),

	});

	return PaymentTypeFilter;

});

require("../libs/underscore/underscore.js");
require("../libs/jquery/jquery-3.7.1.js");
require("../libs/stacktracejs/source-map-js/source-map.js");
require("../libs/stacktracejs/stackframe/stackframe.js");
require("../libs/stacktracejs/error-stack-parser/error-stack-parser.js");
require("../libs/stacktracejs/stack-generator/stack-generator.js");
require("../libs/stacktracejs/stacktrace.js/stacktrace.js");
require("../js/Functions/escape.js");
require("../js/Functions/require.js");
require("../js/GlobalState.js");
require("../js/IgnoreErrors.js");
require("../js/ErrorReporting.js");
require("../js/jquery-migrate-setup.js");
require("../libs/jquery/jquery-migrate-3.5.2.js");
require("../libs/jquery-ui/jquery-ui.js");
require("../js/Functions.js");
require("../js/Functions/disableSubmit.js");
require("../js/Functions/enableAjaxSubmit.js");
require("../js/Functions/flashMessage.js");
require("../js/Functions/getCurrentScript.js");
require("../js/Functions/init.js");
require("../js/Functions/observeNodes.js");
require("../js/Functions/parseDatePickerValue.js");
require("../js/Functions/redefineFunction.js");
require("../js/Functions/SimpleAMD.js");
require("../js/jquery.nette.js");
require("../libs/jquery.ajaxform.js");
require("../libs/js-cookie/js.cookie.js");
require("../js/Inits/Nette.js");
require("../js/Inits/PrivateText.js");
require("../js/init.js");
require("../js/require.config.js");
require("./require.js");
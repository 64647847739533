Sim.require.amd.registerRaw("/public/js/RestoreScrollContext.js", [], () => {

	class RestoreScrollContext
	{

		constructor({context, snippet = null})
		{
			this.contextSelector = context;
			this.context = this.createContext();

			if (snippet !== null)
			{
				jQuery.nette.addSnippetHandler(snippet, {
					detachWithElement: this.context[0],
					beforeFn: () => this.before(),
					afterFn: () => this.after(),
				});
			}
			else
			{
				throw new Error('unsupported trigger');
			}
		}

		createContext()
		{
			const context = $(this.contextSelector);
			if (context.length !== 1) throw new Error('context not found');
			return context;
		}

		getWindowHeight()
		{
			return window.innerHeight || document.documentElement.clientHeight;
		}

		before()
		{
			this.data = null;
			const els = this.context.find('[data-restore-scroll-context-ref]');
			if (!els.length) throw new Error('no [data-restore-scroll-context-ref] in context');
			const [first, last] = [els.first(), els.last()];
			const rectFirst = first[0].getBoundingClientRect();
			const rectLast = last[0].getBoundingClientRect();
			const windowHeight = this.getWindowHeight();
			if (
				rectLast.bottom < windowHeight ||
				rectFirst.top < windowHeight * 0.5
			)
			{
				this.data = {
					ref: first.attr('data-restore-scroll-context-ref'),
					previousScrollTop: document.scrollingElement.scrollTop,
					previousRectTop: rectFirst.top,
				};
			}
		}

		after()
		{
			if (this.data)
			{
				const {ref, previousScrollTop, previousRectTop} = this.data;
				this.data = null;
				const el = this.createContext().find(`[data-restore-scroll-context-ref="${$.escapeSelector(ref)}"]`);
				if (!el.length) throw new Error(`no [data-restore-scroll-context-ref=${ref}] in new context`);
				const {scrollTop} = document.scrollingElement;
				const rect = el[0].getBoundingClientRect();
				document.scrollingElement.scrollTop = scrollTop + rect.top - previousRectTop + scrollTop - previousScrollTop;
			}
		}

	}

	return RestoreScrollContext;
});

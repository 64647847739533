Sim.require.amd.registerRaw("/app/Intranet/components/HellForms/base/DifferentAmountFromExpectedWarning.js", ["/public/js/Helpers/ElementFinder.js","/public/js/Nette.js"],  (ElementFinder, Nette) => {

	class DifferentAmountFromExpectedWarning
	{

		constructor(options)
		{
			const amountElement = ElementFinder.$one(options.amountElement);
			const alertElement = ElementFinder.$one(options.alertElement);
			const directionElement = ElementFinder.$one(options.directionElement, false);

			const change = () => {
				let amount = parseFloat(Nette.getValue(amountElement[0]));
				if (directionElement.val() === '-1')
				{
					amount *= -1;
				}
				alertElement.toggleClass('m-controlGroup__alert--hidden', Number.isNaN(amount) || amount === options.expected);
			};
			const changeDebounce = _.debounce(change, 100);
			amountElement.on('change input', changeDebounce);
			directionElement.on('change', changeDebounce);
			change();
		}

	}

	return DifferentAmountFromExpectedWarning;
});

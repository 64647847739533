(() => {

	const Sim = window.Sim || (window.Sim = {});

	const document = window.document;
	let jQueryHackDOMEvalNode;

	/*
	 * jQuery hack!
	 * domManip()/DOMEval() clone original node script, and inserts copy into document.head.
	 * This mean document.currentScript points to this copy.
	 * `jQuery.contains( doc, node )` is called in domManip() before DOMEval() is called,
	 * so this is only way to capture `node` for DOMEval script without modifying jquery code directly
	 */
	const $contains = $.contains;
	$.contains = (a, b) => {
		if ($contains(a, b))
		{
			if (a === document && b?.tagName === 'SCRIPT')
			{
				jQueryHackDOMEvalNode = b;
			}
			return true;
		}
		return false;
	};

	Sim.getCurrentScript = () => {
		let currentScript = document.currentScript;
		if (currentScript && !currentScript.src && currentScript.parentNode?.tagName === 'HEAD')
		{
			if (currentScript.textContent === jQueryHackDOMEvalNode?.textContent)
			{
				currentScript = jQueryHackDOMEvalNode;
			}
			else
			{
				Sim.triggerWarning('document.currentScript may not be accurate at this context');
			}
		}
		if (!currentScript)
		{
			throw new Error('document.currentScript not available at this context');
		}
		return currentScript;
	};

})();

Sim.require.amd.registerRaw("/app/Intranet/Banks/forms/BankConnectionAccountControls.js", ["/public/js/Nette.js"],  (Nette) => {

	class BankConnectionAccountControls
	{

		constructor(containerSel)
		{
			const container = $(containerSel);
			if (container.length !== 1) throw new Error;

			container.on('click', 'button.js-buttonsAction[data-action]', (e) => {
				const button = $(e.currentTarget);
				button
					.closest('.o-bankConnectionFormAccounts__connectionButtons')
					.find('select[name$="[buttonsAction]"]')
					.val(button.attr('data-action'))
					.trigger('change')
				;
				this.#recalculateSummary(container);
			});
		}

		#recalculateSummary(container)
		{
			const numberOfConnected = container.find('select[name$="[buttonsAction]"]').filter((i, el) => el.value === 'none').length; // BTN_ACTION_NONE
			const numberOfConnectedElement = container.find('.js-numberOfConnected[data-text]');
			numberOfConnectedElement.text((numberOfConnectedElement.attr('data-text') || '').replace('%numberOfConnected%', numberOfConnected));
		}

	}

	return BankConnectionAccountControls;
});

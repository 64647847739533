Sim.require.amd.registerRaw("/app/components/Popups/PopupsStack.js", ["/app/components/Popups/Popup.js"],  (Popup) => {

	class PopupsStack
	{

		constructor(handler)
		{
			this.handler = handler;
			this.popups = new Set; // list of existing popups
			this.backdrop = $('<div class="modal-backdrop"></div>');
			this.onEscapePress = Sim.onEscapePress(() => this.close());
		}

		activate(popup)
		{
			if (popup.$el.data('popupId') !== popup.id)
			{
				throw new Error("Popup was closed, can't be activated again because internal data and events were deleted.");
			}
			this.popups.delete(popup); // reorder
			this.popups.add(popup);
			this._renderStack();
		}

		close(popup = Array.from(this.popups).pop())
		{
			if (!popup || !this.popups.has(popup))
			{
				return false;
			}
			const event = $.Event('close', {namespace: 'SimPopups'});
			popup.$el.triggerHandler(event);
			if (event.isDefaultPrevented())
			{
				return;
			}
			popup.$el.remove(); // to clear data
			this.popups.delete(popup);
			this._renderStack();
			return true;
		}

		add(popupName, html)
		{
			const popup = new Popup(this.handler, popupName);
			this.activate(popup);
			popup.updateContent(html);
			return popup;
		}

		getNumberOfPopups()
		{
			return this.popups.size;
		}

		query(where, need = true)
		{
			if (_.isArray(where))
			{
				return [].concat(...where.map((w) => this.query(w, need)));
			}
			if (where instanceof Popup)
			{
				return [where];
			}
			if (typeof where === 'number' || (typeof where === 'string' && /^\d$/u.test(where)))
			{
				where = {id: where};
			}
			else if (typeof where === 'string')
			{
				where = {name: where};
			}
			const keys = _.keys(where);
			if (!_.difference(keys, ['id', 'name']).length && keys.length && $.isPlainObject(where))
			{
				const res = _.where(Array.from(this.popups), where);
				if (res.length || !need)
				{
					return res;
				}
			}
			throw new Error(`No popups found for query (or invalid query): ${JSON.stringify(where)}`);
		}

		_renderStack()
		{
			let cursor;
			for (const popup of this.popups)
			{
				if (cursor)
				{
					cursor.$el.addClass('popupBackground');
					popup.$el.insertAfter(cursor.$el);
				}
				else
				{
					popup.$el.appendTo(document.body);
				}
				cursor = popup;
			}
			if (cursor)
			{
				cursor.$el.removeClass('popupBackground');
				this.backdrop.insertBefore(cursor.$el);
				this.onEscapePress.on();
			}
			else
			{
				this.backdrop.detach();
				this.onEscapePress.off();
			}
		}

	}

	return PopupsStack;
});

Sim.require.amd.registerRaw("/app/components/FormControls/FilesUploadWithGrid/FilesUploadWithGrid.js", ["/app/components/FormControls/FilesUploadControl/FilesUpload.js","/public/js/Gallery.js","/app/components/Popups/PopupParameters.js"], 



 (
	FilesUpload,
	Gallery,
	PopupParameters,
) => {

	var FilesUploadWithGrid = function (root, template) {
		FilesUpload.call(this, root);
		this.items = this.root.find('.items');
		this.actions = this.root.find('.actions');
		this.template = template;
		if (this.actions.length)
		{
			this.initActionsEvents();
		}
		this.initMagnificEvents();
	};

	FilesUploadWithGrid.prototype = _.extend((function (child, parent) {
		var F = function () {}; F.prototype = parent.prototype; var P = new F; P.constructor = child; return P;
	})(FilesUploadWithGrid, FilesUpload), {

		createFile: function (file) {
			var el = $(this.template);
			el.attr('data-id', file.id);
			el.data('file', file);
			el.find('a').attr('href', file.url);
			el.find('a img').attr('src', file.thumbnail);
			const extIndex = file.name.lastIndexOf('.');
			el.find('a span.name').text(extIndex !== -1 ? file.name.substring(0, extIndex) : file.name);
			el.find('a span.ext').text(extIndex !== -1 ? file.name.substring(extIndex) : '');
			el.find('input[type="hidden"]').attr('value', file.id).attr('name', this.htmlNameIds);
			return el;
		},

		addFileEl: function (el) {
			this.items.append(el);
		},

		initMagnificEvents: function () {
			new Gallery(this.items, '.item.photo > a');
		},

		initActionsEvents: function () {
			var self = this;

			this.items.on('change', 'input:checkbox', _.bind(this.updateIfSelected, this));
			this.updateIfSelected();
			this.actions.find('a.selectAll').on('click', function (e) {
				e.preventDefault();
				self.selectAll();
			});
			this.actions.find('a.selectNone').on('click', function (e) {
				e.preventDefault();
				self.selectNone();
			});

			this.items.sortable({
				items: '> .item',
				containment: 'parent',
				cursor: 'move',
				distance: 5,
				tolerance: 'pointer',
				helper: function () {
					return $('<div>')[0];
				},
				start: function (event, ui) {
					ui.placeholder.append(ui.item.clone().contents());
				},
				stop: function (event, ui) {
					$('body').css('cursor', ''); // jquery ui sortable bug
				},
				update: function () {
					self.save();
				},
			});

			this.actions.find('a.delete').on('click', function (e) {
				e.preventDefault();
				if (confirm($(this).data('txt-confirm')))
				{
					self.getSelected().each(function () {
						self.deleteFile(this.getAttribute('data-id'));
					});
					self.updateIfSelected();
					self.save();
				}
			});

			this.actions.find('a.downloadLink[data-href][data-parameter-files]').on('click', function () {
				var url = this.getAttribute('data-href');
				var parameterFiles = this.getAttribute('data-parameter-files') + '[]';
				var params = _.map(self.getSelectedIds(), function (id) {
					return {name: parameterFiles, value: id};
				});
				this.href = url + (url.indexOf('?') === -1 ? '?' : '&') + $.param(params);
			});

			this.actions.find('a.move').on('click', function () {
				PopupParameters.modifyOnElement($(this), {'files': self.getSelectedIds()});
			});

		},

		getIds: function () {
			return _.map(this.items.find('input[type="hidden"]'), function (el) {
				return el.value;
			});
		},

		getSelected: function () {
			return this.items.find('input:checkbox:checked').closest('.item');
		},

		getSelectedIds: function () {
			return _.map(this.getSelected(), function (el) {
				return el.getAttribute('data-id');
			});
		},

		selectAll: function () {
			this.items.find('input:checkbox').prop('checked', true);
			this.updateIfSelected();
		},

		selectNone: function () {
			this.items.find('input:checkbox').prop('checked', false);
			this.updateIfSelected();
		},

		updateIfSelected: function () {
			var all = this.items.find('input:checkbox');
			var selected = all.filter(':checked');
			all.closest('.item').removeClass('selected');
			selected.closest('.item').addClass('selected');
			var numberOfItems = all.length;
			var numberOfSelectedItems = selected.length;

			var type = '';
			if (numberOfItems > 0)
			{
				type = 'none';
				if (numberOfSelectedItems === 1)
				{
					type = 'one';
				}
				else if (numberOfSelectedItems === numberOfItems)
				{
					type = 'all';
				}
				else if (numberOfSelectedItems > 0)
				{
					type = 'some';
				}
			}
			this.actions.attr('data-isselected', type);
			this.actions.find('.numberOfSelectedItems').text(numberOfSelectedItems);
		},

	});

	return FilesUploadWithGrid;
});

(() => {

	/**
	 * Module loader with full AMD and limited ES6 support.
	 * AMD documentation here https://github.com/amdjs/amdjs-api/blob/master/README.md
	 *
	 * ES6 modules support is experimental, many caveats:
	 * Module can be loaded only if `.mjs` extension is used.
	 * Cache busting with `config.urlArgs` is disabled for modules.
	 * Module can return anything via export and while it can use import, it can't import non-module this way,
	 * but it can use `await Sim.require.promise()` to load those.
	 * If export is not possible without dependencies it is also possible to export as AMD via define:
	 * <code>
	 *	define(import.meta.url, [...], (...) => { return ...; );
	 * </code>
	 * Automatic relative resolve is not always possible inside ES6, so you may need to resolve it manually:
	 * <code>
	 *	Sim.require.promise(Sim.require.amd.resolve('./foo', import.meta.url));
	 *	Sim.require.amd.enter(import.meta.url, () => { Sim.require.get('./foo'); });
	 * </code>
	 */
	class SimpleAMD
	{

		#config;
		#registry = new Map;
		#current;
		#events = this.#createEvents();

		constructor(config = {})
		{
			this.#config = {
				waitSeconds: 30,
				urlBase: '',
				urlArgs: '',
				maps: {},
				warnOnLoad: false,
				...config,
			};
			let urlBase = this.#config.urlBase;
			const origin = window?.location?.origin;
			urlBase = origin !== undefined ? (new URL(urlBase, origin)).href : urlBase;
			this.#config.urlBase = urlBase.replace(/\/+$/u, '');
		}

		/**
		 * Load single module and return it or promise of it.
		 * Undefined is possible only if canLoad=false, then function will return module only if it was already loaded.
		 * Module name is relative to {@see getCurrentContext}.
		 * @param {string} name
		 * @param {boolean} canLoad
		 * @returns {Promise<*>|*|undefined}
		 */
		get(name, canLoad = true)
		{
			const id = this.resolve(name, this.getCurrentContext());
			const load = this.#registry.get(id);
			if (load && load.module !== undefined)
			{
				if (!canLoad && load.module instanceof Promise) return undefined;
				return load.module;
			}
			if (!canLoad) return undefined;
			return Promise.resolve(this.#getLoadPromise(id))
				.then((load2) => this.#getLoadModule(load2))
			;
		}

		/**
		 * Load multiple modules and return array in same order as deps or promise of array.
		 * Undefined is possible only if canLoad=false, then function will return modules only if they were already loaded.
		 * Module names are relative to {@see getCurrentContext}.
		 * @param {string[]} deps names
		 * @param {boolean} canLoad
		 * @returns {Promise<*[]>|(*|undefined)[]}
		 */
		getAll(deps, canLoad = true)
		{
			const mods = [];
			let hasPromises = false;
			for (const dep of deps)
			{
				const mod = this.get(dep, canLoad);
				if (mod instanceof Promise)
				{
					hasPromises = true;
				}
				mods.push(mod);
			}
			return hasPromises ? Promise.all(mods) : mods;
		}

		/**
		 * Normalise module name to its canonical id, optionally relative to other module id.
		 * @see getCurrentContext
		 * Inside of ES6 module you may need to use `import.meta.url` instead of getCurrentContext.
		 * @param {string} id name or id
		 * @param {?string} parent module name or id
		 * @returns {string} canonical module id
		 */
		resolve(id, parent = null)
		{
			if (id === undefined)
			{
				throw new Error("Can't resolve without providing name.");
			}
			if (id.includes('\\'))
			{
				id = id.replace(/\\/gu, '/');
			}
			if (id.startsWith(`${this.#config.urlBase}/`))
			{
				id = id.substr(this.#config.urlBase.length + 1).split('#')[0].split('?')[0];
			}
			const splits = id.split('/');
			const first = splits.shift();
			let last = splits.pop();
			const resolved = [];
			if (first !== '' && !first.includes(':')) // relative
			{
				if (first === '.' || first === '..')
				{
					if (parent === null)
					{
						throw new Error(`Can't resolve relative '${id}' without parent to absolute modId.`);
					}
					resolved.push(...this.resolve(parent).split('/').slice(0, -1));
					splits.unshift(first);
				}
				else if (this.#config.maps[first])
				{
					resolved.push(...this.#config.maps[first].split('/'));
				}
				else
				{
					resolved.push(...this.#config.maps[''].split('/'), first);
				}
			}
			else // absolute or url
			{
				resolved.push(first);
				if (splits[0] === '') // e.g. https://
				{
					resolved.push('');
					splits.shift();
				}
			}
			for (const part of splits)
			{
				if (part === '..')
				{
					resolved.pop();
				}
				else if (part !== '.' && part !== '')
				{
					resolved.push(part);
				}
			}
			last = last === undefined ? resolved.pop() : last;
			if (first.includes(':') || last.includes('?'))
			{
				resolved.push(last);
			}
			else if (last === '' || last === '.' || last === '..')
			{
				if (last === '..')
				{
					resolved.pop();
				}
				resolved.push('index.js');
			}
			else if (!last.includes('.') || last.substr(last.lastIndexOf('.')).length > 10)
			{
				resolved.push(`${last}.js`);
			}
			else
			{
				resolved.push(last);
			}
			const modId = resolved.join('/');
			if (!modId.startsWith('/') && !first.includes(':'))
			{
				throw new Error(`Can't resolve '${id}' from '${parent}' to absolute modId (got '${modId}').`);
			}
			return modId;
		}

		#getOrCreateLoad(id)
		{
			let load;
			if (this.#registry.has(id))
			{
				load = this.#registry.get(id);
			}
			else
			{
				this.#registry.set(id, load = Object.seal({
					id,
					instantiatePromise: undefined, // Promise
					registererPromise: undefined, // Promise
					resolveRegistererPromise: null, // Function
					rawDependencies: undefined, // {[array, string|null]}
					dependencies: undefined, // Promise|Array
					insideFactories: undefined,
					factory: undefined, // Promise|Function
					module: undefined, // Promise|object
				}));
			}
			return load;
		}

		#getLoadPromise(id)
		{
			const load = this.#getOrCreateLoad(id);
			if (Array.isArray(load.dependencies))
			{
				return load;
			}
			return Promise.resolve()
				.then(() => this.#getLoadRegisterer(load))
				.then(() => this.#getLoadDependencies(load))
				.then(() => load)
			;
		}

		#toUrl(id, includeUrlArgs = true)
		{
			if (/^\/[^/]/u.test(id))
			{
				let urlArgs = '';
				if (includeUrlArgs && this.#config.urlArgs && !/\.mjs$/u.test(id))
				{
					urlArgs = `${id.includes('?') ? '&' : '?'}${this.#config.urlArgs}`;
				}
				return `${this.#config.urlBase}${id}${urlArgs}`;
			}
			return id;
		}

		#createScript(id)
		{
			const script = document.createElement('script');
			script.async = true;
			if (/\.mjs$/u.test(id))
			{
				script.type = 'module';
			}
			script['data-amd'] = id;
			script.src = this.#toUrl(id);
			return script;
		}

		#createInstantiatePromise(id)
		{
			if (!this.#createInstantiatePromise.hasErrorEvent)
			{
				window.addEventListener('error', (event) => {
					this.#createInstantiatePromise.lastWindowErrorUrl = event.filename;
					this.#createInstantiatePromise.lastWindowError = event.error;
				});
				this.#createInstantiatePromise.hasErrorEvent = true;
			}
			const script = this.#createScript(id);
			return new Promise((resolve, reject) => {
				script.addEventListener('error', () => {
					reject(new Error(`Error loading ${id} from ${script.src}`));
				});
				script.addEventListener('load', () => {
					document.head.removeChild(script);
					if (this.#createInstantiatePromise.lastWindowErrorUrl === script.src)
					{
						reject(this.#createInstantiatePromise.lastWindowError);
					}
					else
					{
						resolve(script.type === 'module' ? script.src : null);
					}
				});
				document.head.appendChild(script);
			});
		}

		#getLoadRegisterer(load)
		{
			if (load.registererPromise === undefined && load.rawDependencies === undefined)
			{
				if (this.#config.warnOnLoad)
				{
					Sim.triggerWarning(`Module ${load.id} is being manually loaded but it is expected to be bundled.`);
				}
				load.registererPromise = new Promise((resolve, reject) => {
					const timeout = setTimeout(
						() => reject(new Error(`Timed out loading of ${load.id} from ${this.#toUrl(load.id)}`)),
						this.#config.waitSeconds * 1000,
					);
					if (load.instantiatePromise === undefined)
					{
						load.instantiatePromise = this.#createInstantiatePromise(load.id);
					}
					load.instantiatePromise.then((isModule) => {
						if (isModule !== null)
						{
							if (load.factory !== undefined)
							{
								return; // register called
							}
							const registerCode = `Sim.require.amd.register(${JSON.stringify(load.id)}, [], () => m)`;
							try
							{
								eval(`import(isModule).then((m) => ${registerCode}).catch(() => {})`); // eslint-disable-line no-eval
							}
							catch (e) // dynamic import() not supported
							{
								console.error(e); // eslint-disable-line no-console
								const script = document.createElement('script');
								script.type = 'module';
								script.textContent = `import * as m from ${JSON.stringify(isModule)}; ${registerCode}`;
								document.head.appendChild(script);
							}
						}
					}).catch(reject);
					load.resolveRegistererPromise = () => {
						clearTimeout(timeout);
						load.instantiatePromise.then(() => {
							resolve();
							load.instantiatePromise = undefined;
						});
						load.resolveRegistererPromise = null;
					};
				});
			}
			return load.registererPromise;
		}

		/**
		 * Execute function like if inside of module context.
		 * @see getCurrentContext
		 * @param {string} current canonical module id
		 * @param {function():*} fn
		 * @returns {*}
		 */
		enter(current, fn)
		{
			const previous = this.#current;
			this.#current = current;
			try
			{
				return fn();
			}
			finally
			{
				this.#current = previous;
			}
		}

		/**
		 * This is parent all other modules are resolved to if requested from inside of module context.
		 * Inside of ES6 module you may need to use `import.meta.url`.
		 * @see resolve
		 * @see enter
		 * @returns {(string|undefined)} canonical module id
		 */
		getCurrentContext()
		{
			if (this.#current !== undefined)
			{
				return this.#current;
			}
			return window?.document?.currentScript?.['data-amd'];
		}

		/**
		 * Define module.
		 * Can do via function returning module, or promise, or function returning promise, or directly passing the module object.
		 * Non-object module must be wrapped in function/promise. `undefined` is only not allowed module type.
		 * All dependencies are loaded first, and passed into function in order they are in dependencies.
		 *
		 * Module name and dependencies are relative to {@see getCurrentContext}. Name can be null to use module context id.
		 *
		 * In addition function can accept `require, exports, module` arguments as it's last arguments,
		 * or in different order by specifying it in dependencies.
		 * Instead of returning module, it is possible to define module via module.exports or directly set members on exports or `this`.
		 * For function `this` is module.exports
		 * This is for maximum AMD compatibility and returning module is preferred.
		 * - require is AMD require function
		 * - exports is default module object
		 * - module is simple object with module.exports that can be changed to different object.
		 *
		 * @param {?string} name
		 * @param {string[]} dependencies names
		 * @param {(function(this:{}, ...*, (function(string):*|function(string[], function(...*))), {exports: {}}, {}):(*|Promise<*>)|Promise<*>|object)} factory
		 * @returns {void}
		 */
		register(name, dependencies, factory)
		{
			const current = this.getCurrentContext();
			const id = this.resolve(name = name ?? current, current);
			const load = name !== undefined ? this.#getOrCreateLoad(id) : this.#registry.get(id);
			if (!load)
			{
				throw new Error(`Unexpected register for ${id} called.`);
			}
			if (load.factory !== undefined || Object.isFrozen(load))
			{
				throw new Error(`Multiple registers of ${id} called.`);
			}
			load.rawDependencies = [dependencies ?? [], current];
			load.factory = factory ?? null;
			load.resolveRegistererPromise && load.resolveRegistererPromise();
		}

		/**
		 * This is low level version of {@see register}. It is for bundling all modules into single file.
		 * It assumes id and dependencies are already in canonical module ids.
		 * It skips few checks, and should not be used directly.
		 * @param {string} id
		 * @param {string[]} dependencies
		 * @param {(function(this:{}, ...*):(*|Promise<*>)|Promise<*>|object)} factory
		 * @returns {void}
		 */
		registerRaw(id, dependencies, factory, allowOverrideRegisterInside)
		{
			if (this.#registry.has(id))
			{
				const load = this.#registry.get(id);
				if (load.allowInsideFactories && load.insideFactories)
				{
					load.insideFactories.push([dependencies ?? [], factory ?? null]);
					return;
				}
				throw new Error(`Multiple registers of ${id} called.`);
			}
			this.#registry.set(id, Object.seal({
				id,
				dependencies: dependencies ?? [],
				factory: factory ?? null,
				allowInsideFactories: !!allowOverrideRegisterInside,
				insideFactories: null,
				module: undefined,
			}));
		}

		#getLoadDependencies(load)
		{
			if (load.dependencies === undefined)
			{
				const promises = [];
				const dependencies = [];
				for (const dep of load.rawDependencies[0])
				{
					if (dep === 'require' || dep === 'exports' || dep === 'module')
					{
						dependencies.push(dep);
						continue;
					}
					const depId = this.resolve(dep, load.rawDependencies[1]);
					const loadPromise = this.#getLoadPromise(depId);
					if (loadPromise instanceof Promise)
					{
						promises.push(loadPromise);
					}
					dependencies.push(depId);
				}
				load.dependencies = promises.length === 0 ? dependencies : Promise.all(promises).then(() => load.dependencies = dependencies);
			}
			return load.dependencies;
		}

		#thenOrNow(maybePromise, fn)
		{
			if (maybePromise instanceof Promise)
			{
				return maybePromise.then(fn).catch((err) => { throw err; });
			}
			return fn(maybePromise);
		}

		#getLoadModule(load, deep = [])
		{
			if (deep.length > 1337)
			{
				throw new Error(`Recursive dependencies of '${_.unique(deep).join("' -> '")}'`);
			}
			if (load.module === undefined)
			{
				const originalModuleExports = {};
				const module = {exports: originalModuleExports};
				load.insideFactories = [];
				const result = this.#executeFactory(load.id, load.factory, load.dependencies, module, deep);
				load.module = this.#thenOrNow(result, (factoryResult) => {
					module.exports = factoryResult === undefined ? module.exports : factoryResult;

					const chain = () => {
						if (load.insideFactories.length)
						{
							const [deps, fn] = load.insideFactories.shift();
							return this.#thenOrNow(
								this.#executeFactory(load.id, fn, deps, module, deep),
								(res) => {
									module.exports = res === undefined ? module.exports : res;
									return chain();
								}
							);
						}
					};

					return this.#thenOrNow(chain(), () => {
						load.insideFactories = undefined;
						this.#events.trigger(load.id, 'load:after', module);
						if (module.exports === undefined)
						{
							throw new Error(`Module ${load.id} can't be undefined, try null.`);
						}
						if (factoryResult === undefined && module.exports === originalModuleExports && Object.keys(module.exports).length === 0)
						{
							Sim.triggerWarning(`Module ${load.id} can't be undefined, and no functions added to module.exports, maybe explicitly return null.`);
						}
						return module.exports;
					});
				});
				this.#thenOrNow(load.module, (factoryResult) => {
					if (factoryResult === undefined)
					{
						throw new Error(`Module ${load.id} can't be undefined, try null.`);
					}
					load.factory = undefined;
					load.module = factoryResult;
					Object.freeze(load);
				});
			}
			return load.module;
		}

		static #isProbablyClass(func)
		{
			if (typeof func !== 'function' || !func.prototype) return false;
			return (
				Object.getPrototypeOf(func) !== Function.prototype || // extends
				Object.getOwnPropertyNames(func.prototype).length > 1 || // other than constructor, will not detect classes without any public member
				String(func.prototype.constructor).startsWith('class') // may not work for minimized classes
			);
		}

		#executeFactory(id, factory, dependencyIds, module, deep)
		{
			if (typeof factory !== 'function' || SimpleAMD.#isProbablyClass(factory))
			{
				if (typeof factory === 'object' && factory !== null) // Promise, object, array, ..., but not classes because they can't be reliably detected so error better
				{
					factory = _.constant(factory);
				}
				else
				{
					const type = typeof factory === 'function' ? 'class' : typeof factory;
					const snippet = String(factory).substr(0, 100);
					throw new Error(`Invalid factory for ${id}. Maybe wrap it in function. ${type}: ${snippet}`);
				}
			}
			if (!Array.isArray(dependencyIds))
			{
				throw new Error(`#getLoadModule for ${id} called at wrong time, dependencies are not ready.`);
			}
			if (factory.length > dependencyIds.length)
			{
				dependencyIds.push('require', 'exports', 'module');
			}
			const dependencies = [];
			const promises = [];
			for (const depId of dependencyIds)
			{
				const index = dependencies.length;
				if (depId === id)
				{
					throw new Error(`Recursive dependencies of ${depId}`);
				}
				if (depId === 'require')
				{
					dependencies[index] = window.require;
				}
				else if (depId === 'exports')
				{
					dependencies[index] = module.exports;
				}
				else if (depId === 'module')
				{
					dependencies[index] = module;
				}
				else
				{
					const depMod = this.#thenOrNow(this.#getLoadPromise(depId), (depLoad) => this.#getLoadModule(depLoad, deep.concat(id)));
					if (depMod instanceof Promise)
					{
						promises.push(depMod.then((depMod2) => { dependencies[index] = depMod2; }));
						dependencies[index] = undefined;
					}
					else
					{
						dependencies[index] = depMod;
					}
				}
			}
			return this.#thenOrNow(
				promises.length === 0 ? [] : Promise.all(promises),
				() => this.enter(id, () => factory.apply(module.exports, dependencies))
			);
		}

		/**
		 * This is internal function to create global AMD define and require functions.
		 * This gives maximum AMD compatibility.
		 * @return {[(function(string=, string[]=, (function(this:{}, ...*):*)|*)), (function(string):*|function(string[], function(...*)))]}
		 */
		createAmd()
		{
			const define = (name, deps, callback) => {
				if (!callback && typeof name !== 'string')
				{
					callback = deps;
					deps = name;
					name = null;
				}
				if (!callback && (typeof deps === 'function' || (typeof deps === 'object' && !Array.isArray(deps))))
				{
					callback = deps;
					deps = [];
				}
				if (
					(name !== null && typeof name !== 'string') ||
					!Array.isArray(deps) ||
					(typeof callback !== 'function' && typeof callback !== 'object')
				)
				{
					throw new Error(`Invalid call to AMD define(). name=${typeof name}, deps=${typeof deps}, callback=${typeof callback}`);
				}
				this.register(name, deps, callback);
			};
			define.amd = {};

			const require = (deps, callback, onError) => {
				if (Array.isArray(deps))
				{
					onError = onError || ((err) => { throw err; });
					const mods = this.getAll(deps);
					if (mods instanceof Promise)
					{
						mods.then((a) => (callback && callback(...a))).catch(onError);
					}
					else if (callback)
					{
						try { callback(...mods); } catch (err) { onError(err); }
					}
					return require;
				}
				else if (typeof deps === 'string' && !callback && !onError)
				{
					const mod = this.get(deps, false);
					if (mod === undefined)
					{
						throw new Error(
							`Module name '${deps}' has not been loaded yet, ` +
							'you probably wanted to use `require([...], ...)` or put module into dependencies.'
						);
					}
					return mod;
				}
				throw new Error('Invalid call to AMD require(). This is not requirejs.');
			};
			require.toUrl = (id) => this.#toUrl(this.resolve(id));

			return [define, require];
		}

		/**
		 * Register event that trigger at various stages around modules.
		 * @param {(string|string[])} name module name relative to {@see getCurrentContext}
		 * @param {(string|string[])} event See {@see #createEvents} for currently supported events.
		 * @param {function(...*, string, string):void} fn each event has different arguments, but id and event is always provided as last two
		 * @returns {void}
		 */
		addListener(name, event, fn)
		{
			for (const n of typeof name === 'string' ? [name] : name)
			{
				for (const e of typeof event === 'string' ? [event] : event)
				{
					const id = this.resolve(n, this.getCurrentContext());
					this.#events.on(id, e, fn);
				}
			}
		}

		#createEvents()
		{
			const supported = {
				'load:after': (id, event) => {
					if (this.#registry.get(id)?.module !== undefined)
					{
						throw new Error(`Module ${id} is already loaded, can't register event ${event}.`);
					}
				},
			};
			const events = new Map;
			return {
				trigger: (id, event, ...args) => {
					const set = events.get(id)?.get(event);
					if (set !== undefined)
					{
						for (const fn of set)
						{
							fn(...args, id, event);
						}
					}
				},
				on: (id, event, fn) => {
					let map = events.get(id);
					if (map === undefined)
					{
						events.set(id, map = new Map);
					}
					let set = map.get(event);
					if (set === undefined)
					{
						if (!(event in supported))
						{
							throw new Error(`Event ${event} is not one of known events.`);
						}
						map.set(event, set = new Set);
					}
					supported[event](id, event);
					set.add(fn);
				},
			};
		}

	}

	window.Sim.require.SimpleAMD = SimpleAMD;
})();

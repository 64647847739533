Sim.require.amd.registerRaw("/public/js/Helpers/Scrolling.js", [], () => {

	class Scrolling
	{

		static scrollIntoViewIfNeeded(el, center = false)
		{
			if ('scrollIntoViewIfNeeded' in Element.prototype)
			{
				el.scrollIntoViewIfNeeded(Boolean(center)); // chrome only
			}
			else
			{
				el.scrollIntoView({block: center ? 'center' : 'start'});
			}
		}

		static isStartMostlyInViewport(el, atLeastPercent = 0.75, offsetAtMostPercent = 0)
		{
			const rect = el.getBoundingClientRect();
			return (
				rect.bottom > 0 &&
				rect.right > 0 &&
				(rect.top + (rect.height * atLeastPercent)) < (window.innerHeight || document.documentElement.clientHeight) &&
				(rect.left + (rect.width * atLeastPercent)) < (window.innerWidth || document.documentElement.clientWidth) &&
				(rect.top + (rect.height * offsetAtMostPercent)) >= 0 &&
				(rect.left + (rect.width * offsetAtMostPercent)) >= 0
			);
		}

	}

	return Scrolling;
});


Sim.require.amd.registerRaw("/app/components/UnifiedTableFiltering/Tracker/Base/DateFilter.js", [], () => {

	function DateFilter(parameters) {
		var container = $(parameters.container);
		this.ajax = parameters.ajax;

		this.rangeType = container.find('select[name$="[rangeType]"]');
		this.rangeMultiplier = container.find('input[name$="[rangeMultiplier]"]');
		this.rangePeriod = container.find('select[name$="[rangePeriod]"]');

		this.datesAffected = container.find('.dates-affected-container');
		this.datesAffected.textMin = this.datesAffected.find('.min .date-text');
		this.datesAffected.textMax = this.datesAffected.find('.max .date-text');
		this.datesAffected.inputMin = container.find('input[name$="[dateMin]"]');
		this.datesAffected.inputMax = container.find('input[name$="[dateMax]"]');

		this.rangeType.on('change', () => this.resetRangeMultiplier());

		// affected dates to custom
		this.datesAffected.find('a.edit').on('click', (e) => this.changeAffectedDates(e));

		this.affectedDates = {};
		this.initAffectedDates();
		var debounceCalculate = _.debounce(this.calculateAffectedDates.bind(this), 100);
		this.rangeType.on('change', debounceCalculate);
		this.rangePeriod.on('change', debounceCalculate);
		this.rangeMultiplier.on('change keyup paste cut input', debounceCalculate);
	}

	DateFilter.prototype = {

		resetRangeMultiplier: function () {
			if (['next', 'last'].indexOf(this.rangeType.val()) === -1)
			{
				this.rangeMultiplier.val(1);
			}
		},

		changeAffectedDates: function (event) {
			// set dates from affected and switch to custom mode
			this.datesAffected.inputMin.val(this.datesAffected.textMin.text());
			this.datesAffected.inputMax.val(this.datesAffected.textMax.text());
			this.rangeType.val('custom').trigger('change');
			let input = $(event.target.closest('a')).hasClass('min') ? 'inputMin' : 'inputMax';
			this.datesAffected[input].trigger('focus');
		},

		calculateAffectedDates: function () {
			if (this.lastAjax !== undefined)
			{
				this.lastAjax.abort();
			}

			this.datesAffected.hide();

			const {cacheKey, rangeType, rangeMultiplier, rangePeriod} = this.getCacheParameters();
			if (cacheKey)
			{
				if (!this.affectedDates[cacheKey])
				{
					this.lastAjax = $.ajax({
						url: this.ajax.url,
						method: 'POST',
						data: {
							[this.ajax.rangeType]: rangeType,
							[this.ajax.rangePeriod]: rangePeriod,
							[this.ajax.rangeMultiplier]: rangeMultiplier,
						},
						dataType: 'json',
						success: function (data) {
							this.affectedDates[cacheKey] = [
								data[0] ? new Date(data[0]) : null,
								data[1] ? new Date(data[1]) : null,
							];
							this.showAffectedDates(this.affectedDates[cacheKey]);
						}.bind(this),
						complete: function () {
							this.lastAjax = undefined;
						}.bind(this),
						global: false, // ignore Sim.loading
					});
				}
				else
				{
					this.showAffectedDates(this.affectedDates[cacheKey]);
				}
			}
		},

		showAffectedDates: function (dates) {
			this.datesAffected.textMin.text(dates[0] ? Sim.formatDate(dates[0]) : '');
			this.datesAffected.textMax.text(dates[1] ? Sim.formatDate(dates[1]) : '');
			this.datesAffected.show();
		},

		initAffectedDates: function () {
			const {cacheKey} = this.getCacheParameters();
			const minDate = this.datesAffected.textMin.attr('data-date');
			const maxDate = this.datesAffected.textMax.attr('data-date');
			this.datesAffected.textMin.removeAttr('data-date');
			this.datesAffected.textMax.removeAttr('data-date');
			if (cacheKey && minDate && maxDate)
			{
				this.affectedDates[cacheKey] = [
					new Date(minDate),
					new Date(maxDate),
				];
			}
			else
			{
				this.datesAffected.hide();
			}
		},

		getCacheParameters() {
			const rangeType = this.rangeType.val();
			const rangeMultiplier = parseInt(this.rangeMultiplier.val(), 10) || 0;
			const rangePeriod = this.rangePeriod.val();
			let cacheKey = null;

			if (rangeType !== 'custom' && rangeType && rangeMultiplier > 0 && rangePeriod)
			{
				cacheKey = [rangeType, rangeMultiplier, rangePeriod].join('-');
			}

			return {cacheKey, rangeType, rangeMultiplier, rangePeriod};
		},

	};

	return DateFilter;
});

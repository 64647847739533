Sim.require.amd.registerRaw("/app/components/AClickableArea/AClickableArea.js", [], () => {

	class AClickableArea
	{

		#container;

		constructor(container)
		{
			this.#container = $(container);
			if (this.#container.length !== 1)
			{
				throw new Error();
			}

			const hovers = Sim.filterHoverElements((i, el) => $(el).closest(this.#container).length);
			if (hovers.length)
			{
				this.#onHoverChange(hovers.filter('.a-clickableArea'), this.#isAllowed(hovers.last()));
			}

			this.#container.on('mouseover', '.a-clickableArea', (event) => this.#onHoverChange($(event.currentTarget), this.#isAllowed($(event.target))));

			this.#container.on('mouseleave', '.a-clickableArea', (event) => this.#onHoverChange($(event.currentTarget), false));

			this.#container.on('click', '.a-clickableArea', (event) => {
				const clickableArea = $(event.currentTarget);
				const clickedElement = $(event.target);
				if ((clickableArea.hasClass('a-clickableArea--active') || event.originalEvent === undefined) && this.#isAllowed(clickedElement))
				{
					const clickAction = this.#getClickAction(clickableArea);
					if (clickAction.length && !clickedElement.closest('a').is(clickAction)) // ignore direct click on clickAction
					{
						clickAction[0].click();
						event.preventDefault();
					}
				}
			});

			this.#container.on('click', '.a-clickableArea__triggerOnClick', (event) => {
				$(event.target).closest('.a-clickableArea').not('.leftRightPanelComponent [data-id].m-table__row--selected').trigger('click');
			});
		}

		#isAllowed(element)
		{
			const sel = document.getSelection();
			const isSelection = sel.type === 'Range' || !sel.isCollapsed;
			return !isSelection && element.is(`
				.a-clickableArea,
				.a-clickableArea > .m-table__cell,
				.a-clickableArea__included
			`);
		}

		#getClickAction(clickableArea)
		{
			return clickableArea.find('.a-clickableArea__clickAction')
				.filter((i, el) => $(el).closest('.a-clickableArea').is(clickableArea))
				.first()
			;
		}

		#onHoverChange(clickableArea, allowed)
		{
			if (clickableArea.length)
			{
				const clickAction = this.#getClickAction(clickableArea);
				if (clickAction.length)
				{
					clickableArea.toggleClass('a-clickableArea--active', allowed);
					clickAction.toggleClass('a-anchor--hover', allowed);
				}
			}
		}
	}

	return AClickableArea;
});

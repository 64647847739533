Sim.require.amd.registerRaw("/app/Intranet/Config/components/MessageTemplateForm/Languages.js", ["/app/components/CheckboxSearchPopover/CheckboxSearchPopover.js"],  (CheckboxSearchPopover) => {

	class Languages
	{

		#options;
		#tabsRepeater;
		#customTextRepeater;
		#languageSearchPopover;
		#inaccuracy;

		constructor(options)
		{
			this.#options = options;
			this.#tabsRepeater = options.tabsRepeater;
			this.#customTextRepeater = options.customTextRepeater;
			this.#options.form.on('click', '.o-messageTemplateLanguageTab__deleteLanguageButton', (e) => {
				const button = $(e.target);
				if (confirm(button.data('confirmText')))
				{
					const textContainer = button.closest('.o-messageTemplateLanguageTab');
					this.deleteLanguage(textContainer.attr('data-language'), false, !textContainer.is('[data-is-new]'));
				}
			});

			this.#customTextRepeater.changed.onNow(() => {
				for (const el of this.#customTextRepeater.getRows().find('[data-nette-rules*="%langCode%"]').get())
				{
					const $el = $(el);
					const code = $el.closest('.o-messageTemplateLanguageTab').attr('data-language').replace(/[^\w]/gu, '_').toUpperCase();
					if (code)
					{
						$el.attr('data-nette-rules', $el.attr('data-nette-rules').replaceAll('%langCode%', code));
					}
				}
			});

			this.#languageSearchPopover = new CheckboxSearchPopover({
				values: this.#options.allLanguages,
				toggle: this.#options.langSearchToggle,
				onHidden: (selected) => {
					for (const code of selected)
					{
						if (!this.#hasLanguage(code))
						{
							this.#addLanguage(code);
							this.#toggleLanguage(code);
						}
					}
				},
				renderItem: ($item) => {
					if (this.#inaccuracy.valuesSelected.has($item.data('value')))
					{
						$('<i class="o-messageTemplateForm__inaccurateIcon las la-asterisk">')
							.attr('title', this.#options.inaccurateMark.updateRequiredText)
							.appendTo($item)
						;
					}
				},
			});

			this.#inaccuracy = {
				valuesSelected: new Set(this.#options.inaccurateMark.valuesSelected),
				valuesDisabled: new Set(this.#options.inaccurateMark.valuesDisabled),
				valuesDefaultShown: new Set(this.#customTextRepeater.getRows().get().map((el) => $(el).attr('data-language'))),
				objects: new Set,
			};
			this.#customTextRepeater.getRows().each((i, row) => {
				this.#addInaccuracyPopover($(row).find('.o-messageTemplateLanguageTab__inaccurateMark'), $(row).attr('data-language'));
			});
		}

		#hasLanguage(lang, checkIfDeleted = false)
		{
			const el = this.#customTextRepeater.getRows().filter(`[data-language="${$.escapeSelector(lang)}"]`);
			return (el.length && checkIfDeleted) ? !el.hasClass('o-messageTemplateLanguageTab--deleted') : !!el.length;
		}

		#addLanguage(lang)
		{
			if (this.#hasLanguage(lang)) throw new Error();
			const languageText = this.#options.allLanguages[lang];
			const languageName = languageText.replace(/^[A-Z]{2} - (.+)$/u, '$1');
			const textRow = this.#customTextRepeater.add();
			textRow
				.attr('data-language', lang)
				.attr('data-is-new', true)
			;
			textRow.find('[lang]').attr('lang', lang);
			textRow.find('input[name$="[language]"]').val(lang);
			textRow.find('.o-messageTemplateLanguageTab__messageTextHeader').text(this.#options.messageTextHeaderText.replace('%languageName%', languageName));

			const [beforeLang, afterLang] = this.#getLangPosition(lang);
			const tabRow = this.#tabsRepeater.add();
			tabRow.attr('data-language', lang);
			tabRow.find('.o-messageTemplateLanguageTab__switchButton').attr('title', languageName);
			tabRow.find('input[name$="[language]"]').val(lang);
			tabRow.find('.o-messageTemplateLanguageTab__langCode').text(lang);
			if (afterLang)
			{
				tabRow.detach().insertBefore(this.#tabsRepeater.getRows().filter(`[data-language="${$.escapeSelector(afterLang)}"]`));
			}
			else if (beforeLang)
			{
				tabRow.detach().insertAfter(this.#tabsRepeater.getRows().filter(`[data-language="${$.escapeSelector(beforeLang)}"]`));
			}

			this.#options.afterAdd(lang, languageText, beforeLang, afterLang);

			this.#languageSearchPopover.addValue('selected', lang);
			this.#languageSearchPopover.addValue('disabled', lang);
			if (this.#options.inaccurateMark.valuesSelected.includes(lang))
			{
				this.#inaccuracy.valuesDisabled.add(lang);
			}
			this.#refreshInaccuracies();
			this.#addInaccuracyPopover(textRow.find('.o-messageTemplateLanguageTab__inaccurateMark'), lang);
		}

		#toggleLanguage(lang)
		{
			this.#tabsRepeater.getRows().filter(`[data-language="${$.escapeSelector(lang)}"]`).find('.o-messageTemplateLanguageTab__switchButton').trigger('click');
		}

		deleteLanguage(lang, undo, soft = true)
		{
			const dataFilter = `[data-language="${$.escapeSelector(lang)}"]`;
			const tab = this.#tabsRepeater.getRows().filter(dataFilter);
			const textRow = this.#customTextRepeater.getRows().filter(dataFilter);

			tab.toggleClass('o-messageTemplateLanguageTab--deleted', !undo);
			tab.find('input').prop('disabled', !undo);
			textRow.toggleClass('o-messageTemplateLanguageTab--deleted', !undo);
			textRow.find('input, textarea, button').prop('disabled', !undo);

			if (!undo && !soft)
			{
				const nextForToggle = tab.next('.o-messageTemplateLanguageTab').length ? tab.next('.o-messageTemplateLanguageTab') : tab.prev('.o-messageTemplateLanguageTab');
				this.#customTextRepeater.remove(textRow);
				this.#tabsRepeater.remove(tab);
				if (nextForToggle.length)
				{
					this.#toggleLanguage(nextForToggle.attr('data-language'));
				}
				this.#languageSearchPopover.deleteValue('selected', lang);
				if (this.#options.allowedLanguages.includes(lang))
				{
					this.#languageSearchPopover.deleteValue('disabled', lang);
				}
			}
			if (!undo)
			{
				this.#inaccuracy.valuesDisabled.delete(lang);
				this.#refreshInaccuracies();
			}
			if (undo)
			{
				this.#resetInaccuracies(lang);
			}
			this.#options.afterDelete(lang);
		}

		#getLangPosition(lang)
		{
			const langKeys = Object.keys(this.#options.allLanguages);
			const orderKey = langKeys.indexOf(lang);
			let beforeCode = null;
			let afterCode = null;
			this.#tabsRepeater.getRows().each((i, el) => {
				const code = $(el).attr('data-language');
				if (orderKey < langKeys.indexOf(code))
				{
					afterCode = code;
					return false;
				}
				if (code !== lang)
				{
					beforeCode = code;
				}
			});
			return [beforeCode, afterCode];
		}

		#addInaccuracyPopover(popoverToggle, lang)
		{
			const valuesDefaultShown = new Set(this.#inaccuracy.valuesDefaultShown);
			valuesDefaultShown.delete(lang);
			const chsp = new CheckboxSearchPopover({
				valuesSelected: this.#inaccuracy.valuesSelected,
				valuesDisabled: this.#inaccuracy.valuesDisabled,
				valuesDefaultShown,
				valuesTopPlaced: [lang],
				values: this.#options.allLanguages,
				toggle: popoverToggle,
				onHidden: (selected) => {
					this.#inaccuracy.valuesSelected = new Set(selected);
					this.#refreshInaccuracies();
				},
			});
			this.#inaccuracy.objects.add(chsp);
		}

		#refreshInaccuracies()
		{
			for (const el of this.#customTextRepeater.getRows().get())
			{
				const $textRow = $(el);
				const code = $textRow.attr('data-language');
				if (!(code in this.#options.allLanguages))
				{
					throw new Error(code);
				}

				const selected = this.#inaccuracy.valuesSelected.has(code);
				const disabled = this.#inaccuracy.valuesDisabled.has(code); // saved

				$textRow.toggleClass('o-messageTemplateLanguageTab--inaccurate', selected);
				this.#tabsRepeater.getRows().filter(`[data-language="${$.escapeSelector(code)}"]`).toggleClass('o-messageTemplateLanguageTab--inaccurate', selected);

				const accuracyRadios = $textRow.find('input[name$="[accuracy]"]');
				if (selected && !disabled)
				{
					accuracyRadios.prop('checked', false);
					accuracyRadios.filter('[value="markInaccurate"]').prop('checked', true);
				}
				else if (!selected && !disabled)
				{
					accuracyRadios.prop('checked', false);
					accuracyRadios.filter('[value="keepAccurate"]').prop('checked', true);
				}
				else
				{
					accuracyRadios.filter('[value="markInaccurate"]').prop('checked', false);
					accuracyRadios.filter('[value="keepAccurate"]').prop('checked', false);
				}
				accuracyRadios.trigger('change');
			}

			const defaults = this.#customTextRepeater.getRows().get().map((el) => $(el).attr('data-language'));
			this.#inaccuracy.valuesDefaultShown = new Set(defaults);

			for (const chsp of this.#inaccuracy.objects)
			{
				chsp.clearValues('selected');
				chsp.addValue('selected', this.#inaccuracy.valuesSelected);
				chsp.clearValues('disabled');
				chsp.addValue('disabled', this.#inaccuracy.valuesDisabled);
				chsp.addValue('disabled', Array.from(this.#inaccuracy.valuesSelected).filter((code) => (
					this.#options.inaccurateMark.valuesSelected.includes(code) && // saved
					!this.#options.allowedLanguages.includes(code)
				)));
				chsp.clearValues('defaultShown');
				chsp.addValue('defaultShown', this.#inaccuracy.valuesDefaultShown);
				chsp.deleteValue('defaultShown', chsp.getValues('topPlaced'));
			}

			const inaccuraciesNotPresent = Array.from(this.#inaccuracy.valuesSelected)
				.filter((code) => !this.#hasLanguage(code, true))
			;
			this.#options.form.find('input[name="inaccuraciesNotPresent"]').val(inaccuraciesNotPresent.join(','));
			this.#languageSearchPopover.getPopover().toggle.toggleClass('o-messageTemplateForm__languageSearch--containsInaccuraciesNotPresent', !!inaccuraciesNotPresent.length);
			this.#languageSearchPopover.clearValues('defaultShown');
			this.#languageSearchPopover.addValue('defaultShown', [
				inaccuraciesNotPresent,
				this.#options.allImportantLanguages
					.filter((code) => !this.#hasLanguage(code, true))
					.slice(0, Math.max(10 - inaccuraciesNotPresent.length))
				,
			]);

			this.#options.afterInaccurate();
		}

		#resetInaccuracies(code)
		{
			if (this.#options.inaccurateMark.valuesSelected.includes(code))
			{
				this.#inaccuracy.valuesSelected.add(code);
			}
			else
			{
				this.#inaccuracy.valuesSelected.delete(code);
			}
			if (this.#options.inaccurateMark.valuesDisabled.includes(code))
			{
				this.#inaccuracy.valuesDisabled.add(code);
			}
			else
			{
				this.#inaccuracy.valuesDisabled.delete(code);
			}
			this.#refreshInaccuracies();

			const textContainer = this.#customTextRepeater.getRows().filter(`[data-language="${$.escapeSelector(code)}"]`);
			const accuracyRadios = textContainer.find('input[name$="[accuracy]"]');
			accuracyRadios.filter('[value="keepInaccurate"]').prop('checked', false);
			accuracyRadios.filter('[value="markAccurate"]').prop('checked', false);
			accuracyRadios.trigger('change');
		}

		areInaccuraciesChanged(code)
		{
			const textContainer = this.#customTextRepeater.getRows().filter(`[data-language="${$.escapeSelector(code)}"]`);
			const accuracyRadios = textContainer.find('input[name$="[accuracy]"]');
			const orgAccuracyRadios = accuracyRadios.closest('.m-controlGroup').attr('data-original-value') || undefined;
			return accuracyRadios.filter(':checked').val() !== orgAccuracyRadios;
		}

		getInaccuraciesNotPresentChanged()
		{
			const result = new Set;
			for (const code of this.#options.inaccurateMark.valuesSelected.concat(Array.from(this.#inaccuracy.valuesSelected)))
			{
				if (
					!this.#hasLanguage(code) &&
					this.#options.inaccurateMark.valuesSelected.includes(code) !== this.#inaccuracy.valuesSelected.has(code)
				)
				{
					result.add(code);
				}
			}
			return result;
		}

	}

	return Languages;
});

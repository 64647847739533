Sim.require.amd.registerRaw("/app/Intranet/components/TableSelector/TableSelectorPaginator.js", [], () => {

	class TableSelectorPaginator
	{

		constructor(elements)
		{
			this.elements = elements;
			const paginatorEl = elements.tsTableSticky.find('> .paginator[data-page]');
			const start = elements.realTable.find('> tbody > tr.m-table__row--selected')[0] || elements.realTable.find('> tbody > tr:first')[0];
			this.local = {
				start: start ? parseInt($(start).attr('data-position'), 10) : 0,
				itemsPerPage: -1,
			};
			this.remote = {
				page: parseInt(paginatorEl.attr('data-page'), 10),
				itemsPerPage: parseInt(paginatorEl.attr('data-items-per-page'), 10),
				itemCount: parseInt(paginatorEl.attr('data-item-count'), 10),
			};
			this.remote.maxPage = this.remote.itemCount ? Math.ceil(this.remote.itemCount / this.remote.itemsPerPage) : 1;
			this.current = {
				start: -1,
				itemsPerPage: -1,
			};
		}

		isDifferentPosition(paginatorType, start, itemsPerPage, tsMustBeClass = 'tsShowContent')
		{
			const p = this[paginatorType];
			if (p.start !== start || p.itemsPerPage !== itemsPerPage)
			{
				return this.elements.tableSelector.hasClass(tsMustBeClass);
			}
			return false;
		}

		iteratePositions(paginatorType)
		{
			const p = this[paginatorType];
			let startPosition, endPosition;
			if (paginatorType === 'remote')
			{
				startPosition = (p.page - 1) * p.itemsPerPage;
				endPosition = (p.page * p.itemsPerPage) - 1;
			}
			else if (paginatorType === 'local')
			{
				startPosition = p.start;
				endPosition = p.start + p.itemsPerPage - 1;
			}
			else if (paginatorType === 'current')
			{
				if (p.start === -1)
				{
					startPosition = 0;
					endPosition = -1;
				}
				else
				{
					startPosition = p.start;
					endPosition = p.start + p.itemsPerPage - 1;
				}
			}
			else
			{
				throw new Error(paginatorType);
			}
			startPosition = Math.max(startPosition, 0);
			endPosition = Math.min(endPosition, this.remote.itemCount - 1);
			const range = [];
			for (let position = startPosition; position <= endPosition; position++)
			{
				range.push(position);
			}
			return range;
		}

	}

	return TableSelectorPaginator;
});

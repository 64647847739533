(() => {

	const Sim = window.Sim || (window.Sim = {});

	Sim.require = {

		/**
		 * Sync when deps is already loaded, otherwise async.
		 * @param {(string|string[])} deps
		 * @param {function(object|object[])} callback
		 * @param {function(Error)=} onError
		 * @returns {void}
		 */
		callback(deps, callback, onError = undefined)
		{
			this._req(typeof deps === 'string' ? 'get' : 'getAll', deps, callback, onError);
		},

		/**
		 * Construct single dep class.
		 * @param {string} dep
		 * @param {*} args
		 * @returns {void}
		 */
		construct(dep, args = [])
		{
			this._req('get', dep, (Dep) => new Dep(...args));
		},

		/**
		 * @param {(string|string[])}
		 * @returns {Promise<object|object[]>}
		 */
		promise(deps)
		{
			return new Promise((resolve, reject) => this.callback(deps, resolve, reject));
		},

		/**
		 * You probably don't want to use this function. This is only for situations where `await promise()` or `callback()` can't be used.
		 * Returns dependencies synchronously, throw error if any dependency is not loaded yet (see onNotLoadedError to overwrite this behaviour).
		 * It does not try to load dependencies, except for development to generate a better error.
		 * @param {(string|string[])}
		 * @param {function(Error, (object|object[])): (undefined|*)}
		 * @return {(object|object[])}
		 */
		get(deps, onNotLoadedError = undefined)
		{
			const single = typeof deps === 'string';
			deps = single ? [deps] : deps;
			let res = Array.from(Array(deps.length));
			this._isReady && this._req('getAll', deps, (a) => { res = a; }, null, false);
			const errors = Object.entries(res).filter(([, obj]) => obj === undefined).map(([k]) => deps[k]);
			const result = single ? res[0] : res;
			if (errors.length)
			{
				if (Sim.config.debuggerEnabled && !Sim.unloadManager.is())
				{
					this.callback(errors, () => {}); // mainly to throw better error during development, but will load dep for later
				}
				const err = new Error(
					`Sim.require.get(): Module name '${errors.join(', ')}' has not been loaded yet, ` +
					'you probably wanted to use `await Sim.require.promise()` or `Sim.require.callback()` or put module into dependencies.'
				);
				const errResult = onNotLoadedError === undefined ? undefined : onNotLoadedError(err, result);
				if (errResult === undefined)
				{
					throw err;
				}
				return errResult;
			}
			return result;
		},

		/**
		 * Allow define module async. That's either before require is even loaded or with async resolve function or with returning Promise.
		 * @param depName {string}
		 * @param deps {(string|string[])}
		 * @param {(function(this:{}, ...*, (function(string):*|function(string[], function(...*))), {exports: {}}, {}):(*|Promise<*>)|Promise<*>|object)} factory
		 * @returns {void}
		 */
		defineAsync(depName, deps, factory)
		{
			if (this._isReady)
			{
				Sim.require.amd.register(depName, typeof deps === 'string' ? [deps] : deps, factory);
			}
			else
			{
				this._queue.add(() => this.defineAsync(depName, deps, factory));
			}
		},

		_req(method, deps, callback, onError, canLoad = true)
		{
			if (!this._isReady)
			{
				this._queue.add(() => this._req(method, deps, callback, onError, canLoad));
				return;
			}
			onError = onError || ((err) => { throw err; });
			const res = Sim.require.amd[method](deps, canLoad);
			if (res instanceof Promise)
			{
				res.then(callback).catch(onError);
			}
			else
			{
				try { callback(res); } catch (err) { onError(err); }
			}
		},

		init()
		{
			this._isReady = true;
			const queue = this._queue;
			delete this._queue;
			for (const fn of queue) fn();
			delete this.init;
		},

		_queue: new Set,
		_isReady: false,

	};

})();

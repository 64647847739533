Sim.require.amd.registerRaw("/app/components/HtmlEditor/Buttons/LinkButton.js", ["/app/components/HtmlEditor/Buttons/BaseButton.js","/app/components/HtmlEditor/Simditor.js","/app/components/HtmlEditor/Utils.js"],  (BaseButton, Simditor, Utils) => {

	class LinkPopover extends Simditor.Popover
	{

		render()
		{
			this.onEscapePress = Sim.onEscapePress(() => this.hide());
			const template = `
				<div class="link-settings">
					<div class="settings-field">
						<label>${Sim.escapeHtml(this._t('linkText'))}</label>
						<input class="link-text" type="text">
						<a class="btn-unlink" href="javascript:void(0)" title="${Sim.escapeHtml(this._t('removeLink'))}" tabindex="-1">
							<span class="simditor-icon simditor-icon-unlink"></span>
						</a>
					</div>
					<div class="settings-field">
						<label>${Sim.escapeHtml(this._t('linkUrl'))}</label>
						<input class="link-url" type="text">
					</div>
					<div class="settings-field">
						<label>${Sim.escapeHtml(this._t('linkTarget'))}</label>
						<select class="link-target">
							<option value="_blank">${Sim.escapeHtml(this._t('openLinkInNewWindow'))} (_blank)</option>
							<option value="_self">${Sim.escapeHtml(this._t('openLinkInCurrentWindow'))} (_self)</option>
						</select>
					</div>
				</div>
			`;
			this.el.addClass('link-popover').html(template);
			this.textEl = this.el.find('.link-text');
			this.urlEl = this.el.find('.link-url');
			this.unlinkEl = this.el.find('.btn-unlink');
			this.selectTarget = this.el.find('.link-target');

			this.textEl.on('input', () => {
				this.target.text(this.textEl.val());
				this.editor.inputManager.throttledValueChanged();
			});

			this.urlEl.on('input', () => {
				let val = this.urlEl.val();
				if (val && !/^(?:https?:)?\/\/|^(?:mailto|tel):/igu.test(val))
				{
					val = `http://${val}`;
				}
				this.target.attr('href', val);
				this.editor.inputManager.throttledValueChanged();
			});

			this.selectTarget.on('change', () => {
				this.target.attr('target', this.selectTarget.val());
				this.editor.inputManager.throttledValueChanged();
			});

			this.el.find(':input').on('keydown', (e) => {
				if (
					e.which === 13 || // enter
					e.which === 27 || // escape
					(
						!e.shiftKey && e.which === 9 && // tab
						$.contains($(e.target).closest('.link-settings').find('> .settings-field:visible:last')[0], e.target)
					)
				)
				{
					e.preventDefault();
					this.editor.selection.setRangeAfter(this.target, document.createRange());
					this.hide();
				}
			});

			this.unlinkEl.on('click', () => {
				const txtNode = document.createTextNode(this.target.text());
				this.target.replaceWith(txtNode);
				this.editor.selection.setRangeAfter(txtNode, document.createRange());
				this.hide();
			});
		}

		show(...args)
		{
			super.show(...args);
			this.textEl.val(this.target.text());
			this.urlEl.val(this.target.attr('href'));
			this.selectTarget.val(this.target.attr('target'));
			const hasTarget = Utils.isGloballyAllowedTag(this.editor, 'a', 'target');
			this.selectTarget.closest('.settings-field').toggle(hasTarget);
			this.onEscapePress.on();
		}

		hide()
		{
			super.hide();
			this.editor.inputManager.throttledValueChanged();
			this.onEscapePress.off();
		}

	}

	class LinkButton extends BaseButton
	{

		get name() { return 'link'; }
		get icon() { return 'simditor-icon simditor-icon-link'; }
		get disableTag() { return 'pre'; }
		get htmlTag() { return 'a'; }

		render()
		{
			super.render();
			this.popover = new LinkPopover({button: this});
		}

		_status()
		{
			super._status();
			if (this.active && !this.editor.selection.rangeAtEndOf(this.node))
			{
				this.popover.show(this.node);
			}
			else
			{
				this.popover.hide();
			}
		}

		command()
		{
			const range = this.editor.selection.range();
			if (this.active)
			{
				const txtNode = document.createTextNode(this.node.text());
				this.node.replaceWith(txtNode);
				range.selectNode(txtNode);
			}
			else
			{
				const $contents = $(range.extractContents());
				const linkText = this.editor.formatter.clearHtml($contents.contents(), false);
				const $link = $('<a>', {
					href: '',
					target: '_blank',
					text: linkText || this._t('linkText'),
				});
				if (this.editor.selection.blockNodes().length > 0)
				{
					range.insertNode($link[0]);
				}
				else
				{
					const $newBlock = $('<p>').append($link);
					range.insertNode($newBlock[0]);
				}
				range.selectNodeContents($link[0]);
				this.popover.one('popovershow', () => {
					const el = linkText ? this.popover.urlEl : this.popover.textEl;
					el.trigger('focus');
					el[0].select();
				});
			}
			this.editor.selection.range(range);
			this.editor.trigger('valuechanged');
		}

	}

	return LinkButton;
});


Sim.require.amd.registerRaw("/app/Intranet/components/Offers/OfferForm/OfferForm.js", ["/public/js/SimpleEvent.js","/app/Intranet/components/Offers/OfferForm/Amounts.js","/app/Intranet/components/Offers/OfferForm/Objects.js"],  function (SimpleEvent, OfferFormAmounts, OfferFormObjects) {

	function OfferForm() {
		new SimpleEvent(this, 'changedAmounts');
		new SimpleEvent(this, 'changedObjects');
	}
	OfferForm.singleton = function (el) {
		var $el = el ? $(el).closest('.editOffer') : $('.editOffer');
		var o = $el.data('OfferForm');
		if (!o) $el.data('OfferForm', o = new OfferForm($el));
		return o;
	};
	OfferForm.prototype = {

		createObjects: function (a, b, c, d) {
			this.objects = new OfferFormObjects(a, b, c, d);
			this.objects.objects.changed.onNow(this.changedObjects.trigger);
		},

		createAmounts: function (a, b, c) {
			this.amounts = new OfferFormAmounts(a, b, c);
			this.changedObjects.onNow(function (getSelectedObjects, all) {
				this.amounts.triggerObjectsChanged(getSelectedObjects());
			});
			this.amounts.amounts.changed.onNow(this.changedAmounts.trigger);
		},

		createImages: function (ofi) {
			this.images = ofi;
			this.changedObjects.onNow(function (getSelectedObjects, getRealObjects) {
				_.each(getRealObjects(), function (data) {
					$('.unused .unusedImages[data-object="' + data.objectId + '"]').triggerHandler('show.images', [{
						has: data.has,
						returnUnit: data.returnUnit,
					}]);
				});
			});
		},

	};

	return OfferForm;
});

Sim.require.amd.registerRaw("/public/js/GoogleApi.js", [], function () {

	var globalCaches = {};

	function GoogleApi(link, context) {
		this.link = link;
		this.context = context;
		this.localCache = {};
		this.createLink = _.memoize(this.createLink);
	}

	GoogleApi.prototype = {

		getTOP: function (args, callback) {
			var link = this.createLink(args);
			if (globalCaches.TOP && _.keys(globalCaches.TOP)[0] !== link)
			{
				throw new Error('GoogleApi: only one api in top.');
			}
			GoogleApi.queue(this.localCache, 'TOP', link, callback.bind(this.context));
		},

		get: function (args, callback) {
			var link = this.createLink(args);
			GoogleApi.queue(this.localCache, 'IFRAME', link, callback.bind(this.context));
		},

		createLink: function (args) {
			if (args !== null && !_.isArray(args)) throw new Error;
			var link = this.link;
			if (_.isFunction(this.link))
			{
				link = link.apply(null, args);
			}
			else
			{
				if (_.size(args) !== 0) throw new Error;
			}
			if (_.isString(link))
			{
				link = [link, {}];
			}
			link[1]['key'] = Sim.config.googleApiKey;
			return link[0] + (link[0].indexOf('?') === -1 ? '?' : '&') + _.map(link[1], function (val, key) {
				return encodeURIComponent(key) + '=' + encodeURIComponent(val);
			}).join('&');
		},

	};

	GoogleApi.queue = function (localCaches, type, link, callback) {
		var globalCache = ((globalCaches[type] || (globalCaches[type] = {}))[link] || (globalCaches[type][link] = {}));
		var localCache = (localCaches[type + link] || (localCaches[type + link] = {}));
		if (!globalCache.google)
		{
			if (!globalCache.queue)
			{
				globalCache.queue = [];
				GoogleApi['createIn' + type](link, function (google) {
					if (!google) throw new Error(link);
					globalCache.google = google;
					_.invoke(globalCache.queue, 'call', null, globalCache.google, localCache);
					delete globalCache.queue;
				});
			}
			globalCache.queue.push(callback);
			return;
		}
		callback(globalCache.google, localCache);
	};

	GoogleApi.create = function (window, link, init) {
		const timeout = setTimeout(() => {
			delete window._googleApiLoaded;
			throw new Error(`GoogleApi timeout ${link}`);
		}, 30000);
		window._googleApiLoaded = function () {
			clearTimeout(timeout);
			init(window.google);
			delete window._googleApiLoaded;
		};
		var s = window.document.createElement('script');
		s.src = link + '&loading=async&callback=_googleApiLoaded';
		s.async = true;
		s.referrerpolicy = 'no-referrer';
		window.document.head.appendChild(s);
	};

	GoogleApi.createInTOP = function (link, init) {
		GoogleApi.create(window, link, init);
	};

	GoogleApi.createInIFRAME = function (link, init) {
		const iframe = $('<iframe>')
			.attr('src', 'about:blank')
			.css('display', 'none')
			.on('load', () => {
				GoogleApi.create(iframe[0].contentWindow, link, init);
			})
		;
		iframe.appendTo('body');
	};

	return GoogleApi;

});

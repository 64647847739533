Sim.require.amd.registerRaw("/app/Intranet/Banks/forms/BankConnectionForm.js", ["/public/js/Nette.js"],  (Nette) => {

	class BankConnectionForm
	{

		#options;
		#form;
		#noneOption;

		constructor(options)
		{
			this.#options = options;
			this.#form = $(options.form);
			this.#noneOption = this.#form.find(`input[name="purpose[]"][value="${this.#options.PURPOSE_NONE}"]`);

			this.#form.on('change', 'input[name="purpose[]"]', () => this.#purposeDisables());
			this.#form.on('change', 'input[name="purpose[]"], select[name="accountHolder"]', () => this.#checkPurposeSelection());

			this.#purposeDisables();
			this.#checkPurposeSelection();
		}

		#purposeDisables()
		{
			const normalOptions = this.#form.find('input[name="purpose[]"]').not(this.#noneOption);
			if (normalOptions.is(':checked'))
			{
				normalOptions.prop('disabled', false);
				this.#noneOption.prop('disabled', true);
			}
			else if (this.#noneOption.prop('checked'))
			{
				normalOptions.prop('disabled', true);
				this.#noneOption.prop('disabled', false);
			}
			else
			{
				normalOptions.prop('disabled', false);
				this.#noneOption.prop('disabled', false);
			}
		}

		#checkPurposeSelection()
		{
			const accountHolder = this.#form.find('select[name="accountHolder"]');
			const ownerTransferOption = this.#form.find(`input[name="purpose[]"][value="${this.#options.TYPE_OWNER_TRANSFER}"]`);
			ownerTransferOption
				.prop('disabled', this.#noneOption.prop('checked'))
				.add('+ label', ownerTransferOption)
				.removeAttr('title')
			;
			if (accountHolder.val() === this.#options.OWNER && !ownerTransferOption.prop('checked'))
			{
				ownerTransferOption
					.prop('disabled', true)
					.add('+ label', ownerTransferOption)
					.attr('title', this.#options.warningText)
				;
			}
			if (accountHolder.val() === this.#options.OWNER && ownerTransferOption.prop('checked'))
			{
				Nette.formErrors = [];
				Nette.validateControl(this.#form.find('input[name="purpose[]"]'));
				Nette.showFormErrors(this.#form, Nette.formErrors);
				accountHolder.val(null);
			}
		}

	}

	return BankConnectionForm;
});

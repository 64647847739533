Sim.require.amd.registerRaw("/app/Intranet/PropertyManagement/templates/Finance/Hellover.js", ["/app/components/AClickableArea/AClickableArea.js"],  function (AClickableArea) {

	/**
	 * initHellover is customized initBootstrapPopovers for Hellovers (show static on click, specific position and DOM placement)
	 */
	function Hellover(template, jsonp, options) {
		this.setup = {
			expand: {},
		};
		this.contents = {};
		this.template = $('<div>').html(template).contents();
		this.options = options;
		this.jsonp = jsonp;

		this.slot = $('.hellover-slot');
		if (this.slot.length !== 1) throw new Error;
		this.table = this.slot.closest('table');
		if (this.table.length !== 1) throw new Error;
		this.toggles = this.createTogglesIndex();

		this.loadContent();
		this.initEvents(this.table, this.slot, this.toggles.all.filter(':not(.hellover-initialized)'));

		new AClickableArea(this.table);
	}

	Hellover.prototype = {

		initEvents: function (table, slot, toggles) {
			var self = this;

			table.data('hellover-static', false); // hellover-static is used for behavior decision between hover and static state

			const onEscapePress = Sim.onEscapePress();

			toggles.each(function(index) {
				var el = $(this);
				el.one('init', function() {
					var hellover = self.template.clone();
					hellover.find('.popover-title .actual').append(el.data('title')); // content filling

					self.getContent(el.data('month'), function (content) {
						var contentContainer = hellover.find('.popover-content');
						contentContainer.find('.content-loading').remove();
						contentContainer.append(content);
						if (el.is('.active'))	{
							hellover.trigger('show');
						}
						Sim.init(hellover);
					});

					var monthCellElements = el.closest('table').find('.hellover-toggle[data-month="' + el.data('month') + '"]');
					hellover.on('show', function () {
						monthCellElements.addClass('active');
					});

					hellover.on('mouseleave', () => {
						if(!table.data('hellover-static'))	{
							hellover.detach(); // Hide from user, but keep associated events and data
							toggles.removeClass('active');
						}
					});
					hellover.on('click', () => { // enable static position
						table.data('hellover-static', true);
						hellover.addClass('hellover-static');
						onEscapePress.on();
					});

					for (const [selector, move, moveToCurrent] of [
						['.js-monthPrev', -1, false],
						['.js-monthNext', +1, false],
						['.js-monthPrevPrev', -6, true],
						['.js-monthNextNext', +6, true],
					])
					{
						hellover.on('click', selector, (e) => {
							e.preventDefault();
							const disable = self.navigateToPanel(el, move, moveToCurrent);
							if (disable)
							{
								$(e.currentTarget).addClass('m-pagination__item--disabled');
							}
						});
					}

					/* Expand rows handling */
					hellover.on('click', '.a-clickableArea__clickAction', function (e) {
						e.preventDefault();
						var contact = $(this).closest('tbody').data('contract');
						self.setup.expand[contact] = !self.setup.expand[contact];
						hellover.trigger('show.expandRows');
					});
					hellover.on('show.expandRows', function () {
						var tbodies = hellover.find('tbody[data-contract]');
						$.each(self.setup.expand, function (contract, show) {
							tbodies.filter('[data-contract="' + contract + '"]').filter(':has(.expandRows a)').toggleClass('open', show);
						});
					});

					// Store as data attribute
					el.data('hellover', hellover);
					el.addClass('hellover-initialized');
				});
			});

			// User actions on toggles
			toggles.on('mouseenter', function() {
				if(table.data('hellover-static')) return; // Switch only in hover state, not static
				var el = $(this);
				el.trigger('init');
				el.addClass('hover'); // show delay decision variable
				setTimeout(function(){
					if(el.hasClass('hover')){ // mouse pointer is at the same position as 200ms before, show hellover then
						self.openPanel(el);
					}
				}, 200);
			});
			toggles.on('mouseleave', function() {
				$(this).removeClass('hover'); // mouse pointer is somewhere else, prevents hellover appear
			});
			toggles.on('click', function() {
				var el = $(this);
				if (!table.data('hellover-static'))
				{
					table.data('hellover-static', true);
					onEscapePress.on();
				}
				self.openPanel(el);
			});

			// Hide actual hellover and disable static position
			var disableStaticAndHide = function() {
				table.data('hellover-static', false);
				slot.find('.o-hellover').removeClass('hellover-static').detach();
				toggles.removeClass('active');
				onEscapePress.off();
				return false;
			};
			slot.on('click', '[data-dismiss="hellover"]', disableStaticAndHide);
			onEscapePress.addThen(disableStaticAndHide);

			Sim.filterHoverElements(toggles).trigger('mouseenter'); // melo hover pred inicializaci hellover

			const open = (this.options.open ?? null) !== null ? String(this.options.open).match(/^([-+]?\d*)(-[\d-]*)?$/u) : null;
			if (open !== null)
			{
				this.table.trigger('click.Hellover_loadContent');
				for (const contact of (open[2] || '').split('-'))
				{
					if (contact === '') continue;
					this.setup.expand[contact] = true;
				}
				this.navigateToPanel(null, parseInt(open[1], 10) || 0);
			}
		},

		openPanel(toggle) {
			toggle.trigger('init');
			this.slot.children().detach();
			this.slot.append(toggle.data('hellover'));
			this.toggles.all.removeClass('active');
			toggle.data('hellover').trigger('show');
			toggle.data('hellover').toggleClass('hellover-static', !!this.table.data('hellover-static'))
		},

		createTogglesIndex() {
			const all = this.table.find('.hellover-toggle');
			if (all.length === 0) throw new Error;

			const posByEl = new Map;
			const $elByPos = new Map;
			const months = new Map;
			let index = -2;
			for (const el of all)
			{
				const $el = $(el);
				const month = $el.attr('data-month');
				let pos = months.get(month);
				if (pos === undefined)
				{
					pos = index++;
					months.set(month, pos);
					$elByPos.set(pos, $el);
				}
				posByEl.set(el, pos);
			}
			return {all, months, posByEl, $elByPos};
		},

		navigateToPanel($fromToggle, move, moveToCurrent) {
			const pos = $fromToggle ? this.toggles.posByEl.get($fromToggle[0]) : 0;
			if (pos === undefined) throw new Error;
			const $moveToggle = this.toggles.$elByPos.get(pos + move);
			if ($moveToggle !== undefined)
			{
				$moveToggle.trigger('click');
				return false;
			}

			let [year, month] = this.toggles.$elByPos.get(pos)
				.attr('data-month')
				.split('-', 2)
				.map((s) => parseInt(s, 10))
			;

			month += move;
			let open = 0;
			if (!moveToCurrent)
			{
				month += move > 0 ? 1 : -3;
				open += move > 0 ? -1 : 3;
			}

			while (month > 12)
			{
				year++;
				month -= 12;
			}
			while (month <= 0)
			{
				year--;
				month += 12;
			}

			const expands = Object.entries(this.setup.expand)
				.filter(([contract, show]) => show)
				.map(([contract, show]) => contract)
				.join('-')
			;

			location.href = this.options.navPage
				.replace('%25month%25', `${year}-${String(month).padStart(2, '0')}`) // %month%
				.replace('%25open%25', expands ? `${open}-${expands}` : open) // %open%
			;
			return true;
		},

		getContent: function (month, callback) {
			if (this.contents[month] === undefined)
			{
				setTimeout(function () {
					this.getContent(month, callback);
				}.bind(this), 200);
				return;
			}
			callback(this.contents[month]);
		},

		loadContent: function (month, callback) {
			var load = _.once(function () {
				this.table.off('.Hellover_loadContent');
				window[this.jsonp] = function (month, content) {
					this.contents[month] = content;
				}.bind(this);
				var script = document.createElement('script');
				script.src = this.options.url;
				script.onload = function () {
					delete window[this.jsonp];
				}.bind(this);
				document.head.appendChild(script);
			}.bind(this));
			this.table.one('mouseenter.Hellover_loadContent click.Hellover_loadContent', load);
		},

	};

	return Hellover;
});

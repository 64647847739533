Sim.require.amd.registerRaw("/app/Intranet/Banks/forms/BankTransactions/BankTransactionSearchSuggestedHover.js", ["/public/js/Helpers/ElementFinder.js","/public/js/ContinuousAjaxUpdated.js"],  (ElementFinder, ContinuousAjaxUpdated) => {

	class BankTransactionSearchSuggestedHover
	{

		#root = new ElementFinder('.o-bankTransactionMark');
		#searchContainer;
		#snippetBoxEl;
		#hoverBoxEl;

		constructor(options)
		{
			this.#searchContainer = this.#root.findOne(options.searchContainer);
			this.#snippetBoxEl = this.#root.findOne(options.snippetBox);
			const $spinner = $('<div class="o-transactionMatchInfo__spinner">').insertBefore(this.#snippetBoxEl);
			this.#hoverBoxEl = $('<div class="o-transactionMatchInfo__searchSuggestedHover">').insertBefore(this.#snippetBoxEl);

			const ajax = new ContinuousAjaxUpdated({
				...options.ajax,
				spinner: $spinner,
				update: (...a) => this.#update(...a),
				args: () => this.#getArguments(),
				disabled: (args) => (args['#none'] ? {} : null),
				delay: 17,
			});

			this.#searchContainer.on('Rentflow:DropdownMenuSearch.changeActive', () => ajax.request());
		}

		#getArguments()
		{
			if (this.#searchContainer.is(':visible'))
			{
				const $active = this.#searchContainer.find('.m-dropdownMenu__row--active:not(m-dropdownMenu__row--disabled)');
				const data = $active.data('value');
				if (data && $active.is(':visible'))
				{
					return {data};
				}
			}
			return {'#none': true};
		}

		#update({snippets}, args)
		{
			const visible = !args['#none'];
			this.#hoverBoxEl.html(visible ? snippets[this.#snippetBoxEl.attr('id')] || '' : '');
			this.#snippetBoxEl.toggle(!visible);
		}

	}

	return BankTransactionSearchSuggestedHover;
});

Sim.require.amd.registerRaw("/app/components/DropdownMenuSearch/AjaxSubmitFillingForm.js", ["/public/js/Helpers/ElementFinder.js"],  (ElementFinder) => {

	class AjaxSubmitFillingForm
	{

		#root;
		#selectButton;
		#disableSubmit;

		constructor(options)
		{
			this.#root = new ElementFinder(options.form, 'form');
			this.#disableSubmit = Sim.disableSubmit.create(this.#root.$root);
			this.#selectButton = this.#root.findOne('input[type="submit"][data-ref="AjaxSubmitFillingFormFactory"]');
			this.#initAjaxSubmit();

			const nameSelector = `[name^="${$.escapeSelector(options.searchingContainerName)}"]`;
			this.#root.on(`input${nameSelector}, select${nameSelector}`, 'change', () => {
				this.#trigger();
			});
		}

		#initAjaxSubmit()
		{
			Sim.enableAjaxSubmit(this.#root.$root)
				.addIsEnabled((form) => this.#selectButton.is(form[0]['nette-submittedBy']))
				.onSuccess((form, payload, textStatus, jqXHR, submittedBy) => {
					$.nette.success(payload, textStatus, jqXHR);
					this.#root.$root.trigger('Rentflow:AjaxSubmitFillingForm.update');
				})
			;
		}

		#triggerTimout;

		#trigger()
		{
			if (!this.#triggerTimout)
			{
				this.#disableSubmit.start();
			}
			this.#triggerTimout && clearTimeout(this.#triggerTimout);
			this.#triggerTimout = setTimeout(() => this.#triggerNow(), 100);
		}

		#triggerNow()
		{
			if (this.#disableSubmit.isDisabledElsewhere())
			{
				this.#trigger();
			}
			else
			{
				this.#triggerTimout = null;
				this.#selectButton.trigger('click');
				this.#disableSubmit.stop();
			}
		}

	}

	return AjaxSubmitFillingForm;
});
